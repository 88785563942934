import React from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(./../images/various/aboutBackground2.svg)',
        backgroundPosition: 'top right',
        display: 'flex',
        minHeight: 620,
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',    
        textAlign: 'center',
        marginTop: 60,
        [theme.breakpoints.down('sm')]: {
            margin: '100px 0 70px 0',
        },
    },
    text: {
        color: theme.palette.primary.dark,
        maxWidth: '759px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px', 
            },
    },
    title: {
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
          background: '#fff',
          bottom: '6px',
          content: '""',
          height: 13,
          left: 0,
          position: 'absolute',
          width: '100%',
          zIndex: -1
        }
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '22px',
    },
}));


export default function About() {
    const classes = useStyles();

  return (
    <Box className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box className={classes.text}>
                    <Box mb={4}>
                        <Typography className={classes.title}>
                            Sobre o <span className={classes.line_text}>Talk2me</span>
                        </Typography>
                    </Box> 
                    <Box mb={4}>
                        <Typography  className={classes.rich_text}>
                        O NEST posiciona-se como um facilitador na transição das Pequenas e Médias Empresas para a economia digital, ao desenvolver, 
                        apoiar, alavancar e disseminar projetos que possam ser aplicados por vários interlocutores no sector do turismo. 
                        Ao criar projetos como a Plataforma Talk2Me , veicula uma ferramenta inovadora que poderá dar voz a um conjunto alargado 
                        de produtos turísticos, sejam museus, alojamentos, iguarias, peças de artesanato, todos passíveis de gerar interesse e 
                        estabelecer conversas que aprofundem a sua génese e o seu propósito. Saber mais sobre o que é, a sua envolvência, 
                        personalidades, a sua história, as curiosidades e fornecer informações sobre demais interesses turísticos é o que esta 
                        ferramenta permite, e de forma simples!"
                        </Typography>
                    </Box> 
                </Box> 
                <Box>
                    <a href="" target="_blank">
                        <img src="./../images/logos/nestColor.svg" alt="Nest"  />
                    </a>
                </Box>
            </Box>
        </Container>
    </Box>
  )
}