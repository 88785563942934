import React from 'react';

import Icon from "./../../components/Icon";

import {
  makeStyles,
  IconButton,
  Modal,
  Backdrop,
  Box,
  Typography,
  Button,
  CardMedia,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';;

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 384,
      height: 392,
      background:'#FFFFFF',
      borderRadius: '6px',
      padding: 32,
      outline: 'none',
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
    },
    subtitle: {
      fontWeight: 400,
      marginTop: 5,
    },
    box: {
      marginTop: 35,
      width: 320,
      height: 67,
      background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 47.92%, #F3F3F3 100%)',
      border: '1px solid #DBDDF1',
      boxSizing: 'border-box',
      borderRadius: '6px',
    },
    item: {
      fontWeight: 400,
      paddingTop: 25,
      textAlign: 'left',
      padding:'23px 54px 23px 14px',
    },
    media: {
      backgroundColor: '#F3F7FF',
      borderRadius: '6px',
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 20px',
      width: '100%',
    }
}));

const ModalQrCode = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDownloadQRcode = e => {
      e.preventDefault();
      let a = document.createElement("a");
      a.href = props.qrcode;
      a.download = props.name + " QR Code.png";
      a.click();
    }

    const onPrint = e => {
      e.preventDefault();
      window.print();
    }

    return (
      <Box>
        <IconButton onClick={handleOpen}>
          <Icon icon="qr-code" size={25} color="#4050E1" />
        </IconButton>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          }}
        >
          <Slide in={open} timeout={500} >
            <Box className={classes.paper}>
              <Typography
              className={classes.title}
              variant="h2"
              >
                QR Code
              </Typography>
              <Typography
              className={classes.subtitle}
              variant="h4"
              >
               Imprima ou partilhe o QR code
              </Typography>
              <Box mt={4} style={{width: '100%'}}>
                <Box className={classes.media}>
                  <CardMedia
                    id="qr-code"
                    alt="image"
                    component="img"
                    style={{ width: "100px"}}
                    image={props.qrcode}
                  />
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' alignItems='center'  mt={4}>
                <Button variant="outlined" color="primary" onClick={onDownloadQRcode} style={{borderRadius: '34px', textTransform: 'none', marginRight: 8, }}>
                  Download
                </Button>
                <Button variant="outlined" color="primary" onClick={onPrint} style={{borderRadius: '34px', textTransform: 'none', marginRight: 8, }}>
                  Imprimir
                </Button>
                {/* <Button variant="outlined" color="primary" style={{borderRadius: '34px', textTransform: 'capitalize' }}>
                  Partilhar
                </Button> */}
              </Box>
            </Box>
          </Slide>
        </Modal>
      </Box>
    );
}

export default ModalQrCode;