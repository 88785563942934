import React from 'react';

import Footer from '../sections/Footer'

import { Box, makeStyles, Container, Button, Typography } from '@material-ui/core'

import { useNavigation } from 'core/services/navigation';

const useStyles = makeStyles((theme) => ({
  floating_green: {
    backgroundImage: 'url(./../images/various/floatingShapeGreen.svg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 1282,
    left: -70,
    minWidth: 711,
    position: 'absolute',
    top: 500,
    zIndex: 1,
    overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
          display: 'none',
      },
  },
  floating_blue: {
    backgroundImage: 'url(./../images/various/floatingShapeBlue.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 1460,
    minWidth: 442,
    position: 'absolute',
    right: 0,
    top: 715,
    zIndex: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
  },
  hero: {
    alignItems: 'flex-start',
    backgroundImage: 'url(./../images/various/headerBackgroundWool.jpg)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    marginBottom: '215px',
    minHeight: 756,
    padding: ' 40px 0px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./../images/various/headerBackgroundWool_mobile.jpg)',
      marginBottom: 0,
      paddingBottom: 200,

    },
  },
  header: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      '& > img': {
        width: '79px',
      },
    },
  },
  healine: {
    left: 480, 
    position: 'absolute',
    top: 250,
    [theme.breakpoints.down('md')]: {
      left: 0,
      marginTop: 54,
      paddingBottom: 60,
      position: 'relative',
      textAlign: 'center',
      top: 0,
    },
  },
  info: {
    alignItems: 'flex-start',
    color: theme.palette.secondary.electric,
    display: 'inline-flex',
    flexDirection: 'column',
    margin: ' 0px 40px 40px 40px',
    maxWidth: 455,
    overflow: 'hidden',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      margin: '10px 50px 0px 50px',
      textAlign: 'center',
    },
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '42px',
    marginBottom: 30,
    maxWidth: 228,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      marginBottom: 46,
    },
  },
  line_text: {
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
    '&::before': {
      background: '#27D9D5',
      bottom: '6px',
      content: '""',
      height: 13,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex: -1
    }
  },
  phone: {
    left: 180,
    maxWidth: 314,
    padding: '10px 0px',
    position: 'absolute',
    top: 221,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  /* phone_back: {
    backgroundImage: 'url(./../images/various/phoneBack.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    left: 180,
    minHeight: 626,
    padding: '10px 0px',
    position: 'absolute',
    top: 221,
    width: 314,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      left: 0,
      margin: '0 auto',
      position: 'relative',
      top: 0,
    },
  },
  phone_video: {
    backgroundImage: 'url(./../images/various/phonePhotoV2.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 30,
    height: 596,
    left: 195,
    padding: ' 10px 0px',
    position: 'absolute',
    top: 234,
    width: 284,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      margin: '0 auto',
      position: 'relative',
      top: '-610px',
    },
  }, */
  qrcode: {
    backgroundImage: 'url(./../images/various/qrcodeWool.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 133,
    width: 133,
    [theme.breakpoints.down('md')]: {
      height: 100,
      width: 100,
    },
  },
  qr_text: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    maxWidth: 200,
  },
  credits: {
    color: theme.palette.common.white,
    fontSize: 9,
    fontStyle: 'italic',
    position: 'absolute',
    right: '-155px',
    top: '120px',
    transform: 'rotate(90deg) translateX(50%)',
    [theme.breakpoints.down('md')]: {
      right: '-149px',
    },
  },
  synopsis: {
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(./../images/various/woolBackground.svg)',
    backgroundPosition: 'top right',
    display: 'flex',
    minHeight: 746,
    [theme.breakpoints.down('md')]: {
      minHeight:0
    },
  },
  synopsisContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',    
    marginTop: 150,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        margin: '80px 0 140px 0',
    },
  },
  synopsisText: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    maxWidth: 873,
    [theme.breakpoints.down('md')]: {
        padding: '0px 15px', 
        },
  },
  bold: {
    fontWeight: 700,
  },
  cardsBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '215px',
    marginTop: -300,
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: -135 ,
    },   
  },
  card_01: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #4050E1',
    borderRadius: '20px 20px 20px 0px',
    color: theme.palette.primary.dark,
    display: 'inline-flex',
    flexDirection: 'column',
    height: 501,
    margin: '64px 8px 0px 8px',
    maxWidth: 340,
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
    }, 
  },
  card_02: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #4050E1',
    borderRadius: '20px 20px 20px 0px',
    color: theme.palette.primary.dark,
    display: 'inline-flex',
    flexDirection: 'column',
    height: 501,
    margin: '0px 8px 0px 8px',
    maxWidth: 340,
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
    }, 
  },
  card_03: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #4050E1',
    borderRadius: '20px 20px 20px 0px',
    color: theme.palette.primary.dark,
    display: 'inline-flex',
    flexDirection: 'column',
    height: 501,
    margin: '64px 8px 0px 8px',
    maxWidth: 340,
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
    }, 
  },
  cardImage: {
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '244px',
    width: '100%',
  },
  cardContent: {
    alignItems: 'center', 
    display: 'flex' ,
    flexDirection: 'column',
    padding: '34px 34px 0px 34px',
  },
  date: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  btn: {
    '& button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.landing.blue': {
        minWidth: 142, 
    },
},
}));

function Wool() {
  const classes = useStyles();
  const navigation = useNavigation();

  const onOpenVideo = () => navigation.openVideo();

  return (
    <div className="App">
      <header>
        <Box className={classes.hero}>
          <Container maxWidth="lg" style={{position: 'relative'}}>
            <Box className={classes.header}>
              <Box flexGrow={1} flexBasis={0}>
                <Box className={classes.logo}>
                  <a href="/">
                    <img src="./../images/logoComplex.svg" alt="Talk2Me" />
                  </a>
                </Box>
              </Box>
              <Box display="flex" flexGrow={1} flexBasis={0} justifyContent="flex-end">
                <Box display="flex">
                  <Button variant="outlined" className="landingFull green" href="/">
                      Voltar
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className={classes.healine}>
              <Box className={classes.info}>
                <Box>
                  <Typography className={classes.title}>
                    Caso <span className={classes.line_text}>WOOL</span> Covilhã Arte Urbana 
                  </Typography>
                </Box>
                <Box mb={2} className={classes.qrcode}></Box>
                <Box>
                  <Typography className={classes.qr_text}>
                    Leia este código e converse com o mural. Actualize o messenger para uma melhor experiência.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.phone}>
              <img src='./../images/various/phone.png' width='100%' />
         {/*  <Box className={classes.phone_back}></Box>
              <Box className={classes.phone_video}></Box>   */}     
            </Box>
          </Container>
          <Box className={classes.credits}>
              Imagens cedidas por WOOL | Covilhã Arte Urbana (autores: Bosoletti e Alberto Montes)
          </Box>
        </Box>
      </header>
      <main>
        <Box className={classes.synopsis}>
          <Container maxWidth="lg">
              <Box className={classes.synopsisContainer}>
                  <Box mb={4} className={classes.synopsisText}>
                    <Typography>
                      A plataforma <span className={classes.bold}>Talk2me</span>, numa parceria entre o <span className={classes.bold}>NEST- Centro de Inovação do Turismo, 
                      o WOOL e a UZER</span>, permite aos visitantes da Covilhã dialogarem com os murais da coleção de Arte Urbana da cidade, através do Facebook Messenger, 
                      podendo descobrir informação relevante sobre as obras, tal como: <span className={classes.bold}>de que artista é, como foi feito, qual a inspiração </span>
                      ou mesmo ver <span className={classes.bold}>um vídeo do making of do mural</span>. 
                      <br/> <br/>
                      Antes de utilizar <span className={classes.bold}>a Plataforma talk2me</span>, os visitantes das obras do <span className={classes.bold}>WOOL</span> teriam de pedir o acompanhamento de um guia ou de materiais de suporte físico para 
                      saberem mais sobre as obras espalhadas nas ruas da cidade. Agora, através da <span className={classes.bold}>a Plataforma talk2me</span>, qualquer visitante com um dispositivo móvel com acesso à 
                      internet poderá descobrir mais sobre cada uma das obras, conversando com o QR Code associado a cada uma delas.
                    </Typography>
                  </Box> 
              </Box>
          </Container>
        </Box>
        <Box className={classes.cardsBox}>
          <Box className={classes.card_01}>
            <Box className={classes.cardImage} style={{backgroundImage: 'url(./../images/various/wool-03.jpg)'}}></Box>
            <Box className={classes.cardContent}>
              <Box mb={1} style={{ width: '100%' }}>
                <Typography variant="h2" style={{ fontWeight: 700 }} >
                  “Fachadas da Covilhã exibem obras de arte.”
                </Typography>
              </Box>
              <Box >
                <Typography  className={classes.date}>
                  in Telejornal TVI , 20/Mar/2021
                </Typography>
              </Box>
              <Box mt={5} className={classes.btn}>
                <Button variant="outlined" className="landing blue" onClick={onOpenVideo}>
                  Ver Vídeo
                </Button>
              </Box> 
            </Box>
          </Box>
          <Box className={classes.card_02}>
            <Box className={classes.cardImage} style={{backgroundImage: 'url(./../images/various/wool-01.jpg)'}}></Box>
            <Box className={classes.cardContent}>
              <Box mb={1} style={{width: '100%'}}>
                  <Typography variant="h2" style={{fontWeight: 700}}>
                      “Talk2me: a plataforma que nos põe a falar com paredes, arte e objetos”
                  </Typography>
              </Box>
              <Box >
                  <Typography  className={classes.date}>
                      in Observador, 02/Mar/2021
                  </Typography>
              </Box>
              <Box mt={4} className={classes.btn}>
                <Button variant="outlined" className="landing blue" target="_blank" href="https://observador.pt/2021/03/02/talk2me-a-plataforma-que-nos-poe-a-falar-com-paredes-arte-e-objetos/">
                    Ver Notícia
                </Button>
              </Box> 
            </Box>
          </Box>
          <Box className={classes.card_03}>
            <Box className={classes.cardImage} style={{backgroundImage: 'url(./../images/various/wool-02.jpg)'}}></Box>
            <Box className={classes.cardContent}>
              <Box mb={1} style={{width: '100%'}}>
                <Typography variant="h2" style={{fontWeight: 700}}>
                    “Talk2Me: Um chatbot que põe os monumentos em Portugal a falar”
                </Typography>
              </Box>
              <Box >
                <Typography  className={classes.date}>
                    in Visão, 25/Fev/2021
                </Typography>
              </Box>
              <Box mt={4} className={classes.btn}>
                <Button variant="outlined" className="landing blue" target="_blank" href="https://visao.sapo.pt/exameinformatica/noticias-ei/mercados/2021-02-25-talk2me-um-chatbot-que-poe-os-monumentos-em-portugal-a-falar/">
                    Ver Notícia
                </Button>
              </Box> 
            </Box>
          </Box>
        </Box>
        <Box> 
            <Box className={classes.floating_green}></Box>
            <Box className={classes.floating_blue}></Box>
        </Box>
      </main>
      <Footer /> 
    </div>
  );
}

export default Wool;