import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  "@global": {
    "@keyframes moveBg": {
      "0%": {
        transform: "translateX(-2.5%)"
      },
      "100%": {
        transform: "translateX(0%)"
      },
    }
  },
  root: {
    borderRadius: '20px',
    boxShadow: '0px 0px 10px rgba(138, 138, 138, 0.25)',
    overflow: 'hidden',
    marginBottom: '8px'
  },
  image: {
    // animation: "moveBg 8s linear",
    // animationFillMode: 'forwards',
    backgroundColor: 'rgb(242, 242, 242)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    paddingTop: '56.25%',
    // left: '-2.5%',
    width: '100%',
  },
}));

function CardTitleImage(props) {
  const classes = useStyles(props);
  const message = props.message;

  return (
    <div className={classes.root}>
      <div>
        {props.image_url ?
          <div
            className={classes.image}
            style={{ backgroundImage: 'url(' + props.image_url.replace(/ /g, "%20") + ')' }}>
          </div>
          : null}
      </div>
      <div style={{ color: '#3A3A3A', padding: '15px 20px' }}>
        <Typography variant='inherit' style={{ fontWeight: 700 }} >
          {props.title ? props.title : null}
        </Typography>
        {props.subtitle ?
          <>
            <br></br>
            <Typography variant='inherit' >
              {props.subtitle}
            </Typography>
          </> : null}
      </div>
    </div>
  );
}

export default CardTitleImage;