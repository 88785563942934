import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, } from '@material-ui/core';
import Button from './Button';
import CardTitleImage from './CardTitleImage';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  buttons: props => ({
    filter: props.theme_color ? 'drop-shadow(0px 0px 10px rgba(138, 138, 138, 0.25))' : 'drop-shadow(0px 0px 10px rgba(102, 115, 231, 0.25))',
    listStyle: 'none',
    margin: '0 auto',
    padding: 0,
    flex: 1,
    width: '100%'
  }),
  zoomImage: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: '56.25%',
    width: '100%',
    top: '-35px',
    right: '10px',
    zIndex: 2,
  }
}));

function TemplatePlace(props) {
  const classes = useStyles(props);

  const sortByOrder = array => {
    const sorted = array.sort(function (a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  const getTitle = element => {
    let title = element && element.conversation ? element.conversation.name : '';
    return title;
  }

  const getImage = element => {
    let image = element && element.conversation && element.conversation.image ? element.conversation.image.publicUrl : null
    return image;
  }

  return (
    <div style={{ width: props.slider ? '85%' : '100%', margin: props.slider ? '10px' : '0', display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative' }}>
        <CardTitleImage
          image_url={getImage(props.element)}
          title={getTitle(props.element)}
        />
      </div>
      <div className={classes.buttons}>
        {
          sortByOrder(props.element.buttons).map((button, i) => {
            if (!button.disabled) {
              return <div key={i}>
                {
                  button.type === "web_url" ?
                    <Button lang={props.lang} button={button} conversation={props.conversation} theme_color={props.theme_color}>
                      {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                    </Button>
                    :
                    (
                      button.type === "maps" ?
                        <Button lang={props.lang} button={button} onClick={() => props.onResponse(props.element)} theme_color={props.theme_color}>
                          {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                        </Button>
                        :
                        <Button lang={props.lang} button={button} onClick={() => props.onResponse(button)} theme_color={props.theme_color}>
                          {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                        </Button>
                    )

                }
              </div>
            }
          })
        }
      </div>
    </div>
  );
}

export default TemplatePlace;