import React, { useContext, useEffect, useState } from 'react';
import FaqsSidebar from './FaqsSidebar';
import FaqsContent from './FaqsContent';

import { ServicesContext } from '../../../context/services';

import {
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0px',
    }
  },
  sidebar: {
    backgroundColor: '#EBEDF5',
    position: 'fixed',
    height: '220px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'block !important',
      paddingLeft: '15px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxWidth: '400px',
      height: '100vh',
    }, 
  },
  content: {
    background: theme.palette.background.dark,
    padding: '24px 42px',
    position: 'fixed',
    top: '220px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 200px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      top: '0px',
      left: '470px',
      fontWeight: 300,
      display: 'flex !important',
      flexDirection: 'column',
      alignIitems: 'flex-start',
      padding: '24px 42px',
      height: '100vh',
      width: '100%',
    }, 
  },
}));


const FaqsView = () => {
  const classes = useStyles();
  const { services } = useContext(ServicesContext);
  //const [faqs, setFaqs] = useState({});
  const [faqCategories, setFaqCategories] = useState(null);
  const [currentID, setID] = useState(0);
  
  useEffect(function () {
    services.faqsApi.getAll().then(({ allFaqs }) => {
      //setFaqs(allFaqs);
      parseCategories(allFaqs);
    });
  }, []);

  const parseCategories = faqs => {
    let categories = [];
    faqs.map((faq) => {
      if(!categories[faq.category.order-1]) categories[faq.category.order-1] = { title: faq.category.title, items:[faq] }
      else categories[faq.category.order-1].items.push(faq);
    });
    setFaqCategories(categories);
  }

  const onSelected = id => {
    setID(id);
    const section = document.querySelector( '#faq-'+id );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.sidebar}>
        <FaqsSidebar page="FAQs" categories={faqCategories} onSelected={onSelected} currentID={currentID}/>
      </Box>
      <Box className={classes.content}>
          <Box>
            {
              faqCategories && faqCategories.length > 0 ? faqCategories.map((category, index) => (
                category.items.length > 0 ? category.items.map((faq, index) => (
                  <Box key={index} id={'faq-' + faq.id}>
                    <FaqsContent key={faq.id} question={faq.title} answer={faq.text}/>
                  </Box>
                )) : null
              )) : null 
            }
        </Box>
      </Box>
    </Box>
    );
  };
  
  export default FaqsView;
  