import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Icon from "./../../components/Icon";

import ModalView from './ModalView';
import ModalQrCode from './ModalQrCode';
import ModalDelete from './ModalDelete';


import { ServicesContext } from '../../../context/services';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import DotIcon from '@material-ui/icons/Brightness1Rounded';
import ModalCharts from './ModalCharts';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    textAlign: "left",
    fontWeight: 300,
  },
  body: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 300,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F8FAFE',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 500,

  },
  value: {
    color: theme.palette.primary.dark,
  },
  table: {
    minWidth: 650,
    minWidth: '1243px',
  },
  btn: {
    height: '34px',
    width: '102px',
    border: '1px solid #4050E1',
    borderRadius: '6px',
    textTransform: 'none',
    marginLeft: '6px',
  },
}));

function createData(name, location, tag, status, date, actions) {
  return { name, location, tag, status, date, actions };
}

const icons = (
  <Box display='flex' justifyContent='center'>
    <ModalView />
    <ModalQrCode />
    <Link to="/backoffice/create">
      <IconButton >
        <Icon icon="edit" size={25} color="#4050E1" />
      </IconButton>
    </Link>
  </Box>
);

const tagLisboa = (
  <Box display="flex" alignItems='center' justifyContent="center" style={{ width: '100%', }}>
    <Box display="flex" alignItems='center' justifyContent="center" style={{ background: '#A457BE', width: 72, height: 19, borderRadius: 2 }}>
      <Typography
        variant="h5"
        style={{ color: 'white', fontWeight: 400, paddin: '2px 6px' }}
      >
        CM Lisboa
      </Typography>
    </Box>
  </Box>
);

const parseIds = (conversations) => {
  var ids = '';
  for (var i = 0; i < conversations.length; i++) {
    if (ids.length) ids += ',';
    ids += `"${conversations[i].id}"`
  }
  return ids;
};

const checkInteractions = (interactions) => {
  let result = { start: 0, postback: 0, link: 0, users: 0 };
  let users = {}, interaction;

  for (var i = 0; i < interactions.length; i++) {
    interaction = interactions[i];
    // if (!users[interaction.user.id]) {
    //   users[interaction.user.id] = true;
    //   result.users++;
    // }
    result[interaction.type]++;
  }

  return result;
}

const Conversations = ({ user, onStats }) => {
  const classes = useStyles();
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  const [conversations, setConversations] = useState({});

  useEffect(function () {
    getConversations();
  }, []);

  const getConversations = () => {
    let promise;

    console.log(user);
    
    if(!user.isAdmin) {
      promise = services.conversationsApi.getAllByUser(user.id)
    } else promise = services.conversationsApi.getAll();

    promise.then(({ allConversations }) => {
      const ids = parseIds(allConversations);
      // services.interactionsApi.getByConversations(ids).then(({ allInteractions }) => {
      //   const stats = checkInteractions(allInteractions);
      //   if (onStats) onStats(stats);
      // });
      setConversations(allConversations);
    });
  }

  const onDeleteConversation = id => {
    services.conversationsApi.delete(id).then(({ deleteConversation }) => {
      if (deleteConversation.id) {
        addToast('Conversa removida.', { appearance: 'success', autoDismiss: true });
        getConversations();
      }
    });
  }

  const setDate = date => {
    const newDate = new Date(date);
    return newDate.toISOString().substr(0, 10).split('-').reverse().join('/');
  }

  return (
    <Card
      className={classes.root}
    >
      <CardContent style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ paddingRight: '24px', paddingLeft: '24px' }}>
            <Box display="flex" justifyContent="space-between" alignContent="center">
              <Typography className={classes.title} variant="h2">
                Conversas
              </Typography>
              <Link to="/backoffice/create" style={{ textDecoration: 'none' }}>
                <Button className={classes.btn} variant="contained" color="primary" >
                  Criar Conversa
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
            <Box mt={3}>
              <TableContainer >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow >
                      <StyledTableCell>Nome</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>Tags</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>Estado</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>Data</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>Ações</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      conversations.length > 0 ? conversations.map((conversation) => (
                        <StyledTableRow key={conversation.id}>
                          <StyledTableCell style={{ fontWeight: 500 }}>{conversation.name}</StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {conversation.tag ? conversation.tag.split(',').map((tag, index) => (
                              <Chip
                                key={tag}
                                label={tag}
                                color="primary"
                                style={{ margin: '5px' }}
                              />
                            )) : ''}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}><DotIcon style={{ marginRight: '2px', fontSize: '14px', color: conversation.status ? "#32D30A" : "#D32E0A" }} fontSize="small" /> {conversation.status ? 'Online' : 'Offline'}</StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>{conversation.createdAt ? setDate(conversation.createdAt) : 'N/A'}</StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center", color: "#4050E1" }}>
                            <Box display='flex' justifyContent='center'>
                              <Box>
                                <a href={conversation.url} target="_blank">
                                  <IconButton >
                                    <Icon icon="eye" size={25} color="#4050E1" />
                                  </IconButton>
                                </a>
                              </Box>
                              {/*<ModalView/>*/}
                              <ModalQrCode qrcode={conversation.qrcode} name={conversation.name} />
                              <ModalCharts id={conversation.id} name={conversation.name}/>
                              <Link to={"/backoffice/edit/" + conversation.id} >
                                <IconButton >
                                  <Icon icon="edit" size={25} color="#4050E1" />
                                </IconButton>
                              </Link>
                              <ModalDelete id={conversation.id} name={conversation.name} onDeleteConversation={onDeleteConversation} />
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      )) : null
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Conversations;
