import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles,  } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: props => ({ 
    background: '#F3F7FF',
    borderRadius: '20px',
    color: props.theme_color ? props.theme_color : '#4050E1',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '5px 5px',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
  })
}));

function Option(props) {
  const classes = useStyles(props);

  return ( 
       <div className={classes.root} {...props}>
            { props.children }
        </div>
  );
}

export default Option;