export default function makeConversationsApi({
    query,
    mutation,
}) {
    const conversationFragment = `
        id
        name
        white_label
        logo {
            publicUrl
        }
        theme_color
        image {
            publicUrl
        }
        place_address
        place_lng
        place_lat
        place_image
        qrcode
        url
        show_english
        status
        tag
        user {
            id
        }
        start {
            id
        }
        createdAt
    `;

    const shortFragment = `
        id
        name
        qrcode
        url
        status
        tag
        createdAt
    `;

    return {
        getAllByUser: (uid) => query(`
            query {
                allConversations(where: { user: { id: "${uid}" } }) { ${shortFragment} }
            }
        `),

        getAll: (uid) => query(`
            query {
                allConversations { ${shortFragment} }
            }
        `),

        getById: id => query(`
            query{
                Conversation(where: {id: "${id}"})  { ${conversationFragment} }
              }
        `),

        create: data => mutation(`
            mutation {
                createConversation(data: ${data}) { id }
            }
        `),

        createAndUpload: (data, image) => mutation(`
            mutation updateQuery ($image: Upload) {
                createConversation(data: ${data}) { ${conversationFragment} }
            }
            `, { image }
        ),

        createAndUploadLogo: (data, logo) => mutation(`
            mutation updateQuery ($logo: Upload) {
                createConversation(data: ${data}) { ${conversationFragment} }
            }
            `, { logo }
        ),

        createAndUploadFileLogo: (data, image, logo) => mutation(`
            mutation updateQuery ($image: Upload, $logo: Upload) {
                createConversation(data: ${data}) { ${conversationFragment} }
            }
            `, { image, logo }
        ),

        update: (id, data) => mutation(`
            mutation {
                updateConversation(id: "${id}", data: ${data}) { ${conversationFragment} }
            }
        `),

        updateAndUpload: (id, data, image) => mutation(`
            mutation updateQuery ($image: Upload) {
                updateConversation(id: "${id}", data: ${data}) { ${conversationFragment} }
            }
            `, { image }
        ),

        updateAndUploadLogo: (id, data, logo) => mutation(`
            mutation updateQuery ($logo: Upload) {
                updateConversation(id: "${id}", data: ${data}) { ${conversationFragment} }
            }
            `, { logo }
        ),

        updateAndUploadFileLogo: (id, data, image, logo) => mutation(`
            mutation updateQuery ($image: Upload, $logo: Upload) {
                updateConversation(id: "${id}", data: ${data}) { ${conversationFragment} }
            }
            `, { image, logo }
        ),

        update: (id, data) => mutation(`
            mutation {
                updateConversation(id: "${id}", data: ${data}) { ${conversationFragment} }
            }
        `),

        delete: id => mutation(`
            mutation {
                deleteConversation(id: "${id}") { id }
            }
        `),
    };
}