import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';


import Icon from "./../../components/Icon";

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: "center",
    flexDirection: "column",
    justifyContent: 'center',
    margin: '20px 0',
    maxWidth: '50%',
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      maxWidth: 'none',
      float: 'none',
      paddingTop: 10,
      paddingBottom: 0,
    },
  },
  button: {
    color: theme.palette.grey.main,
    borderRadius: "10px 10px 10px 0px",
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '62px',
    height: '62px',
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  icon: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
    font: '10px'
  },
  active: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({ className, href, icon, title, ...rest }) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        exact
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        <Icon
          className={classes.icon}
          icon={icon}
          size="28"
        />
        <span className={classes.title}>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
