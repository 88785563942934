import initApollo, { makeQuery, makeMutation } from '../core/lib/apollo';

import makeConversationssApi from './conversations';
import makeActionsApi from './actions';
import makeResponsesApi from './responses';
import makeOptionsApi from './options';
import makeButtonsApi from './buttons';
import makeElementsApi from './elements';
import makeFaqsApi from './faqs';
import makeLeadsApi from './leads';
import makeTutorialsApi from './tutorials';
import makeInteractionsApi from './interactions';

const apolloClient = initApollo();
const query = makeQuery(apolloClient);
const mutation = makeMutation(apolloClient);

const conversationsApi = makeConversationssApi({ query, mutation });
const actionsApi = makeActionsApi({ query, mutation });
const responsesApi = makeResponsesApi({ query, mutation });
const optionsApi = makeOptionsApi({ query, mutation });
const buttonsApi = makeButtonsApi({ query, mutation });
const elementsApi = makeElementsApi({ query, mutation });
const faqsApi = makeFaqsApi({ query, mutation });
const leadsApi = makeLeadsApi({ query, mutation });
const tutorialsApi = makeTutorialsApi({ query, mutation });
const interactionsApi = makeInteractionsApi({ query, mutation });

const services = {
    conversationsApi,
    interactionsApi,
    actionsApi,
    responsesApi,
    optionsApi,
    buttonsApi,
    elementsApi,
    leadsApi,
    faqsApi,
    tutorialsApi
};

export default services;