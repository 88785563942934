import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles,  } from '@material-ui/core'
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center', 
    filter: 'drop-shadow(0px 4px 25px rgba(31, 57, 150, 0.25))',
    fontWeight: 600,
    position: 'fixed',
    top: 0,
    left: '50%', 
    transform: 'translateX(-50%)', 
    maxWidth: '420px', 
    width: '100%',
    zIndex: 2,
  },
  back_btn: {
    cursor: 'pointer',
    marginRight: '5px'
  },
  header:{
    position: 'relative',
    borderBottomRightRadius: props => props.lang ? '0%' : '50%',
    overflow: 'hidden',
    height: props => props.lang ? '45px' : '50vh',
    transition: 'all 200ms',
    width: '100%',
    zIndex: 2,
  },
  content: {
    background: props => props.lang ? (props.theme_color ? props.theme_color : '#4050E1') : 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    alignItems: props => props.lang ? 'center' : 'initial',
    flexDirection: 'column',
    flexDirection: props => props.lang ? 'row' : 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    padding: props => props.lang ? '10px 10px' : '20px 25px',
    transition: 'all 200ms',
  },
  logo:{
    display: props => props.lang ? 'none' : 'block',
  },
  name: {
    color: '#fff',
    fontSize: props => props.lang ? '16px' : '26px',
    lineHeight: props => props.lang ? '18px' : '30px',
    maxWidth: props => props.lang ? '100%' : '50%',
    fontWeight: 600,
    textTransform: 'uppercase',
    transition: 'all 200ms',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    opacity: props => props.lang ? '0' : '1',
    transition: 'opacity 200ms',
  },
  lang_toggle:{
    display: 'flex',
  },
  lang_item: {
    cursor: 'pointer',
    marginLeft: '4px'
  }
}));

function Header(props) {
  const classes = useStyles(props);

  const onBackHandler = event => {
    props.onBack();
  }
  
  return ( 
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img src={props.conversation.white_label && props.conversation.logo && props.conversation.logo.publicUrl ? props.conversation.logo.publicUrl : "./../images/logo.svg"} alt="Talk2Me" height="100px" />
          </div>
          <div className={classes.name}>{props.conversation.name} </div>
          { props.lang && props.conversation.show_english ? 
            <div className={classes.lang_toggle}>
              <div 
                className={classes.lang_item} 
                style={{color: props.lang === 'pt' ? (props.conversation.theme_color ? '#fff' : '#00E7E4') : 'rgb(255 255 255 / 50%)'}}
                onClick={() => props.updateLang('pt')}
              >PT
              </div>
              <div 
                className={classes.lang_item} 
                style={{color: props.lang === 'en' ? (props.conversation.theme_color ? '#fff' : '#00E7E4') : 'rgb(255 255 255 / 50%)'}}
                onClick={() => props.updateLang('en')}
              >
                EN
              </div>
            </div> 
          : null}
        </div>
        <div 
          className={classes.image}
          style={{backgroundImage: `url('${props.conversation.image ? props.conversation.image.publicUrl : ""}')`}}
        ></div>
      </div>
    </div>
  );
}

export default Header;