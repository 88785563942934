/*import React from 'react';
import ReactDOM from 'react-dom';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Router from './router';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
*/

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import HttpsRedirect from 'react-https-redirect';

import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'core/services/auth';

import initApollo from 'core/lib/apollo';

// Init apollo client
const client = initApollo();

ReactDOM.render(
  <HttpsRedirect>
    <ApolloProvider client={client}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </HttpsRedirect>,
  document.getElementById('root')
);
