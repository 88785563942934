import React, { Component } from 'react';
import Layout from '../layouts';

import SettingsView from '../views/SettingsView';

class Settings extends Component {

  render() {
      return (
        <Layout>
          <SettingsView />
        </Layout>
      );
  }
}

export default Settings;
