import React, { useEffect, useState } from 'react';

import {
  makeStyles,
  Modal,
  Backdrop,
  Box,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';import Icon from '../../../../components/Icon';
import { Fragment } from 'react';
;

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 384,
      background:'#FFFFFF',
      borderRadius: '6px',
      padding: 32,
      outline: 'none',
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
    },
    subtitle: {
      fontWeight: 400,
      marginTop: 5,
    },
    box: {
      marginTop: 35,
      width: 320,
      height: 67,
      background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 47.92%, #F3F3F3 100%)',
      border: '1px solid #DBDDF1',
      boxSizing: 'border-box',
      borderRadius: '6px',
    },
    item: {
      fontWeight: 400,
      textAlign: 'left',
      padding:'23px 14px 23px 14px',
    },
    btnDelet: {
    width: '160px',
    height:'34px',
    borderRadius: '6px',
    marginTop:'16px',
    marginLeft:'6px',
    textTransform: 'none',
    },
}));

const ModalRemove = props => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    /*
    useEffect(() => {
      setOpen(props.open);
    }, [props.open]);
    */

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmRemove = e => {
      props.onConfirmRemove()
      setOpen(false);
    }

    return (
      <Fragment>
        {
          props.type === 'button' ? 
            <Button variant="outlined" color="primary" style={{borderRadius: '34px' }} onClick={handleOpen}>
              {props.label ? props.label : 'Remover'}
            </Button>
          : 
            <IconButton onClick={handleOpen}>
              <Icon icon="delete" size={25} color="#4050E1" />
            </IconButton>
        }
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{timeout: 500,}}
        >
          <Slide in={open} timeout={500} >
            <Box className={classes.paper}>
              <Typography
              className={classes.title}
              variant="h2"
              >
                {props.title ? props.title : 'Remover'}
              </Typography>
              <Typography
              className={classes.subtitle}
              variant="h4"
              >
                {props.subtitle ? props.subtitle : 'Tem a certeza que pretende remover?'}
              </Typography>
              <Box display="flex" mt={2}>
                <Button
                  variant="outlined" 
                  color="primary" 
                  onClick={confirmRemove} 
                  style={{borderRadius: '32px', textTransform: 'none', margin: '0 5px' }}
                >
                  Remover
                </Button>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleClose} 
                  style={{borderRadius: '32px', textTransform: 'none', margin: '0 5px' }}
                >
                  Voltar
                </Button>
              </Box>
            </Box>
          </Slide>
        </Modal>
      </Fragment>
    );
}

export default ModalRemove;