import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core'
import Icon from './Icon';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgb(0 0 0 / 45%)',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    // padding: '10px',
    position: 'fixed !important',
    top: 0,
    paddingTop: '45px',
    height: '100vh',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '420px',
    width: '100%',
    zIndex: 10,
    scrollSnapAlign: 'none !important',
  },
  container: {
    // background: '#6673E7',
    // borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 600,
    top: 55,
    padding: '0 15px',
    position: 'fixed',
    zIndex: 2,
    width: '100%'
  },
  close_btn: {
    cursor: 'pointer'
  }
}));

const ModalZoomImage = (props) => {
  const classes = useStyles();

  const onClose = e => {
    if(e.target === e.currentTarget) props.onClose();
  }

  return (
    <div className={classes.root} onClick={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.close_btn} onClick={() => props.onClose()}>
            <Icon icon="close-small" size="35" />
          </div>
        </div>
        <TransformWrapper centerOnInit={true}>
          <TransformComponent wrapperStyle={{ maxWidth: "100%", maxHeight: 'calc(100vh - 45px)'}}  >
          <img src={props.element.image && props.element.image.publicUrl ? props.element.image.publicUrl : null } style={{height: '100vh'}}/>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  ) 
}

export default ModalZoomImage;