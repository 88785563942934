import React from 'react';

import { Box,Container, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        display: 'flex',
        minHeight: 656,
        paddingTop: 20,
        marginBottom: 180,
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 100,
        },
    },
    background: {
        backgroundImage: 'url(./../images/various/statsBackground.svg)',
        backgroundPosition: 'center 35px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'auto',
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
            background: '#27D9D5',
            bottom: '6px',
            content: '""',
            height: 13,
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1
        }
    },
    card: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,        
        border: '2px solid #00E7E4',
        borderRadius: '20px 20px 20px 0px',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        flexDirection: 'column',
        height: 500,
        margin: 20,
        maxWidth: 300,
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            margin: 10,
        },
    },
    image: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginTop: 46,
        minHeight: '156px',
        width: '166px',
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '34px 34px 0px 34px',
        textAlign: 'center',
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '22px',
    },
    bold: {
        fontWeight: 700,
    },
}));


export default function What() {
    const classes = useStyles();

  return (
    <Box id='what' className={classes.root}>
        <Box className={classes.background}>
            <Container maxWidth="lg">
                <Box className={classes.content}>
                    <Box mb={14}>
                        <Typography className={classes.title} >
                            O que é o <span className={classes.line_text}>Talk2me</span>
                        </Typography>
                    </Box> 
                    <Box display='inline-flex' alignItems='center' flexWrap='wrap' justifyContent='center' >
                            <Box className={classes.card}>
                                <Box className={classes.image} style={{backgroundImage: `url(./../images/various/museumCircle.svg)`}}>
                                </Box>
                                <Box className={classes.text}>
                                    <Typography  className={classes.rich_text}>
                                        Talk2me é um <span className={classes.bold}>CHATBOT</span> que permite criar <span className={classes.bold}>CONVERSAS ESCRITAS </span> 
                                        com “objectos”, através do telemóvel. É como se os “objectos” ganhassem vida e conseguissem, de facto,  responder. 
                                        <span className={classes.bold}>1.3 biliões de pessoas usam o Facebook Messenger.</span>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.card}>
                                <Box className={classes.image} style={{backgroundImage: `url(./../images/various/frameCircle.svg)`}}>
                                </Box>
                                <Box className={classes.text}>
                                    <Typography  className={classes.rich_text}>
                                        Adapta-se a <span className={classes.bold}>QUALQUER </span> necessidade ou <span className={classes.bold}>NEGÓCIO</span>: monumentos, 
                                        jardins, roteiros, produtos regionais, nomes de ruas, galerias de arte… É ideal para criar uma maior ligação com os clientes ou visitantes.​
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.card}>
                                <Box className={classes.image} style={{backgroundImage: `url(./../images/various/qrCircle.svg)`}}>
                                </Box>
                                <Box className={classes.text}>
                                    <Typography  className={classes.rich_text}>
                                        <span className={classes.bold}>CRIE UMA CONVERSA EM MINUTOS</span>. Define-se, na plataforma, um fluxo de conversa. É gerado um QRCode. 
                                        Depois, basta apontar o telemóvel e podemos conversar com o “objecto”, via Facebook Messenger. 
                                        <span className={classes.bold}> NÃO É NECESSÁRIO DOWNLOAD DE APP</span>.
                                    </Typography>
                                </Box>
                            </Box>
                    </Box> 
                </Box>
            </Container>
        </Box>
    </Box>
  )
}