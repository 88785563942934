import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F7F8FA',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#4050E1',
      dark: '#1F3996'
    },
    secondary: {
      main: '#00E7E4',
      electric: '#27D9D5'
    },
    text: {
      primary: '#3A3A3A',
      secondary: colors.blueGrey[600]
    },
    green: {
      main: '#94E66D'
    },
    red: {
      main: '#F16E6E'
    },
    grey: {
      main: '#DBDDF1',
      light: '#F3F7FF',
      dark: '#3A3A3A',
    },
  },
  shadows,
  typography,
  overrides: {
    MuiTypography: {
      root: {
        '&.line': {
          display: 'inline-block',
          position: 'relative',
          zIndex: 1,
          '&::before': {
            background: '#27D9D5',
            content: '""',
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: '6px',
            height: 13,
            zIndex: -1
          },
          '&.white': {
            '&.line': {
              '&::before': {
              background: '#ffff',
              },
            },
          },
        }
      },
    },
    MuiFilledInput: {
      input: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormControl: {
      root: {
        background: "#fff",
        borderRadius: '6px',
        overflow: 'hidden',
      },
    },
    MuiInputBase: {
      input: {
        background: "#fff"
      },
    },
    MuiButton: {
      outlined: {
        border:'2px solid rgba(0, 0, 0, 0)',
      },
      root: {
        fontSize: "12px",
        color: '#5C5C5C"',
        fontWeight: 400,
        '&.landing': {
          textTransform: 'none',
          fontSize: '18px',
          lineHeight: '26px',
          fontWeight: 500,
          minWidth: '172px',
          borderRadius: '20px 20px 20px 0px',
          borderColor: 'inherit',

        },        
        '&.landingFull': {
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '26px',
          fontWeight: 500,
          minWidth: '94px',
          borderRadius: '20px 20px 20px 0px',
          borderColor: 'none',
          background:"#fff",
        },
        '&.green': {
          color: '#27D9D5'
        },
        '&.blue': {
          color: '#1F3996'
        },
        '&.white': {
          color: '#ffff'
        }
      },
      label: {
        display: 'flex',
        alignItems:"center",
        flexDirection:"column",
        justifyContent: 'center',
        width: 'auto'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        boderColor: 0,
      },
    },
    MuiTabScrollButton: {
      root: {
        '&.Mui-disabled':{
          display: 'none'
        }
      }
    },
    MuiTab: {
      root: {
        minWidth: '0px !important'
      }
    }
  },
});

export default theme;