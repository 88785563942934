import React, { useState } from 'react';

import {
  Typography,
  Button,
  Slide,
  Input,
  Box
} from '@material-ui/core';

import { useStyles } from '../style';
import { useAuth } from 'core/services/auth';
import { useNavigation } from 'core/services/navigation';
import { useToasts } from 'react-toast-notifications';

const SigninView = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: '', password: '' });
  const { signin } = useAuth();
  const { addToast } = useToasts();
  const navigation = useNavigation();

  const onRecover = () => navigation.openRecover();

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.id]: e.target.value
    });
  };

  const handleOnSubmit = async data => {
    try {
      await signin(user);
      navigation.goToDashboard();
    } catch (error) {
      console.log(error);
      addToast('Dados inválidos.', { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Slide in={true} timeout={500}>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h1" className="line">Login</Typography>
          <Box mt={2}>
            <Typography>Para entrar na sua Conta, introduza os seus dados.</Typography>
          </Box>
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Email do utilizador"
            type="email"
            id="email"
            value={user.email}
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Password"
            type="password"
            id="password"
            value={user.password}
            onChange={handleChange}
          />
        </Box>
        <Box textAlign="left">
          <Box className={classes.lostPasswordBtn} onClick={onRecover}>Esqueceu-se da password?</Box>
        </Box>
        <Button variant="outlined" className="landing green" style={{ marginTop: 32 }} onClick={handleOnSubmit}>
          Login
        </Button>
      </Box>
    </Slide>
  );
}

export default SigninView;