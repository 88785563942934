import React, { useState, useContext } from 'react';

import { Box, Button, Container, makeStyles, Typography, Input, } from '@material-ui/core';

import { ServicesContext } from '../../context/services';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url(./../images/various/talkToUsBackground.jpg)',
        display: 'flex',
        minHeight: 450,
        padding: '40px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '82px 0px',
        },
    },
    block: {
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-evenly',
        height: '100%',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    title: {
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
            background: '#27D9D5',
            bottom: '6px',
            content: '""',
            height: 13,
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1
        }
    },
    rich_text: {
        fontWeight: 400,
        /*         fontSize: '14px',
                lineHeight: '22px', */
        fontSize: '16px',
        lineHeight: '23px',
        '& > a': {
            color: '#27D9D5',
            textDecoration: 'none',
        },
    },
    input: {
        borderBottom: '1px solid #fff',
        color: theme.palette.common.white,
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '26px',
        margin: '15px 0',
        paddingBottom: '10x',
        '& > input:-webkit-autofill': {
            'transition': 'background-color 5000s ease-in-out 0s',
            '-webkit-text-fill-color': 'white !important',
        },
        '& > ::placeholder': {
            opacity: 1,
        },
        '& input': {
            background: 'transparent',
        },
        '&::after': {
            bottom: '-1px',
            borderBottom: '2px solid #27D9D5',
        },
        '&::before': {
            bottom: '-1px',
            borderBottom: '1px solid #ffff',
        },
    }
}));

export default function TalkToUs() {
    const classes = useStyles();
    const [lead, setLead] = useState({ name: '', email: '', phone: '' });
    const [submitted, setSubmitted] = useState(false);
    const { services } = useContext(ServicesContext);

    const handleChange = e => {
        setLead({
            ...lead,
            [e.target.id]: e.target.value
        });
    };


    const handleOnSubmit = () => {
        if (lead.name != '' && lead.email) {
            services.leadsApi.create(lead).then(({ createLead }) => {
                if (createLead.id) {
                    setLead({ name: '', email: '', phone: '' });
                    setSubmitted(true);
                }
            });
        }
    };
    return (
        <Box id='talk' className={classes.root}>
            <Container maxWidth="lg" >
                <Box className={classes.block}>
                    <Box>
                        <Box mb={4}>
                            <Typography className={classes.title}>
                                Fale <span className={classes.line_text}>connosco</span>
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography className={classes.rich_text}>
                                Quer experimentar criar as suas conversas?
                             <br />
                             Deixe-nos os seus dados para falarmos consigo.
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <Input
                                className={classes.input}
                                placeholder="Nome*"
                                type="text"
                                id="name"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box>
                            <Input
                                className={classes.input}
                                placeholder="E-mail*"
                                type="email"
                                id="email"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box>
                            <Input
                                className={classes.input}
                                placeholder="Número de Telefone"
                                type="text"
                                id="phone"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box mt={2}>
                            {!submitted ?
                                <>
                                    <Button variant="outlined" className="landing green" onClick={handleOnSubmit}>
                                        Submeter
                                    </Button>
                                    <Box mt={2}>
                                        <span style={{ fontStyle: 'italic' }}>*campos obrigatórios</span>
                                    </Box>
                                </>
                                :
                                <>
                                    <Button variant="outlined" className="landing white" style={{ pointerEvents: 'none' }} onClick={handleOnSubmit}>
                                        Submeter
                                    </Button>
                                    <Box mt={2}>
                                        <span style={{ fontStyle: 'italic' }}>Entraremos em contacto brevemente!</span>
                                    </Box>
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}