import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles,  } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  
}));

function MessageText(props) {
  const classes = useStyles();
  const [message, setMessage] = useState(props.message);

  return (
    <li className={message.user ? 'user' : 'bot' }>
      { 
        props.lang === 'en' ? (message.text_en && !message.title_en ? message.text_en : message.title_en)
        : (message.text_pt && !message.title_pt ? message.text_pt : message.title_pt) }
    </li>
  );
}

export default MessageText;