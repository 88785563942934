import React, { Fragment, useContext, useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom'

import {
  Card,
  CardMedia,
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ActionView from '../../ActionView';
import ModalEditTemplateImages from "./ModalEditTemplateImages";
import Icon from "../../../../components/Icon";
import { ServicesContext } from '../../../../../context/services';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 380,
    padding: '18px'
  },
  media: {
    // height: 193,
    borderRadius: 4,
    paddingTop: '56.25%',
    margin: '40px 0px 18px 0px',
  },
  btn: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    borderColor: 'transparent',
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    height: '34px',
    width: '100%'
  },
  btnActive: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    height: '34px',
    width: '100%'
  },
  actionBtn: {
    backgroundColor: '#F3F7FF',
    border: '1px solid #4050E1',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '18px',
    margin: '15px 0',
    padding: '8px 12px',
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      top: '-10px',
      height: '5px',
    },
    '&::after': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      bottom: '-5px',
      height: '5px',
    },
  }
}));

const TemplateImages = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState(null);
  const [action, setAction] = useState(null);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  useEffect(() => {
    checkTabType();
    if (action && data.elements[currentTabIndex] && data.elements[currentTabIndex].buttons) {
      const updateAction = data.elements[currentTabIndex].buttons.find(button => button.id === action.id);
      setAction(updateAction);
    }
  }, [currentTabIndex, data]);

  const onOptionSelect = button => {
    setAction(button);
  }

  const sortElements = elements => {
    const sorted = elements.sort(function(a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  const parseElements = actions => {
    let arr = '';
    for (var i = 0; i < actions.length; i++) {
      arr += `{ id: "${actions[i].id}" }`;
    }
    return arr;
  }

  const handlerSave = () => {
    const elementIDs = parseElements(data.elements);
    let serviceData = `{ elements: { disconnectAll: true, connect: [ ${elementIDs} ] }}`;

    services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
      // console.log('updateTemplate', updateResponse)
      setData(updateResponse);
      addToast('Alterações gravadas.', { appearance: 'success', autoDismiss: true });
    });
  }

  const handlerRemove = () => {
    props.onRemoveResponse(data.id);
  }

  const updateElementInArray = (elements, newElement) => {
    return elements.map(element => {
      if (element.id !== newElement.id) {
        return element;
      }
      return newElement;
    });
  }

  const handleChange = element => {
    // console.log('handleChange', element)
    const newElements = updateElementInArray(data.elements, element);
    setData({
      ...data,
      ['elements']: sortElements(newElements)
    });
  };

  const onChangeSort = elements => {
    setData({
      ...data,
      ['elements']: sortElements(elements)
    });
  }

  const onAddNewTab = () => {
    var elementData = { type: data.type === 'template' ? 'generic' : (data.type === 'template_images' ? 'images' : 'places'), title_pt: 'Nova tab', title_en: 'New tab', subtitle_pt: '', subtitle_en: '' };
    services.elementsApi.create(elementData).then(({ createElement }) => {
      if (createElement.id) {
        // console.log('New element created', createElement.id)
        const newElements = data.elements;
        newElements.push(createElement);

        const elementIDs = parseElements(newElements);
        let serviceData = `{ elements: { disconnectAll: true, connect: [ ${elementIDs} ] }}`;

        services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
          // console.log('New element added to template', updateResponse)
          setData({ ...data, updateResponse });
          addToast('Nova tab adicionada.', { appearance: 'info', autoDismiss: true });
        });
      }
    });
  }

  const onRemoveTab = id => {
    if (id) {
      services.elementsApi.delete(id).then(({ deleteElement }) => {
        if (deleteElement.id === id) {
          setCurrentTabIndex(0);
          const newElements = data.elements.filter(function (element) { return element.id !== deleteElement.id });
          if (newElements.length === 0) {
            // console.log('There is no more elements, so lets remove this response.', props.data.id);
            props.onRemoveResponse(props.data.id);
          } else {
            const elementIDs = parseElements(newElements);
            const serviceData = `{ elements: { disconnectAll: true, connect: [ ${elementIDs} ] }}`;
            services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
              // console.log('onRemoveTab', updateResponse)
              setData({ ...data, ['elements']: updateResponse.elements });
            });
            addToast('Tab removida.', { appearance: 'info', autoDismiss: true });
          }
        }
      });
    }
  }

  const onPrevTab = () => {
    if (currentTabIndex !== 0) {
      setCurrentTabIndex(currentTabIndex - 1);
      setAction(null);
    }
  }

  const onNextTab = () => {
    if (currentTabIndex !== data.elements.length - 1) {
      setCurrentTabIndex(currentTabIndex + 1);
      setAction(null);
    }
  }

  // const checkTabType = () => {
  //   if (data.type === 'template' || data.type === 'template_places' && data.elements[currentTabIndex].type === 'location' || data.type === 'template_images' ) setCurrentTab(data.elements[currentTabIndex]);
  //   else if (data.type === 'template_places' && data.elements[currentTabIndex].type === 'places') setCurrentTab(data.elements[currentTabIndex] ? data.elements[currentTabIndex].conversation : data.elements[currentTabIndex]);
  //   else console.log('type not found')
  // }

  const checkTabType = () => {
    const elements = sortElements(data.elements);
    if (data.type === 'template' || data.type === 'template_places' && elements[currentTabIndex].type === 'location' || data.type === 'template_images') setCurrentTab(elements[currentTabIndex]);
    else if (data.type === 'template_places' && elements[currentTabIndex].type === 'places') setCurrentTab(elements[currentTabIndex] ? elements[currentTabIndex].conversation : elements[currentTabIndex]);
    else console.log('type not found')
  }

  const getMediaURL = () => {
    console.log('currentTab', currentTab)
    if (currentTab && currentTab.image && currentTab.image !== "undefined") return currentTab.image.publicUrl;
    else if (currentTab && currentTab.place_image && currentTab.place_image !== "undefined") return currentTab.place_image;
    else return '/images/templates/frameBig.svg';
  }

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box mr={1}>
          <Card className={classes.card}>
            {data.elements.length > 0 ?
              <Box style={{ float: 'right' }} >
                <Icon icon="circle-left" size={25} color="#4050E1" onClick={onPrevTab} style={{ opacity: currentTabIndex === 0 ? '0.5' : '1' }} />
                <Icon icon="circle-right" size={25} color="#4050E1" onClick={onNextTab} style={{ opacity: currentTabIndex === data.elements.length - 1 ? '0.5' : '1' }} />
              </Box>
              : null}
            <CardMedia
              className={classes.media}
              image={getMediaURL()}
              title="image"
            />
            <Box>
              <Typography variant='inherit' style={{ fontWeight: 700 }} >
                {currentTab && currentTab.title_pt ? currentTab.title_pt : currentTab && currentTab.name ? currentTab.name : ''}
              </Typography>
            </Box>
            {/*currentTab && currentTab.subtitle_pt ?
            <Box>
              <Typography variant='inherit' >
                {currentTab.subtitle_pt}
              </Typography>
            </Box> : null */}
            <Box mt={2}>
              {
                data.elements[currentTabIndex] && data.elements[currentTabIndex].buttons ? data.elements[currentTabIndex].buttons.map((button) => (
                  <>
                    {
                      !button.disabled ?
                        <Box key={button.id}>
                          {
                            button.payload && button.payload.id === action ?
                              <Button variant="outlined" color="primary" className={classes.btnActive} onClick={() => onOptionSelect(button)}>{button.title_pt}</Button>
                              :
                              <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onOptionSelect(button)}>{button.title_pt}</Button>
                          }
                        </Box>
                        : null
                    }
                  </>
                )) : null
              }
            </Box>
          </Card>
        </Box>
        <ModalEditTemplateImages data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} onAddTab={onAddNewTab} onRemoveTab={onRemoveTab} onChangeSort={onChangeSort} show_english={props.show_english} />
      </Box>
      {action ?
        <Fragment>
          <Box textAlign="center" style={{ maxWidth: '380px' }}>
            {
              action.type === "web_url" ?
                <a href={action.url} target="_blank" style={{ textDecoration: 'none' }}>
                  <Box className={classes.actionBtn}>
                    <div style={{maxWdith: '70%', width: '100%'}}>{action.url}</div>
                    <LinkIcon fontSize="small" style={{marginLeft: '4px'}}/>
                  </Box>
                </a>
                :
                <Fragment>
                  <Box className={classes.actionBtn}>
                    {action.title_pt}
                  </Box>
                  <Box>
                    <ActionView actionId={action.payload ? action.payload.id : ''} depth={props.depth + 1} card={props.card} show_english={props.show_english} />
                  </Box>
                </Fragment>
            }
          </Box>
        </Fragment>
        : null}
    </>
  )
}

export default TemplateImages;

