import React, { useState } from 'react';

import {
  Typography,
  Button,
  Slide,
  Box
} from '@material-ui/core';

import { useStyles } from '../style';
import { useAuth } from 'core/services/auth';
import { useNavigation } from 'core/services/navigation';
import { useToasts } from 'react-toast-notifications';

const TrialOver = () => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const navigation = useNavigation();



  return (
    <Slide in={true} timeout={500}>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h1" className="line">Aviso</Typography>
          <Box mt={2}>
            <Typography>O seu período experimental terminou.</Typography>
          </Box>
        </Box> 
        <Box className={classes.wrapper} style={{ marginTop: 32, width: 375}}>
            <Typography>
                Neste momento todas as suas conversas encontram-se inativas e não tem permissões para editar.
                Para continuar a utilizar o Talk2me contacte, por favor, <a style={{ textDecoration: 'none', color: '#27D9D5' }} href="mailto:info@uzerconsulting.com">info@uzerconsulting.com</a>. 
                <br/>
                Obrigado.
            </Typography>
        </Box>
      </Box>
    </Slide>
  );
}

export default TrialOver;