import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles,  } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  slider: props => ({
    /* snap mandatory on horizontal axis  */
    scrollSnapType: 'x mandatory',
    overflowX: 'scroll',
    overflowY: 'hidden',
    display: 'flex',
    /* Enable Safari touch scrolling physics which is needed for scroll snap */
    WebkitOverflowScrolling: 'touch',
    '& > div': {
      /* snap align center  */
      scrollSnapAlign: 'center',
      // position: 'relative',
      flexShrink: 0,
      // width: '85%',
    },
    '&::-webkit-scrollbar': {
      height: '1em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      //backgroundColor: '#4050E1',
      backgroundColor: props.theme_color ? props.theme_color : '#4050E1',
      borderRadius: '20px',
      border: '6px solid transparent',
      backgroundClip: 'content-box',
    }
  }),
}));

function Slider(props) {
  const classes = useStyles(props);

  return ( 
    <div 
      className={classes.slider} 
      style={{
        overflowY: props.active ? 'hidden' : 'visible', //conditional for slider active > 1 item
        overflowX: props.active ? 'scroll' : 'visible'
      }}
    >
      {props.children}
    </div>
  );
}

export default Slider;