import React from 'react';

import Icon from "./../../backoffice/components/Icon";

import { Box, Container, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center', 
        backgroundImage: 'url(./../images/various/statsBackground.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        minHeight: 530,       
        marginBottom: 115, 
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'auto',
        },
    },
    content: {
        display: 'flex', 
        justifyContent: 'center',
        position: 'relative',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            flexDirection: 'column-reverse',
            textAlign: 'center',
        },
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        padding: '0px 250px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px', 
        },
    },
    info: {
        alignItems: 'flex-start',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        flexDirection: 'column',
        margin: '28px 50px 20px 100px',
        maxWidth: '280px',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center', 
            textAlign: 'center', 
            margin: '140px 0px 8px 0px',
        },
    },
    image: {
        background: theme.palette.primary.dark,
        backgroundImage: 'url(./../images/various/statDashboard.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: 5,
        minHeight: 304,
        width: 540,        
        [theme.breakpoints.down('sm')]: {
            minHeight: 168,
            width: 300,
        },
    },
    frame: {
        border: '2px solid #4050E1',
        borderRadius: 5,
        margin: '20px 0px 0px 20px',
        minHeight: 304,
        position: 'absolute',
        width: 540,
        zIndex: '-1',
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0px 0px 8px',
            minHeight: 168,
            width: 300,
        },
    },
/*     play: {
        left: 0,
        position: 'relative',
        top: 100,
        [theme.breakpoints.down('sm')]: {
            top: 38,
            left: 0,
            position: 'relative',
        },
    }, */
    rich_text: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '23px',
    },
    floating_circle: {
        backgroundImage: 'url(./../images/various/elipseShapeBlue.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 201,
        left: '9.31%',
        minWidth: 136,
        position: 'absolute',
        top: '85%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    bold: {
        fontWeight: 700,
    },
}));


export default function Stats() {
    const classes = useStyles();

  return (
    <Box className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box mt={2} style={{ zIndex: 1}} >
                    <Box className={classes.frame}></Box>
                    <Box className={classes.image}>
                {/* <Box className={classes.play}>
                        <Icon icon="play-btn-home" size={101} color="#fff" />
                    </Box> */}
                    </Box>
                </Box>
                <Box className={classes.info}>                  
                    <Box mb={4}>
                        <Typography variant="h1" className="line white" style={{ fontWeight: 700 }}>
                            Estatísticas
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        <Typography  className={classes.rich_text}>
                        A Plataforma Talk2me diponibiliza um Dashboard com estatísticas que permitem o acompanhamento do seu 
                        projeto em real time, nomeadamente com dados como o <span className={classes.bold}>número total de conversas</span>, 
                        <span className={classes.bold}> número de utilizadores únicos</span>, <span className={classes.bold}>o total de interações com perguntas e botões </span>
                         e de <span className={classes.bold}>aberturas de links externos</span>.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.floating_circle}></Box> 
        </Container>
    </Box>
  )
}