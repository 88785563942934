import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

export function useNavigation() {
	const match = useRouteMatch();
	const location = useLocation();
	const history = useHistory();

	const closeModal = () => history.push(location.pathname);
	const openLogin = () => history.push(`${match.url}?id=signin`);
	const openTrial = () => history.push(`${match.url}?id=trial`);
	const openVideo = () => history.push(`${match.url}?id=video`);
	const openRecover = () => history.push(`${match.url}?id=recover-password`);
	const navigateTo = url => window.open(url, '_blank');
	const goTo = path => path ? history.push(path) : null;
	const goToDashboard = () => history.push("/backoffice");
	const goHome = () => history.push("/");
	
	return {
		goTo,
		goHome,
		openVideo,
		openTrial,
		openLogin,
		closeModal,
		navigateTo,
		openRecover,
		goToDashboard
	};
};
