import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Icon from "./../../components/Icon";
import FaqsQuestion from './FaqsQuestion';
import Select from '@material-ui/core/Select';
import { useLocation } from 'react-router-dom';

import {
  Grid,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '22px',
  },
  content: {
    marginTop:'5px',
  },
  back: {
    fontWeight: 500,
    fontSize: '22px',
    marginLeft:'26px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
  item: {
    fontSize: '22px',
    marginLeft: '-16px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
  category: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '22px',
    margin:'0px 42px auto 42px',
  }
}));

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const classes = useStyles();

  const location = useLocation();

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} >
        {icon ? <ListItemIcon className={classes.back}>{icon}</ListItemIcon> : null}
        <ListItemText disableTypography className={classes.item} primary={primary} />
      </ListItem>
    </li>
  );
}

const FaqsSidebar = props => {
  const classes = useStyles();

  const onSelect = e => {
    props.onSelected(e.target.value);
  }

  return (
    <Grid container
      spacing={2}
      className={classes.root}
      >
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} className={classes.content}>
        <Box>
          <List>
            <ListItemLink disableTypography to={location.pathname === "/faqs" ? "/" : "/backoffice/"} primary={ props.page } icon={ <Icon icon="back" size={25} color="#4050E1" />} />
          </List>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
        <Box display='flex' flexDirection='column' alignItems='center' >
          <Hidden xsDown>
            <List style={{paddingTop: 0, width: '100%' }}>
              {props.categories && props.categories.length > 0 ? props.categories.map((category, index) => (
                <Box key={index} mb={3}>
                  <Typography className={classes.category}>{category.title}</Typography>
                  <Box>
                  { 
                    category.items.length > 0 ? category.items.map((faq, index) => (
                      <Box key={index} onClick={() => props.onSelected(faq.id)}>
                        <FaqsQuestion key={faq.id} question={faq.title}  />
                      </Box>
                      )) : null 
                  }
                  </Box>
                </Box>
                )) : null 
              }
            </List>
          </Hidden>
          <Hidden smUp>
            <Select
              native
              value={props.index}
              onChange={onSelect}
            >
              {
                props.categories && props.categories.length > 0 ? props.categories.map((category, index) => (
                  category.items.length > 0 ? category.items.map((faq, index) => (
                    <option value={faq.id} key={index}>{faq.title} </option>
                    )) : null 
                )) : null 
              }
            </Select>
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FaqsSidebar;
