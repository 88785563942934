import React, { Component } from 'react';
import Layout from '../layouts';

import TutorialView from '../views/TutorialView';

class Tutorial extends Component {

  render() {
      return (
        <Layout>
         <TutorialView />
        </Layout>
      );
  }
}

export default Tutorial;
