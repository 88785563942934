import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles,  } from '@material-ui/core';
import Button from './ui/Button';
import CardTitleImage from './ui/CardTitleImage';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundColor: 'rgb(242, 242, 242)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: 170,
  },
  buttons: props => ({
    filter: props.theme_color ? 'drop-shadow(0px 0px 10px rgba(138, 138, 138, 0.25))' : 'drop-shadow(0px 0px 10px rgba(102, 115, 231, 0.25))',
    listStyle: 'none',
    margin: '0 auto',
    padding: 0,
  }),
  button: props => ({ 
    background: '#F3F7FF',
    borderRadius: '20px',
    //color: '#4050E1',
    color: props.theme_color ? props.theme_color : '#4050E1',
    cursor: 'pointer',
    margin: '5px 0',
    padding: '5px 15px',
    textAlign: 'center',
    width: '100%',
  })
}));

function MessageButtons(props) {
  const classes = useStyles(props);
  const [message, setMessage] = useState(props.message);

  const sortByOrder = array => {
    const sorted = array.sort(function(a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  return (
    <Fragment>
      <li className={message.user ? 'user' : 'bot' }>
      { 
        props.lang === 'en' ? (message.text_en && !message.title_en ? message.text_en : message.title_en)
        : (message.text_pt && !message.title_pt ? message.text_pt : message.title_pt) }
    </li>
    <li 
      className={message.id ? 'bot' : 'user' } 
      style={{width: '100%', padding: 0, background: 'transparent' }} 
    >
      {
        message.buttons ? 
        <div className={classes.buttons}>
          {
            sortByOrder(message.buttons).map((button, i) => {
              return <div key={i}>
                <Button onClick={() => button.type !== 'web_url' ? props.onResponse(button) : null } button={button} lang={props.lang} conversation={props.conversation} theme_color={props.theme_color}>
                  { props.lang === 'en' && button.title_en ? button.title_en : button.title_pt }
                </Button>
              </div>
            })
          }
        </div> : null
      }
    </li>
    </Fragment>
  );
}

export default MessageButtons;