import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  Typography,
  Button,
  Slide,
  Input,
  Box
} from '@material-ui/core';

import { useStyles } from '../style';
import { useToasts } from 'react-toast-notifications';

const RECOVER_PASSWORD_MUTATION = gql`
	mutation recoverPassword($email: String!) {
		startPasswordRecovery(email: $email) { id }
	}
`;

const RecoverView = props => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const { addToast } = useToasts();

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const [mutate] = useMutation(RECOVER_PASSWORD_MUTATION);

  const validate = () => {
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(email)) addToast('Endereço de e-mail inválido.', { appearance: 'error', autoDismiss: true });
      else handleOnSubmit()
  }

  const handleOnSubmit = async data => {
    try {
      const { data: { startPasswordRecovery } } = await mutate({ variables: { email } });

      if (startPasswordRecovery) setSuccess(true);
      else addToast('E-mail não encontrado.', { appearance: 'error', autoDismiss: true });

    } catch (error) {
      console.log(error);
      addToast('Ocorreu um erro. Por favor, tente novamente mais tarde.', { appearance: 'error', autoDismiss: true });
    }
  };

  return (

    <Slide in={true} timeout={500}>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h1" className="line">Password</Typography>
          <Box mt={2}>
            <Typography>{!success ? "Para recuperar a sua password, introduza o email com o qual se registou." : "Obrigado!" }</Typography>
          </Box>
        </Box>
        {!success ?
          <>
            <Box className={classes.wrapper}>
              <Input
                className={classes.input}
                placeholder="Email do utilizador"
                type="email"
                id="email"
                value={email}
                onChange={handleChange}
              />
            </Box>
            <Button variant="outlined" className="landing green" style={{ marginTop: 20 }} onClick={validate}>
              Recuperar password
            </Button>
          </>
          :
          <Box className={classes.success}>
            <Typography>Siga os passos enviados para o seu e-mail para recuperar a password.</Typography>
          </Box>
        }
      </Box>
    </Slide>
  );
}

export default RecoverView;