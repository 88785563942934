import { Box, Button, CardMedia, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { ServicesContext } from '../../../../../context/services';
import { useAuth } from 'core/services/auth';
import { useToasts } from 'react-toast-notifications';
import Icon from '../../../../components/Icon';
import ModalRemove from '../ModalRemove';
import SimpleButton from '../SimpleButton';
import AddButton from '../SimpleButton/AddButton';
import { fade } from '@material-ui/core/styles';
import SearchLocationInput from '../../../../components/SearchLocationInput';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
    formFields: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '6px',
        color: theme.palette.primary.dark,
    },
    input: {
        width: '100%',
        background: '#fff',
        border: '1px solid #DBDDF1',
        boxSizing: 'border-box',
        borderRadius: '6px',
        fontWeight: 400,
        textAlign: 'left',
        padding: '10px 12px',
    },
    btn: {
        display: 'inline-block',
        fontSize: 12,
        height: 34,
        borderRadius: '34px',
        textTransform: 'none',
        marginRight: 8,
        padding: '8px 16px',
    },
    searchItem: {
        display: 'flex',
        width: '320px',
        height: '42px',
        borderRadius: '6px',
        justifyContent: 'space-between',
        backgroundColor: fade('#FFFFFF', 1),
        '&:hover': {
            backgroundColor: fade('#FFFFFF', 0.75),
        },
        marginLeft: 0,
    },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        color: theme.palette.primary.dark,
    },
    searchInput: {
        border: 'none',
        width: '250px',
        backgroundColor: 'transparent',
        padding: '12px',
        outline: 'none',
    },
    formUpload: {
        display: 'none',
    },
    btnUpload: {
        width: '97px',
        height: '34px',
        borderRadius: '34px',
        marginTop: '16px',
        textTransform: 'none',
    },
}));

const PlaceTab = props => {
    const classes = useStyles();

    const { services } = useContext(ServicesContext);
    const { addToast } = useToasts();
    const { user } = useAuth();
    const { id } = useParams();

    const [allConversations, setAllConversations] = useState([]);

    const [place, setPlace] = useState(props.data);
    const [googleImage, setGoogleImage] = useState(null);
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [placeName, setPlaceName] = useState('');

    const [conversation, setConversation] = useState(null);
    const [location, setLocation] = useState(null);

    /*useEffect(() => {
        setPlace(props.data);
        if(props.data.image && props.data.image.publicUrl) setImage(props.data.image.publicUrl);
    }, [props.data]);
    */

    useEffect(() => {
        if (place.conversation) setConversation(place.conversation);
        if (place.conversation && place.conversation.image) {
            setImage(place.conversation.image.publicUrl);
        } else if (place.conversation && place.conversation.place_image) {
            setImage(place.conversation.place_image);
        }

        if (place && place.image) {
            setImage(place.image.publicUrl);
        } else if (place && place.place_image) {
            setImage(place.place_image);
        }

        if (place.type === 'location' && place.place_address) {
            setPlaceName(place.place_address);
            setLocation({
                ...location,
                ['place_address']: place.place_address,
            });
        }
    }, []);

    useEffect(() => {
        if (props.saveAll) validate();
    }, [props.saveAll]);

    useEffect(() => {
        props.onUpdatedPlace(place)
    }, [place]);

    useEffect(function () {
        getConversations();
    }, []);

    const getConversations = () => {
        services.conversationsApi.getAllByUser(user.id).then(({ allConversations }) => {
            setAllConversations(allConversations.filter(function (conversation) { return conversation.id !== id }))
        });
    }

    const handleChange = e => {
        if (e.target.id == "conversation") {
            const conversationSelected = allConversations.filter(function (conversation) { return conversation.id === e.target.value })[0];
            //console.log('conv selected', conversationSelected);
            setConversation(conversationSelected);
            setPlace({ ...place, title_pt: conversationSelected.name, title_en: conversationSelected.name, conversation: conversationSelected.id })

            if (conversationSelected && conversationSelected.image) setImage(conversationSelected.image.publicUrl);
            else if (conversationSelected && conversationSelected.place_image) setImage(conversationSelected.place_image);
        }
        else setPlace({ ...place, [e.target.id]: e.target.value });
    };

    const parseButtons = buttons => {
        let arr = '';
        for (var i = 0; i < buttons.length; i++) {
            arr += `{ id: "${buttons[i].id}" }`;
        }
        return arr;
    }

    const parsedLocation = () => {
        let tmpLocation = ``;
        location.place_name ? tmpLocation += `place_name: "${location.place_name}" ` : ``;
        location.place_address ? tmpLocation += `place_address: "${location.place_address}" ` : ``;
        location.place_image ? tmpLocation += `place_image: "${location.place_image}" ` : ``;
        location.place_lat ? tmpLocation += `place_lat: ${location.place_lat} ` : ``;
        location.place_lng ? tmpLocation += `place_lng: ${location.place_lng} ` : ``;
        return tmpLocation;
    }

    const parsedConversation = () => {
        return `conversation: { connect: { id : "${conversation.id}" } },`;
    }

    const validateButtons = () => {
        let isValid = false;
        for (let i = 0; i < place.buttons.length; i++) {
            const button = place.buttons[i];
            if (!button.disabled) isValid = true;
        }
        return isValid;
    }

    const validate = () => {
        props.onUpdatedPlace('validate');
        if (place.type === 'places' && !place.conversation) addToast('Tem de escolher uma conversa ou seleccionar um local.', { appearance: 'error', autoDismiss: true });
        else if (place.type === 'location' && !place.title_pt || place.type === 'location' && place.title_pt.length < 2 || place.type === 'location' && place.title_pt.toString().toLowerCase() === "nova tab") addToast('Título em português.', { appearance: 'error', autoDismiss: true });
        else if (place.type === 'location' && props.show_english && !place.title_en || place.type === 'location' && props.show_english && place.title_en.length < 2) addToast('Título em inglês.', { appearance: 'error', autoDismiss: true });
        else if (place.type === 'location' && !location) addToast('Nova localização.', { appearance: 'error', autoDismiss: true });
        else if (place.buttons.length <= 0) addToast('Adicione pelo menos um botão', { appearance: 'error', autoDismiss: true });
        else if (!validateButtons()) addToast('Tem de ter pelo menos um botão activo', { appearance: 'error', autoDismiss: true });
        else handleSave();
    }

    const handleSave = () => {

        const buttonsIDs = parseButtons(place.buttons);
        const serviceData = `{ 
            type: ${place.type},
            title_pt: "${place.title_pt.replace(/"/g, '\\"')}", 
            title_en: "${place.title_en ? place.title_en.replace(/"/g, '\\"') : ''}", 
            ${place.type === 'location' && location ? parsedLocation() : ''} 
            ${place.type === 'places' ? parsedConversation() : ''}
            buttons: { disconnectAll: true, connect: [ ${buttonsIDs} ] } 
            ${file ? ', image: $image' : ''} 
        }`;
        if (place.id && !file) {
            services.elementsApi.update(place.id, serviceData).then(({ updateElement }) => {
                // console.log('updatePlace', updateElement)
                setPlace(updateElement);
                props.onSaved(updateElement);
            });
        } else {
            services.elementsApi.updateAndUpload(place.id, serviceData, file.value).then(({ updateElement }) => {
                // console.log('updatePlace', updateElement)
                setPlace(updateElement);
                props.onSaved(updateElement);
            });
        }
    }

    const handleRemove = () => {
        // console.log('handleRemove', place.id)
        props.onRemoveTab(place.id);
    }

    const addToPlace = button => {
        let newButtons = place.buttons ? place.buttons : [];
        newButtons.push(button);
        setPlace({
            ...place,
            ['buttons']: newButtons
        });
    }

    const onAddNewButton = e => {
        var data = { type: 'postback', title_pt: '', title_en: '' };
        services.buttonsApi.create(data).then(({ createButton }) => {
            // console.log('onAddNewButton', createButton)
            if (createButton) addToPlace(createButton);
        });
    }

    const updateButtonInArray = (buttons, newButton) => {
        return buttons.map(button => {
            if (button.id !== newButton.id) {
                return button;
            }
            return newButton;
        });
    }

    const onUpdatedButton = button => {
        const newButtons = updateButtonInArray(place.buttons, button);
        setPlace({
            ...place,
            ['buttons']: newButtons
        });

        //props.onUpdatedPlace(place);
        //props.onChange({type: 'options', data: newOptions });
    };

    const onRemoveButton = id => {
        const newButtons = place.buttons.filter(function (button) { return button.id !== id });
        setPlace({
            ...place,
            ['buttons']: newButtons
        });
        //props.onChange({type: 'options', data: newOptions });
    }

    const onFileChanged = (event) => {
        const { files } = event.target;
        const file = files[0];

        //onChange({ name: 'file', value: file });
        setFile({ name: 'file', value: file });

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            setImage(event.target.result);
        });
        reader.readAsDataURL(file);
    }

    const hiddenFileInput = useRef(null);

    const onFileUpload = event => {
        hiddenFileInput.current.click();
    }

    const onLocationChanged = result => {
        /*
        let photo;
    
        if (result.photos) {
          photo = result.photos[0].getUrl();
          setGoogleImage(result.photos[0].getUrl());
        }
        */
        setLocation({
            ...location,
            ['place_name']: result.name,
            ['place_address']: result.formatted_address,
            //place_image: photo ? photo : null,
            ['place_lat']: result.geometry.location.lat(),
            ['place_lng']: result.geometry.location.lng()
        });
    }
    const onDragEnd = result => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const newButtons = Array.from(place.buttons);
        const [removed] = newButtons.splice(result.source.index, 1);
        newButtons.splice(result.destination.index, 0, removed);
        for (let i = 0; i < newButtons.length; i++) {
            const tab = newButtons[i];
            tab.order = i;
            updateIndex(tab);
        }
        let newPlace = place;
        newPlace.buttons = newButtons;
        setPlace(newPlace);
    };

    const updateIndex = (button) => {
        const serviceData = `{ 
            order: ${button.order}
        }`;

        services.buttonsApi.update(button.id, serviceData).then(({ updateButton }) => {
            console.log('updateButton', updateButton)
        });
    }

    const sortByOrder = array => {
        const sorted = array.sort(function(a, b) {
          return parseFloat(a.order) - parseFloat(b.order);
        });
        return sorted;
    }

    const show_english = props.show_english;

    return (
        <Box>
            <Box>
                <Typography className={classes.formFields} align="justify">Tipologia</Typography>
                <select
                    name="type"
                    id="type"
                    className={classes.input}
                    value={place.type ? place.type : ''}
                    onChange={handleChange}
                >
                    <option value="places">As minhas conversas</option>
                    <option value="location">Nova localização</option>
                </select>
            </Box>
            <Box mt={2} style={{ display: place.type === 'places' ? 'none' : 'block' }}>
                <Box mt={0}>
                    <Typography className={classes.formFields} align="justify"> Título em português </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_pt"
                        value={place.title_pt}
                        onChange={handleChange}
                        placeholder="Título (em português)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                    <Typography className={classes.formFields} align="justify"> Título em inglês </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_en"
                        value={place.title_en}
                        onChange={handleChange}
                        placeholder="Título (em inglês)"
                        maxLength="80"
                    />
                </Box>
                <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2}>
                    <Typography className={classes.formFields} align="justify">Localização</Typography>
                    <Box>
                    </Box>
                    <div className={classes.input} style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                        <SearchLocationInput onChange={onLocationChanged} location={placeName} classes={{ input: classes.searchInput }} />
                        <div className={classes.searchIcon}> <Icon icon="search" size={25} color="#4050E1" /> </div>
                    </div>
                </Box>
            </Box>
            <Box mt={2} style={{ display: place.type === 'location' ? 'none' : 'block' }}>
                <Typography className={classes.formFields} align="justify">As suas outras Conversas</Typography>
                <select
                    name="conversation"
                    id="conversation"
                    className={classes.input}
                    value={place.conversation ? place.conversation.id : 0}
                    onChange={handleChange}
                >
                    {allConversations.length > 0 ? <option>Escolher conversa</option> : <option>Sem conversas</option>}
                    {allConversations.map((conversation) => (<option key={conversation.id} value={conversation.id}>{conversation.name}</option>))}
                </select>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2} mb={3}>
                <Typography className={classes.formFields} align="justify"> Foto </Typography>
                <input
                    accept="image/x-png,image/gif,image/jpeg"
                    className={classes.formUpload}
                    name="contained-button-file"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onFileChanged}
                />
                <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                    {/* <CardMedia
                        alt="image"
                        component="img"
                        style={{ borderRadius: '6px' }}
                        image={image ? image : (googleImage ? googleImage : '/images/templates/frame.svg')}
                    /> */}
                    <CardMedia
                        style={{ paddingTop: '56.25%', borderRadius: '6px' }}
                        image={image ? image : (googleImage ? googleImage : '/images/templates/frame.svg')}
                        title="image"
                    />
                    {place.type === 'location' ? <Button
                        variant="contained"
                        className={classes.btnUpload}
                        color="primary"
                        type="submit"
                        onClick={onFileUpload}
                    >
                        Upload Foto
                    </Button> : null}
                </label>
            </Box>
            {/* <Box>
                {
                    place.buttons ? place.buttons.map((button, index) => (
                        <Box key={button.id} mb={2}>
                            <Typography className={classes.formFields} > Botão {index + 1} </Typography>
                            <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={props.show_english} />
                        </Box>
                    ))
                        : null
                }
            </Box> */}
            <Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="buttons">
                {props => (
                    <div 
                        ref={props.innerRef}
                        {...props.droppableProps}
                    >
                    {
                        place.buttons ? sortByOrder(place.buttons).map((button, index) => (
                            <Draggable
                                key={button.id}
                                draggableId={`id-${button.id}`} // must be a string
                                index={index}
                                disableInteractiveElementBlocking={true}
                            >
                            {props => (
                                <Box 
                                    ref={props.innerRef}
                                    {...props.draggableProps}
                                    {...props.dragHandleProps}
                                    key={button.id} mb={2}
                                >
                                    <Typography className={classes.formFields} > Botão {index + 1} </Typography>
                                    <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={show_english} />
                                </Box>
                            )}
                            </Draggable>
                        ))
                        : null
                    }
                    {props.placeholder}
                </div>
                )}
              </Droppable>
            </DragDropContext>
            </Box>
            <AddButton buttonsLength={place.buttons.length} limit={3} onAddNewButton={onAddNewButton} />
            <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }} mt={2} >
                <Button variant="contained" color="primary" className={classes.btn} onClick={props.onSave}>
                    Gravar
                </Button>
                <ModalRemove type="button" onConfirmRemove={handleRemove} label="Remover tab" />
            </Box>
        </Box>
    );
};

export default PlaceTab;
