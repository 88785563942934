import React, { Fragment } from 'react';

import Router from './router';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'

import services from './services';
import { ServicesContext } from './context/services';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const App = () => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ServicesContext.Provider value={{ services }}>
          <ToastProvider placement="bottom-center">
            <Router />
          </ToastProvider>
          </ServicesContext.Provider>
      </ThemeProvider>
    </Fragment>
  )
};

export default App;
