import React, { useContext, useState } from "react";

import { useToasts } from 'react-toast-notifications';
import ModalEditSimpleText from './ModalEditSimpleText'
import { ServicesContext } from '../../../../../context/services';

import {
  Card,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 380,
    minHeight: 67,
  },
  text: {
    padding: '22px 14px 22px 14px',
  },
}));

const SimpleText = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  const handlerSave = () => {
    let serviceData = `{ text_pt: "${data.text_pt.replace(/\n/, "\\n").replace(/"/g, '\\"')}", text_en: "${data.text_en.replace(/\n/, "\\n").replace(/"/g, '\\"')}"}`;
    // console.log('serviceData', serviceData);
    if(!data.id){
      services.responsesApi.create(serviceData).then(({ createResponse }) => {
        // console.log('createResponse', createResponse)
        setData( {...data, id: createResponse.id, text_pt: createResponse.text_pt, text_en: createResponse.text_en });
      });
    }else{
      services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
        // console.log('updateResponse', updateResponse)
        setData( {...data, id: updateResponse.id, text_pt: updateResponse.text_pt, text_en: updateResponse.text_en });
        addToast('Alterações gravadas com sucesso.', { appearance: 'success', autoDismiss: true });
      });
    }
  }

  const handlerRemove = () => {
    props.onRemoveResponse(data.id);
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    });
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center' key={data ? data.index : 0 }>
      <Box mr={1}>
        <Card className={classes.card}>
          <Box className={classes.text} >
            <Typography style={{ whiteSpace: 'pre-line' }} variant='inherit'>
              { data ? data.text_pt : '' }
            </Typography>
          </Box>
        </Card>
      </Box>
      <ModalEditSimpleText data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} open={data && data.text_pt === '' ? true : false} show_english={props.show_english}/>
    </Box>
  )
}

export default SimpleText;

