import React, { useContext, useEffect, useState } from "react";

import { useToasts } from 'react-toast-notifications';
import ActionView from '../../ActionView';
import ModalEditQuestionButtons from './ModalEditQuestionButtons'
import { ServicesContext } from '../../../../../context/services';
import LinkIcon from '@material-ui/icons/Link';

import {
  Card,
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 380,
    //height: 227,
  },
  text: {
    padding: '18px 18px 10px 18px',
  },
  btn: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    borderColor: 'transparent',
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    textTransform: 'none',
    minHeight: '34px',
    width: '100%'
  },
  btnActive: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    textTransform: 'none',
    height: '34px',
    width: '100%'
  },
  actionBtn: {
    backgroundColor: '#F3F7FF',
    border: '1px solid #4050E1',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '18px',
    margin: '15px 0',
    padding: '8px 12px',
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      top: '-10px',
      height: '5px',
    },
    '&::after': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      bottom: '-5px',
      height: '5px',
    },
  }
}));

const QuestionButtons = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const [action, setAction] = useState(null);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  useEffect(() => {
    if(action && data.buttons.length){
        const updateAction = data.buttons.find(button => button.id === action.id);
        setAction(updateAction);
    }
  }, [data]);

  const onOptionSelect = id => {
    setAction(id);
  }

  const parseButtons = buttons => {
    let arr = '';
    for (var i = 0; i < buttons.length; i++) {
      arr += `{ id: "${buttons[i].id}" }`;
    }
    return arr;
  }

  const handlerSave = () => {
    const buttonsIDs = parseButtons(data.buttons);
    let serviceData = `{ text_pt: "${data.text_pt.replace(/\n/, "\\n").replace(/"/g, '\\"')}", text_en: "${data.text_en.replace(/\n/, "\\n").replace(/"/g, '\\"')}", buttons: { disconnectAll: true, connect: [ ${buttonsIDs} ] }}`;

    if (!data.id) {
      services.responsesApi.create(serviceData).then(({ createResponse }) => {
        // console.log('createResponse', createResponse)
        setData(createResponse);
      });
    } else {
      services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
        // console.log('updateResponse', updateResponse)
        setData(updateResponse);
        //setData( {...data, id: updateResponse.id, text_pt: updateResponse.text_pt, text_en: updateResponse.text_en });
      });
    }
  }

  const handlerRemove = () => {
    props.onRemoveResponse(data.id);
  }

  const handleChange = e => {

    if (e.type === 'buttons' || e.type === 'buttons') {
      // console.log('e.data', e.data)
      setData({
        ...data,
        [e.type]: e.data
      });
    } else {
      setData({
        ...data,
        [e.target.id]: e.target.value
      });
    }

  };

  const updateObjectInArray = (array, newItem) => {
    return array.map(item => {
      if (item.id !== newItem.id) {
        return item;
      }
      return newItem;
    });
  }

  const sortByOrder = array => {
    const sorted = array.sort(function(a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box mr={1}>
          <Card className={classes.card}>
            <Box className={classes.text} >
              <Typography style={{ whiteSpace: 'pre-line' }} variant='inherit'>
                {data ? data.text_pt : ''}
              </Typography>
            </Box>
            <Box style={{ padding: '0px 16px 10px 16px' }} >
              {
                data && data.buttons ? sortByOrder(data.buttons).map((button) => (
                  <Box key={button.id}>
                    {
                      button.type === "web_url" ?
                        <a href={button.url} target="_blank" style={{ textDecoration: 'none' }}>
                          <Button variant="outlined" color="primary" className={classes.btn}>
                            <div style={{maxWdith: '70%', width: '100%'}}>{button.title_pt}</div>
                            <LinkIcon fontSize="small" style={{position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)'}}/>
                          </Button>
                        </a>
                        :
                        button.payload && button.payload.id === action ?
                          <Button variant="outlined" color="primary" className={classes.btnActive} onClick={() => onOptionSelect(button.payload.id, button.title_pt)}>{button.title_pt}</Button>
                          :
                          <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onOptionSelect(button.payload.id, button.title_pt)}>{button.title_pt}</Button>
                    }
                  </Box>
                )) : null
              }
            </Box>
          </Card>
        </Box>
        <ModalEditQuestionButtons data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} open={data && data.text_pt === '' ? true : false} show_english={props.show_english} />
      </Box>
      {action ?
        <Fragment>
          <Box textAlign="center" style={{ maxWidth: '380px' }}>
            <Box className={classes.actionBtn}>
              {
                data.buttons.map(button => {
                  if (button.payload.id === action) {
                    return button.title_pt;
                  }
                })
              }
            </Box>
          </Box>
          <Box>
            <ActionView actionId={action} depth={props.depth + 1} card={props.card} show_english={props.show_english} />
          </Box>
        </Fragment>
        : null}
    </>
  )
}

export default QuestionButtons;