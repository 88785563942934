import React, { useState, useEffect, useContext } from 'react';

import Icon from "../../components/Icon";

import Graph from './Graph';

import {
  makeStyles,
  IconButton,
  Modal,
  Backdrop,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';

import {
  ResponsiveContainer, PieChart, Pie, Tooltip
} from 'recharts';

import Slide from '@material-ui/core/Slide';

import { ServicesContext } from '../../../context/services';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      alignItems: 'flex-start',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '90%',
    maxWidth: 600,
    background: '#FFFFFF',
    borderRadius: '6px',
    padding: 32,
    outline: 'none',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  box: {
    marginTop: 35,
    width: 320,
    height: 67,
    background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 47.92%, #F3F3F3 100%)',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
  },
  item: {
    fontWeight: 400,
    paddingTop: 25,
    textAlign: 'left',
    padding: '23px 54px 23px 14px',
  },
  media: {
    backgroundColor: '#F3F7FF',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 20px',
    width: '100%',
  },
  graphic: {
    width: '100%',
    maxWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }
}));

const ModalCharts = ({ id, name }) => {
  const classes = useStyles();
  const { services } = useContext(ServicesContext);
  const [open, setOpen] = React.useState(false);

  const [stats, setStats] = useState([
    { title: 'Inicios de Conversa', value: '--', tooltip: 'Número total de conversas iniciadas através da leitura de QR Code ou link directo.' },
    { title: 'Utilizadores', value: '--', tooltip: 'Total de utilizadores de Messenger únicos que acederam ás tuas conversas.' },
    { title: 'Interações', value: '--', tooltip: 'Total de vezes que os utilizadores interagiram com botões (e.g. Quem és tu?) ou opções (e.g Sim / Não).' },
    { title: 'Links Externos', value: '--', tooltip: 'Total de vezes que os utilizadores abriram links externos em botões das tuas conversas.' }
  ]);

  useEffect(function () {
    if (open && id) getStats(id);
  }, [open]);

  const getStats = (id) => {
    // services.interactionsApi.getByConversation(id).then(({ allInteractions }) => {
    //   const stats = checkInteractions(allInteractions);
    //   if (onStats) onStats(stats);
    // });
  }

  const checkInteractions = (interactions) => {
    let result = { start: 0, postback: 0, link: 0, users: 0 };
    let users = {}, interaction;

    for (var i = 0; i < interactions.length; i++) {
      interaction = interactions[i];
      // if (!users[interaction.user.id]) {
      //   users[interaction.user.id] = true;
      //   result.users++;
      // }
      result[interaction.type]++;
    }

    return result;
  }

  const onStats = (stats) => {
    setStats([
      { title: 'Inicios de Conversa', value: stats.start || '--', tooltip: 'Número total de conversas iniciadas através da leitura de QR Code ou link directo.' },
      { title: 'Utilizadores', value: stats.users || '--', tooltip: 'Total de utilizadores de Messenger únicos que acederam ás tuas conversas.' },
      { title: 'Interações', value: stats.postback || '--', tooltip: 'Total de vezes que os utilizadores interagiram com botões (e.g. Quem és tu?) ou opções (e.g Sim / Não).' },
      { title: 'Links Externos', value: stats.link || '--', tooltip: 'Total de vezes que os utilizadores abriram links externos em botões das tuas conversas.' }
    ]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data01 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
    { name: 'Group E', value: 278 },
    { name: 'Group F', value: 189 },
  ];

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Icon icon="charts" size={25} color="#4050E1" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} timeout={500} >
          <Box className={classes.paper}>
            <Typography
              className={classes.title}
              variant="h2"
            >
              Estatísticas - {name}
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="h4"
            >
              Lorem ipsum dolor sit amet consectetur adipiscing elit
            </Typography>
            <Box mt={4} style={{ width: '100%' }}>
              <Grid container spacing={3}>
                {
                  stats && stats.length > 0 ? stats.map((graph, index) => (
                    <Grid key={'stats-' + index} item className={classes.graphic} >
                      <Graph title={graph.title} value={graph.value} tooltip={graph.tooltip} />
                    </Grid>
                  )) : null
                }
                {/* <PieChart width={200} height={200}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data01}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                  />
                  <Tooltip />
                </PieChart> */}
              </Grid>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}

export default ModalCharts;