import React from 'react';

import { Box, Button, Container, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        marginBottom: 100,
        minHeight: 530,       
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',    
        textAlign: 'center',
    },
    title: {
        color: theme.palette.primary.dark,
        padding: '0px 250px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px', 
        },
    },
    card: {
        alignItems: 'center',
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        height: 402,
        overflow: 'hidden',
        margin: '15px 0',
        maxWidth: 636,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', 
            height: 500,
            textAlign: 'center',
            width: 300,
        },
    },
    image: {
        '& img': {
            display: 'block',
        },
        [theme.breakpoints.down('sm')]: {
            '& img': {
                width: 311,
            },
            height: 147,
            overflow: 'hidden'
        },
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '0px 40px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center', 
            marginTop: 32,
            padding: '0px',
            },
    },
    title_card: {
        fontWeight: 700,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 26,
        },
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '23px',
        width:'182px',
        [theme.breakpoints.down('sm')]: {
            width:'220px',
        },
    },
    btn: {
        '& button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.landing.blue': {
            minWidth: 142, 
        },
    },
    bold: {
        fontWeight: 700,
    }, 
}));


export default function Projects() {
    const classes = useStyles();

  return (
    <Box className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box className={classes.title} mb={4}>
                        <Typography variant="h1" className="line" style={{ fontWeight: 700 }}>
                            Projetos
                        </Typography>
                </Box> 
                <Box className={classes.card}>
                    <Box className={classes.image}>
                        <img src="./../images/various/projectsImage.jpg" alt="Projects"   />
                    </Box>
                    <Box className={classes.text}>
                        <Box>
                            <Typography variant="h2" className={classes.title_card} >
                                WOOL
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography className={classes.rich_text}>
                                Em parceria com o <span className={classes.bold}>NEST</span> e com projeto <span className={classes.bold}>WOOL– Covilhã Arte Urbana</span>, o <span className={classes.bold}>Talk2me</span> permite aos visitantes descobrir informação relevante sobre cada um dos 32 murais existentes.
                            </Typography>
                        </Box>
                        <Box mb={4} className={classes.btn}>
                            <Button variant="outlined" className="landing blue" href="/caso-wool"  >
                                Saber mais
                            </Button>
                        </Box> 
                    </Box>
                </Box>
            </Box>
        </Container>
    </Box>
  )
}