import React from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: '40px 0px;',
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '0 auto',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            width: '100%',
            '& div': {
                margin: '20px',
            },
        },
    },
    text: {
        color: '#555555',
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 'bold',
    },
}));


export default function Footer() {
    const classes = useStyles();

  return (
    <footer className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box>
                    <a href="https://www.nestportugal.pt/" target="_blank">
                        <img src="./../images/logos/nest.svg" alt="Nest"  />
                    </a>
                </Box>
                <Box>
                    <a href="https://www.uzerconsulting.com" target="_blank">
                        <img src="./../images/logos/uzer.svg" alt="Uzer" />
                    </a>
                </Box>
                <Box>
                    <a href="https://www.akt.pt/" target="_blank">
                        <img src="./../images/logos/akt.svg" alt="AKT" />
                    </a>
                </Box>
{/*                 <Box>
                    <Typography className={classes.text} variant="h2">
                        Contacts
                    </Typography>
                </Box>     */}
     
            </Box>
        </Container>
    </footer>
  )
}