import React from 'react';

import { Box, Button, Container, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundImage: 'url(./../images/various/howBackground.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        minHeight: 530,
        marginBottom: 150,  
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'auto',
        }, 
    },
    floating_circle: {
        backgroundImage: 'url(./../images/various/elipseShapeGreen.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 189,
        left: '5.35%',
        minWidth: 133,
        position: 'absolute',
        top: '-24.62%', 
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }, 
        
    },
    content: {
        alignItems: 'flex-start',
        display: 'flex',          
        justifyContent: 'center',
        paddingTop: 100,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            flexDirection: 'column', 
            paddingTop: 42,
        }, 
    },
    title: {
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
            background: '#27D9D5',
            bottom: '6px',
            content: '""',
            height: 13,
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1
        }
    },
    info: {
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        display: 'inline-flex',
        flexDirection: 'column',
        margin: ' 0px 40px 40px 40px',
        maxWidth: '280px',
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center', 
            margin: '40px 0px 0px 0px',
            textAlign: 'center',
        },      
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '23px',
        width: '239px'
    },
    box: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',

        },   
    },
    card_01: {
        alignItems: 'flex-start',
        backgroundColor: theme.palette.common.white,
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        display: 'inline-flex',
        color:  theme.palette.primary.dark,
        height: 340,
        maxWidth: 200,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        margin: '64px 8px 0px 8px',
        [theme.breakpoints.down('sm')]: {
            height: 300,
            maxWidth: 264,
            textAlign: 'center',
            margin: '20px 55px 0px 0px',           
        },   
    },
    card_02: {
        alignItems: 'flex-start',
        backgroundColor: theme.palette.common.white,
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        display: 'inline-flex',
        color:  theme.palette.primary.dark,
        height: 340,
        maxWidth: 200,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        margin: '0px 8px 0px 8px',
        [theme.breakpoints.down('sm')]: {
            height: 300,
            maxWidth: 264,
            textAlign: 'center',
            margin: '20px 0px 0px 0px',
        },   
    },
    card_03: {
        alignItems: 'flex-start',
        backgroundColor: theme.palette.common.white,
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        display: 'inline-flex',
        color:  theme.palette.primary.dark,
        height: 340,
        maxWidth: 200,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        margin: '64px 8px 0px 8px',
        [theme.breakpoints.down('sm')]: {
            height: 300,
            maxWidth: 264,
            textAlign: 'center',
            margin: '20px 55px 0px 0px',
        },   
    },
    
    outline: {
        color: theme.palette.secondary.main,
        fontWeight: '700',
        fontSize: '260px',
        lineHeight: '22px',
        opacity: 0.10,
        position: 'absolute',
        right: '11%',
        top: '50%',
        WebkitTextFillColor: 'transparent', 
        WebkitTextStrokeWidth: '2px',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            right: '20%',
            top: '56%',

        },  
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '40px 18px 0px 18px',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            padding: '40px 28px 0px 28px',

        },  
    },
    rich_text_card: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '23px',
    },

}));


export default function How() {
    const classes = useStyles();

  return (
    <Box className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.floating_circle}></Box> 
            <Box className={classes.content}>
                <Box className={classes.info}>                  
                    <Box mb={4} >
                        <Typography className={classes.title}>
                            Como <span className={classes.line_text}>funciona?</span>
                        </Typography>
                    </Box> 
                    <Box mb={4}>
                        <Typography  className={classes.rich_text}>
                            O Talk2me é tão simples, que qualquer pessoa pode criar as suas conversas, em minutos. Se precisar de ajuda… converse com o nosso help desk. 
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        <Button variant="outlined" className="landing white" >
                            Criar Conversa
                        </Button>
                    </Box> 
                </Box>
                <Box className={classes.box}  >
                    <Box className={classes.card_01} >
                        <Box >
                            <Typography className={classes.outline} >
                                1
                            </Typography>
                        </Box>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Typography variant="h2" color='secondary' style={{fontWeight: 700}}>
                                        1
                                    </Typography>
                                </Box>
                                <Box >
                                    <Typography variant="h2" style={{fontWeight: 700}} >
                                        Conteúdo
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                    Escolha os “Objectos” e as histórias que eles têm para contar: quem os fez, o que simbolizam, curiosidades, … 
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.card_02}>
                        <Box >
                            <Typography className={classes.outline} >
                               2
                            </Typography>
                        </Box>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1} >
                                    <Typography variant="h2" color='secondary' style={{fontWeight: 700}}>
                                       2
                                    </Typography>
                                </Box>
                                <Box >
                                    <Typography variant="h2" style={{fontWeight: 700}}>
                                        Conversa
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                    Escreva o fluxo da conversa na plataforma Talk2me.​ É só preencher os campos assinalados. ​No final, é gerado um QRCode, válido pelo período comercial contratado.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.card_03}>
                        <Box >
                            <Typography className={classes.outline} >
                               3
                            </Typography>
                        </Box>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Typography variant="h2" color='secondary' style={{fontWeight: 700}}>
                                        3
                                    </Typography>
                                </Box>
                                <Box >
                                    <Typography variant="h2" style={{fontWeight: 700}}>
                                        Pronto a usar
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                    Coloque o QRCode junto do “Objecto” (autocolante, placa…) e convide as pessoas a conversarem com ele. 
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box> 
            </Box>
        </Container>
    </Box>
  )
}