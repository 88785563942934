import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, } from '@material-ui/core'
import Option from './ui/Option';
import Slider from './ui/Slider';

const useStyles = makeStyles((theme) => ({

}));

function MessageLoop(props) {
  const classes = useStyles();
  const [message, setMessage] = useState(props.message);

  return (
    <li className={message.user ? 'user' : 'bot'} style={{ marginBottom: props.showOptions ? '50px' : '2px' }}>
      {
        props.lang === 'en' ? (message.text_en && !message.title_en ? message.text_en : message.title_en)
          : (message.text_pt && !message.title_pt ? message.text_pt : message.title_pt)
      }
      {
        props.showOptions ?
          <div style={{ position: 'fixed', bottom: 5, left: '50%', transform: 'translateX(-50%)', maxWidth: '420px', width: '100%' }}>
            <Slider active={message.options && message.options.length !== 1} theme_color={props.theme_color}>
              {
                message.response.buttons ? message.response.buttons.map((button, i) => {
                  return <div key={i} style={{ marginLeft: 5 }}>
                    <Option onClick={() => props.onResponse(button)} theme_color={props.theme_color}>
                      {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                    </Option>
                  </div>
                })
                  : null
              }
            </Slider>
          </div> : null
      }
    </li>
  );
}

export default MessageLoop;