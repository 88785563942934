import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core'
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgb(0 0 0 / 45%)',
    display: 'block',
    overflow: 'hidden',
    padding: '10px',
    position: 'fixed',
    top: '45px',
    height: 'calc( 100vh - 45px )',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '420px',
    width: '100%',
    zIndex: 10,
    scrollSnapAlign: 'none !important',
  },
  container: {
    background: '#6673E7',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    position: 'relative',
    width: '100%',
    height: 'calc( 100vh - 150px )',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    padding: '10px 15px',
    textTransform: 'uppercase',
  },
  detail_text: {
    padding: '0 15px 40px 15px',
    overflow: 'auto',
  },
  close_btn: {
    cursor: 'pointer'
  }
}));

const ModalDetail = (props) => {
  const classes = useStyles();
  
  const getTitle = element => {
    let title = element && element.title_pt ? element.title_pt : '';
    if(props.lang === 'en' && element && element.title_en) title = element.title_en;
    return title;
  }

  const getDetailText = element => {
    let subtitle = element && element.detail_pt ? element.detail_pt : '';
    if(props.lang === 'en' && element && element.detail_en) subtitle = element.detail_en;
    return subtitle;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span>{getTitle(props.element)}</span>
          <div className={classes.close_btn} onClick={() => props.onClose()}>
            <Icon icon="close-small" size="35" />
          </div>
        </div>
        <div className={classes.detail_text} dangerouslySetInnerHTML={{ __html: getDetailText(props.element) }}>
        </div>
      </div>
    </div>
  )
}

export default ModalDetail;