import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../components/Logo';
import NavItem from './NavItem';
import MenuButton from './MenuButton';
import { useLocation } from 'react-router-dom';

import {
  Box,
  List,
  makeStyles
} from '@material-ui/core';

const items = [
  {
    href: '/backoffice',
    icon: 'dashboard',
    title: 'Dashboard'
  },
  {
    href: '/backoffice/create',
    icon: 'conversation',
    title: 'Criar'
  },
/*   {
    href: '/backoffice/stats',
    icon: 'charts',
    title: 'Stats'
  }, */
  {
    href: '/backoffice/tutorial',
    icon: 'tutorials',
    title: 'Tutorial'
  },
  {
    href: '/backoffice/faqs',
    icon: 'faqs',
    title: 'FAQs'
  },
  {
    href: '/backoffice/settings',
    icon: 'settings',
    title: 'Definições'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "0px 0px 20px 0px",
    backgroundColor: theme.palette.primary.dark,
    position: 'fixed',
    width: '100%',
    overflow: 'hidden',
    minHeight: '0',
    zIndex: 1,
    transition: 'all 250ms',
    [theme.breakpoints.up('sm')]: {
      borderRadius: "0px 20px 0px 0px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.04)",
      width: '84px',
      minHeight: '100vh !important',
    }, 
  },
  openMenuBtn: {
    display: 'block',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }, 
  },
  navigation: {
  },
  logo: {
    width: 43,
    height: 48,
    [theme.breakpoints.up('sm')]: {
      width: 56,
      height: 76
    }, 
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 206,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = () => {
    isMobileNavOpen ? setMobileNavOpen(false) : setMobileNavOpen(true);
  }

  const location = useLocation();

  return (
    <>
    <Box className={classes.root} style={{minHeight: isMobileNavOpen ? '100vh' : '0', maxHeight: isMobileNavOpen ? '100vh' : '80px'}}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          p={2}
        >
          <Logo className={classes.logo}/>
          <Box className={classes.openMenuBtn} onClick={toggleNav}>
            <MenuButton open={isMobileNavOpen} />
          </Box>
        </Box>
        <Box 
          alignItems="center"
          display="flex"
          flexDirection="column"
          mt={3}
          className={classes.navigation}
        >
          <List className={classes.navigationList}>
            {location.pathname !== "/faqs" ? items.map((item) => (
              <NavItem
                href={item.href}
                type={item.type}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            )) : null}
          </List>
        </Box>
      </Box>
    </Box>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;