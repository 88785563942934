import React from 'react';

import Icon from "./../../components/Icon";

import {
  makeStyles,
  IconButton,
  Modal,
  Backdrop,
  Box,
  Typography,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide'; import { Fragment } from 'react';
import { useAuth } from 'core/services/auth';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 384,
    background: '#FFFFFF',
    borderRadius: '6px',
    padding: 32,
    outline: 'none',
    textAlign: 'center',
    maxHeight: '100vh',
    overflow: 'auto'
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  box: {
    cursor: 'pointer',
    marginTop: 6,
    width: 320,
    height: 67,
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 47.92%, #F3F3F3 100%)',
    borderRadius: '6px',
    fontWeight: 400,
    textAlign: 'left',
    padding: '5px 14px 5px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    fontWeight: 400,
    textAlign: 'left',
    padding: '4px 0px 4px 0px',
  },
  addBtn: {
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      top: '5px',
      height: '5px',
    },
    '&::after': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      bottom: '5px',
      height: '5px',
    },
  }
}));

const componentsList = {
  text: {
    title: 'Texto simples',
    description: '(balão de texto individual)',
    type: 'text',
    constrain: false,
  },
  buttons: {
    title: 'Texto com Links externos​',
    description: '(texto e máx 3 botões com links)​',
    type: 'buttons',
    constrain: false, //TODO postback
  },
  options: {
    title: 'Pergunta',
    description: '(com opção de respostas múltiplas)​',
    type: 'options',
    constrain: true,
  },
  template_generic: {
    title: 'Carrossel de imagens com links​',
    description: '(imagens, com título e link externo)​',
    type: 'template_generic',
    constrain: false, //TODO postback
  },
  template_places: {
    title: 'Carrossel com imagens e geolocalização',
    description: '(imagens com título, direções e link externo)',
    type: 'template_places',
    constrain: false, //TODO postback
  },
  template_images: {
    title: 'Carrossel com imagens',
    description: '(imagens com detalhe)',
    type: 'template_images',
    constrain: false, //TODO postback
  },
  closearea: {
    title: 'Fecho de area',
    description: '(voltar a chamar o menu da area)​',
    type: 'closearea',
    constrain: true
  },
  loopback: {
    title: 'Fecho de caminho de conversa​',
    description: '(voltar a chamar o menu inicial)​',
    type: 'loopback',
    constrain: true
  }
};

const ModalAdd = props => {
  const { user } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddResponse = type => {
    props.onAddResponse(type, props.index);
    setOpen(false);
  };

  const getComponents = () => {
    const types = user && user.plan ? user.plan.components.split(',') : [];
    const depth = user && user.plan ? user.plan.depth : 10;

    let components = [];

    if (props.depth < depth) {
      for (var i = 0; i < types.length; i++) {
        if (componentsList[types[i]]) components.push(componentsList[types[i]]);
      }
    } else components.push(componentsList['text']);

    if(props.area) components.push(componentsList['closearea']);
    if(props.card) components.push(componentsList['loopback']);

    return components;
  };

  const renderComponent = component => {
    if (user.plan.unlocked || !(props.constrain === true && component.constrain || props.index === 0 && component.constrain)) {
      return <Box className={classes.box} onClick={() => onAddResponse(component.type)} key={component.title} >
        <Box>
          <Typography className={classes.item} variant="h4" >
            {component.title}
          </Typography>
          <Typography className={classes.item} variant="h5">
            {component.description}
          </Typography>
        </Box>
        <Icon icon="add" size={28} color="#4050E1" />
      </Box>
    }
  };

  // const renderComponent = component => {
  //   if (props.constrain === true && component.constrain || props.index === 0 && component.constrain) {
  //     return <Box className={classes.box} style={{ opacity: 0.5 }} key={component.title} >
  //       <Box>
  //         <Typography className={classes.item} variant="h4" >
  //           {component.title}
  //         </Typography>
  //         <Typography className={classes.item} variant="h5">
  //           {component.description}
  //         </Typography>
  //       </Box>
  //       <Icon icon="add" size={28} color="#4050E1" />
  //     </Box>
  //   } else {
  //     return <Box className={classes.box} onClick={() => onAddResponse(component.type)} key={component.title} >
  //       <Box>
  //         <Typography className={classes.item} variant="h4" >
  //           {component.title}
  //         </Typography>
  //         <Typography className={classes.item} variant="h5">
  //           {component.description}
  //         </Typography>
  //       </Box>
  //       <Icon icon="add" size={28} color="#4050E1" />
  //     </Box>
  //   }
  // };

  const renderComponents = () => {
    var components = getComponents();

    return (
      <>
        {components.map((component) => (
          <Fragment key={component.title}>
            {renderComponent(component)}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <Box>
      <IconButton onClick={handleOpen} className={classes.addBtn}>
        <Icon icon="add" size={28} color="#4050E1" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Slide in={open} timeout={500} >
          <Box className={classes.paper}>
            <Typography className={classes.title} variant="h2">
              Adicione ao fluxo
            </Typography>
            <Typography className={classes.subtitle} variant="h4" style={{ marginBottom: 32 }} >
              Lista de componentes que pode adicionar.
            </Typography>
            {renderComponents()}
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}

export default ModalAdd;