import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles, } from '@material-ui/core'
import { ServicesContext } from '../../context/services';
import MessageText from '../components/MessageText';
import MessageCard from '../components/MessageCard';
import MessageTemplate from '../components/MessageTemplate';
import MessageOptions from '../components/MessageOptions';
import MessageAreaLoop from '../components/MessageAreaLoop';
import MessageLoop from '../components/MessageLoop';
import MessageButtons from '../components/MessageButtons';
import Header from '../components/ui/Header';
import GoogleMaps from '../components/ui/GoogleMaps';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    padding: '50px 10px 0px 10px',
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxHeight: '100vh',
    width: '100%',
  },
  messages: props => ({
    fontSize: '16px',
    lineHeight: '22px',
    listStyle: 'none',
    margin: '0',
    padding: '0',
    position: 'relative',
    zIndex: 1,
    '& > li': {
      display: 'inline-block',
      clear: 'both',
      padding: '10px 20px',
      borderRadius: '30px',
      marginBottom: '2px',
    },
    '& .typing': {
      padding: 0,
      display: 'block',
      float: 'left',
      width: '70px',
    },
    '& .bot': {
      background: props.white_label && props.theme_color ? props.theme_color : '#4050E1',
      color: '#fff',
      float: 'left',
      borderBottomLeftRadius: '5px',
    },
    '& > .user': {
      background: props.white_label && props.theme_color ? '#f2f2f2' : '#00E7E4',
      color: '#3A3A3A',
      float: 'right',
    },
    '& .bot + .user': {
      marginTop: '15px',
      borderBottomRightRadius: '5px',
    },
    '& .user + .bot': {
      marginTop: '15px',
    },
    '& > .bot + .bot': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& > .user + .user': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    '& > .user:last-of-type': {
      borderBottomRightRadius: '30px',
    }
  }),
}));

function MessagesView(props) {
  const { services } = useContext(ServicesContext);
  const styleProps = { theme_color: props.conversation.theme_color, white_label: props.conversation.white_label };
  const classes = useStyles(styleProps);
  const innerHeight = window.innerHeight;
  const [messages, setMessages] = useState([]);
  const [hasStarted, setStart] = useState(false);
  const [typing, setTyping] = useState(false);
  const [lang, setLanguage] = useState(null);
  const [maps, setMaps] = useState(null);

  const messagesEndRef = useRef(null)

  const addMessage = (message, current = 0, total = 0) => {
    setMessages(messages => [...messages, message]);
    if (current === total) setTyping(false);
  }

  const getAction = id => {
    setTyping(true);
    services.actionsApi.getById(id).then(({ Action }) => {
      if (Action.id && Action.responses) {
        Action.responses.forEach((response, i) => {
          setTimeout(() => {
            addMessage(response, i, Action.responses.length - 1);
          }, i * 750);
        });
      }
    });
  }

  const onResponse = button => {
    if (button.id === 'lang_pt' || button.id === 'lang_en') {
      setLanguage(button.id === 'lang_pt' ? 'pt' : 'en');
    } else {
      switch (button.type) {
        case 'location':
          setMaps(button);
          break;
        case 'places':
          button.place_lat = button.conversation.place_lat;
          button.place_lng = button.conversation.place_lng;
          setMaps(button);
          break;
        default:
          let userResponse = button;
          userResponse.user = true;
          userResponse.type = 'text';
          addMessage(userResponse);
          getAction(userResponse.payload.id);
          saveInteraction(button);
      }
    }
  }

  const saveInteraction = (button) => {
    const data = {
      conversation: props.conversation.id,
      action: button.payload.id,
      ref: button.title_pt,
      lang
    };

    services.interactionsApi.createPostback(data).then(({ Interaction }) => {
      console.log(Interaction);
    });
  }

  useEffect(function () {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages]);

  useEffect(function () {
    if (!props.conversation.show_english && props.conversation.start.id) {
      setTyping(true);
      setTimeout(() => {
        setLanguage('pt');
      }, 3000);
    }
    else selectLanguage();
  }, []);

  useEffect(function () {
    if (lang && props.conversation.start.id && !hasStarted) {
      setMessages([]);
      getAction(props.conversation.start.id);
      setStart(true);
    }
  }, [lang]);

  const selectLanguage = () => {
    setTyping(true);
    const langMessages = [
      { id: 'lang01', text_pt: 'Selecione o idioma de sua preferência', text_en: 'Selecione o idioma de sua preferência', type: 'text' },
      {
        id: 'lang02',
        text_pt: 'Select the language of your preference',
        text_en: 'Select the language of your preference',
        type: 'options',
        options: [
          { id: 'lang_pt', title_pt: 'Português', title_en: 'Português' },
          { id: 'lang_en', title_pt: 'English', title_en: 'English' },
        ]
      },
    ];
    langMessages.forEach((response, i) => {
      setTimeout(() => {
        addMessage(response, i, langMessages.length - 1);
      }, i * 750);
    });
  }

  const onUpdateLang = lang => {
    setLanguage(lang);
  }

  return (
    <div className={classes.root} style={{ maxHeight: innerHeight }} >
      <Header
        conversation={props.conversation}
        lang={lang}
        theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null}
        updateLang={onUpdateLang}
      />
      <ul className={classes.messages}>
        {
          messages.map((message, index) => {
            switch (message.type) {
              case 'text':
                return <MessageText key={index} message={message} lang={lang ? lang : 'pt'} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'card':
                return <MessageCard key={index} message={message} lang={lang ? lang : 'pt'} onResponse={onResponse} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'template':
                return <MessageTemplate key={index} message={message} lang={lang ? lang : 'pt'} conversation={props.conversation.id} onResponse={onResponse} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'options':
                return <MessageOptions key={index} message={message} lang={lang ? lang : 'pt'} onResponse={onResponse} showOptions={index === messages.length - 1} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'template_places':
                return <MessageTemplate key={index} message={message} lang={lang ? lang : 'pt'} conversation={props.conversation.id} onResponse={onResponse} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'template_images':
                return <MessageTemplate key={index} message={message} lang={lang ? lang : 'pt'} conversation={props.conversation.id} onResponse={onResponse} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'closearea':
                return <MessageAreaLoop key={index} message={message} lang={lang ? lang : 'pt'} onResponse={onResponse} showOptions={index === messages.length - 1} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'loopback':
                return <MessageLoop key={index} message={message} lang={lang ? lang : 'pt'} onResponse={onResponse} showOptions={index === messages.length - 1} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              case 'buttons':
                return <MessageButtons key={index} message={message} lang={lang ? lang : 'pt'} conversation={props.conversation.id} onResponse={onResponse} theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null} />
              default:
            }
          })
        }
        {typing ? <li className="typing"><img src="https://support.signal.org/hc/article_attachments/360016877511/typing-animation-3x.gif" width="70" /></li> : null}
        <li ref={messagesEndRef} style={{ float: 'left' }} />
      </ul>
      {
        maps ?
          <GoogleMaps
            place_lat={maps.place_lat}
            place_lng={maps.place_lng}
            place_name={lang === 'pt' ? maps.title_pt : maps.title_en}
            onClose={() => setMaps(null)}
            theme_color={props.conversation.white_label && props.conversation.theme_color ? props.conversation.theme_color : null}
          />
          : null
      }
    </div>
  );
}

export default MessagesView;