export default function makeOptionsApi({
    query,
    mutation,
}) {
    const optionFragment = `
        id
        title_pt
        title_en
        payload { id }
    `;

    return {
        getAll: () => query(`
            query {
                allOptions { ${optionFragment} }
            }
        `),

        getById: id => query(`
            query{
                Option(where: {id: "${id}"})  { ${optionFragment} }
            }
        `),

        get: ids => query(`
            query {
                allOptions(where: { id_in: ${ids} }) { ${optionFragment} }
            }
        `),

        create: data => mutation(`
            mutation {
                createOption(data: {
                    title_pt: "${data.title_pt}",
                    title_en: "${data.title_en}"
                }) { ${optionFragment} }
            }
        `),

        update: (id, data) => mutation(`
            mutation {
                updateOption(id: "${id}", data: ${data}) { ${optionFragment} }
            }
        `),

        delete: id => mutation(`
            mutation {
                deleteOption(id: "${id}") { id }
            }
        `),
    };
}