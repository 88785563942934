import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Icon from "../../components/Icon";
import SearchLocationInput from '../../components/SearchLocationInput';
import ModalDelete from './ModalDelete'

import { fade } from '@material-ui/core/styles';
import {
  makeStyles,
  withStyles,
  Typography,
  Box,
  Grid,
  Link,
  Button,
  CardMedia,
  InputBase,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@material-ui/core';

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} >
        {icon ? <ListItemIcon className={classes.btnBack}>{icon}</ListItemIcon> : null}
        <ListItemText disableTypography className={classes.backTitle} primary={primary} />
      </ListItem>
    </li>
  );
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    color: theme.palette.common.white,
  },
  track: {
    borderRadius: 26 / 2,
    border: '1px solid #FFFFFF',
    backgroundColor: '#FFFFFF',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const TagsInput = props => {
  const classes = useStyles();
  const [tags, setTags] = React.useState(props.tags);

  useEffect(function () {
    setTags(props.tags)
  }, [props.tags]);

  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = event => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      props.selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  return (
    <Box>
      <Box>
        <input
          type="text"
          className={classes.formInputs}
          onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
          placeholder="Introduza Tags e clique em ENTER"
        />
      </Box>
      <div className="tags-input" style={{ width: '320px', display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', margin: '10px -5px' }}>
        {tags.map((tag, index) => (
          <Chip
            key={tag}
            label={tag}
            color="primary"
            onDelete={() => removeTags(index)}
            style={{ margin: '5px' }}
          />
        ))}
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '22px',
  },
  content: {
    marginTop: '5px',
  },
  btnBack: {
    fontWeight: 500,
    fontSize: '22px',
    marginLeft: '26px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
  backTitle: {
    fontSize: '22px',
    marginLeft: '-16px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  formInputs: {
    width: '320px',
    minHeight: '42px',
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    padding: '12px',
    fontFamily: 'Heebo',
    outline: 'none',
  },
  formInputsGuests: {
    width: '320px',
    height: '42px',
    background: 'none',
    borderRadius: '6px',
    border: '1px solid #3A3A3A',
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    padding: '12px',
    fontFamily: 'Heebo',
    outline: 'none',
  },
  searchItem: {
    display: 'flex',
    width: '320px',
    height: '42px',
    borderRadius: '6px',
    justifyContent: 'space-between',
    backgroundColor: fade('#FFFFFF', 1),
    '&:hover': {
      backgroundColor: fade('#FFFFFF', 0.75),
    },
    marginLeft: 0,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: theme.palette.primary.dark,
  },
  searchInput: {
    border: 'none',
    width: '250px',
    backgroundColor: 'transparent',
    padding: '12px',
    outline: 'none',
  },
  formUpload: {
    display: 'none',
  },

  btnUpload: {
    width: '97px',
    height: '34px',
    borderRadius: '34px',
    marginTop: '16px',
    textTransform: 'none',
  },
  btnQr: {
    width: '111px',
    height: '34px',
    borderRadius: '34px',
    marginTop: '16px',
    marginRight: '6px',
    textTransform: 'none',
  },
  btnCopy: {
    width: '67px',
    height: '34px',
    borderRadius: '34px',
    marginTop: '16px',
    textTransform: 'none',
  },
  btnShare: {
    width: '78px',
    height: '34px',
    borderRadius: '34px',
    marginTop: '16px',
    marginLeft: '6px',
    textTransform: 'none',
  },
  btnSave: {
    width: '151px',
    height: '34px',
    borderRadius: '6px',
    marginTop: '16px',
    textTransform: 'none',
  },
  media: {
    backgroundColor: '#F3F7FF',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 20px',
    width: '100%',
  },
  picture: {
    width: '100%',
    borderRadius: 4,
    paddingTop: '56.25%',
  },
  logo: {
    width: '100px',
    height: '100px',
    borderRadius: 4
  },
}));

const SetupConversation = ({ title, onSubmit, conversation, onChange, onDelete }) => {
  const [googleImage, setGoogleImage] = useState(null);
  const [placeName, setPlaceName] = useState('');
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const { addToast } = useToasts();
  const classes = useStyles();

  useEffect(() => {
    if (conversation.image) {
      setImage(conversation.image.publicUrl);
    } else if (conversation.place_image) {
      setImage(conversation.place_image);
    }
  }, [conversation.image]);

  useEffect(() => {
    if (conversation.logo) {
      setLogo(conversation.logo.publicUrl);
    }
  }, [conversation.logo]);

  useEffect(() => {
    if (conversation.place_address) {
      setPlaceName(conversation.place_address);
    }
  }, [conversation.place_address]);

  const handleChange = e => {
    if (e.target.value || e.target.checked !== undefined) onChange(e);
  };

  const handleSubmit = event => {
    onSubmit();
  }

  const onDeleteConversation = id => {
    onDelete(id);
  }

  const selectedTags = tags => {
    onChange({ name: 'tag', values: tags.join() });
  };

  const onFileChanged = (event) => {
    const { files } = event.target;
    const file = files[0];

    onChange({ name: 'file', value: file });

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setImage(event.target.result);
    });
    reader.readAsDataURL(file);
  }

  const hiddenFileInput = useRef(null);

  const onFileUpload = event => {
    hiddenFileInput.current.click();
  }

  const onLogoChanged = (event) => {
    const { files } = event.target;
    const file = files[0];

    onChange({ name: 'logo', value: file });

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setLogo(event.target.result);
    });
    reader.readAsDataURL(file);
  }

  const hiddenLogoInput = useRef(null);

  const onLogoUpload = event => {
    hiddenLogoInput.current.click();
  }

  const onLocationChanged = result => {
    /*
    let photo;

    if (result.photos) {
      photo = result.photos[0].getUrl();
      setGoogleImage(result.photos[0].getUrl());
    }
    */
    onChange({
      name: 'location',
      value: {
        place_name: result.name,
        place_address: result.formatted_address,
        //place_image: photo ? photo : null,
        place_lat: result.geometry.location.lat(),
        place_lng: result.geometry.location.lng()
      }
    });
  }

  const downloadQRcode = e => {
    e.preventDefault();
    let a = document.createElement("a");
    a.href = conversation.qrcode;
    a.download = conversation.name + " QR Code.png";
    a.click();
  }

  const copyURL = e => {
    e.preventDefault();
    navigator.clipboard.writeText(conversation.url);
    addToast('Copiado!', { appearance: 'success', autoDismiss: true });
  }

  const isChecked = conversation => {
    return conversation.white_label;
  }


  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} className={classes.content}>
        <List>
          <ListItemLink disableTypography to="/backoffice/" primary={title} icon={<Icon icon="back" size={25} color="#4050E1" />} />
        </List>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
        <Box display='flex' flexDirection='column' alignItems='center' >
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Box>
              <form>
                <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
                  <label htmlFor="name" className={classes.formFields}>Nome</label>
                  <input
                    className={classes.formInputs}
                    placeholder="Inserir nome"
                    type="text"
                    name="name"
                    value={conversation.name}
                    onChange={handleChange}
                  />
                </Box>
              </form>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
              <Typography
                className={classes.formFields}
                align="justify"
                gutterBottom
              >
                Localização (caso se aplique)
              </Typography>
              <Box>
              </Box>
              <div className={classes.searchItem}>
                <SearchLocationInput onChange={onLocationChanged} location={placeName} classes={{ input: classes.searchInput }} />
                <div className={classes.searchIcon}>
                  <Icon icon="search" size={25} color="#4050E1" />
                </div>
              </div>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
              <Typography
                className={classes.formFields}
                align="justify"
                style={{ marginBottom: 0 }}
              >
                Personalizar tema
              </Typography>
              <FormControlLabel
                control={<IOSSwitch checked={conversation.white_label} onChange={handleChange} name="white_label" />}
                label={conversation.white_label ? 'Sim' : 'Não'}
              />
            </Box>
            {conversation.white_label ? 
            <>
              <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
                <Typography className={classes.formFields} align="justify"> Logo (100 x 100)</Typography>
                <input
                  accept="image/x-png,image/gif,image/jpeg" 
                  className={classes.formUpload}
                  name="contained-button-file"
                  type="file"
                  ref={hiddenLogoInput}
                  onChange={onLogoChanged}
                />
                <label htmlFor="contained-button-file">
                  <CardMedia
                    className={classes.logo}
                    image={logo && logo !== 'undefined' ? logo :  '/images/templates/frame.svg'}
                    title="logo"
                  />
                  <Button
                    variant="contained"
                    className={classes.btnUpload}
                    color="primary"
                    type="submit"
                    onClick={onLogoUpload}
                  >
                    Upload logo
                  </Button>
                </label>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
                <label htmlFor="theme_color" className={classes.formFields}>Cor do template</label>
                <input
                  className={classes.formInputs}
                  type="color"
                  name="theme_color"
                  style={{ width: 100 }}
                  value={conversation.theme_color}
                  onChange={handleChange}
                />
              </Box>
            </>
            : null}
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3} style={{ width: '100%' }}>
              <Typography className={classes.formFields} align="justify"> Foto </Typography>
              <input
                accept="image/x-png,image/gif,image/jpeg" 
                className={classes.formUpload}
                name="contained-button-file"
                type="file"
                ref={hiddenFileInput}
                onChange={onFileChanged}
              />
              <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                <CardMedia
                  className={classes.picture}
                  image={image && image !== 'undefined' ? image : (googleImage ? googleImage : '/images/templates/frame.svg')}
                  title="image"
                />
                {/* <CardMedia
                  alt="image"
                  component="img"
                  style={{ maxWidth: 320, borderRadius: '6px' }}
                  image={image && image !== 'undefined' ? image : (googleImage ? googleImage : '/images/templates/frame.svg')}
                /> */}
                <Button
                  variant="contained"
                  className={classes.btnUpload}
                  color="primary"
                  type="submit"
                  onClick={onFileUpload}
                >
                  Upload Foto
                </Button>
              </label>
            </Box>
            {conversation.id ?
              <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3} style={{ width: '100%' }}>
                <Typography className={classes.formFields} align="justify">QrCode</Typography>
                <Box className={classes.media}>
                  <CardMedia
                    alt="image"
                    component="img"
                    style={{ width: "200px" }}
                    image={conversation.qrcode}
                  />
                </Box>
                <label htmlFor="contained-button-file">
                  <Box display='flex' flexDirection='row' alignItems='center' pr={1}>
                    <Button variant="outlined" className={classes.btnCopy} color="primary" type="submit" onClick={downloadQRcode}>
                      Download
                    </Button>
                    {/* <Button variant="outlined" className={classes.btnShare} color="primary" type="submit">
                      Partilhar
                  </Button> */}
                  </Box>
                </label>
              </Box> : null}

            {conversation.url ? <Box>
              <form>
                <Box display='flex' flexDirection='column' alignItems='flex-start' >
                  <label htmlFor="url" className={classes.formFields}>Link</label>
                  <Link className={classes.formInputs} style={{ fontSize: '13px' }} href={conversation.url} target="_blank" rel="noopener">
                    {conversation.url}
                  </Link>
                </Box>
                <label htmlFor="contained-button-file">
                  <Box display='flex' flexDirection='row' alignItems='center' pr={1} mb={3}>
                    <Button variant="outlined" className={classes.btnCopy} color="primary" type="submit" onClick={copyURL}>
                      Copiar
                    </Button>
                    {/* <Button variant="outlined" className={classes.btnShare} color="primary" type="submit">
                      Partilhar
                  </Button> */}
                  </Box>
                </label>
              </form>
            </Box> : null}
            <Box>
              <Typography
                className={classes.formFields}
                align="justify"
              >
                Tag (e.g. Museus, Farois, etc)
              </Typography>
              <TagsInput selectedTags={selectedTags} tags={conversation.tag && conversation.tag.length > 0 ? conversation.tag.split(',') : []} />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
              <Typography
                className={classes.formFields}
                align="justify"
                style={{ marginBottom: 0 }}
              >
                Mostrar inglês
              </Typography>
              <FormControlLabel
                control={<IOSSwitch checked={conversation.show_english} onChange={handleChange} name="show_english" />}
                label={conversation.show_english ? 'Sim' : 'Não'}
              />
            </Box>
            {conversation.id ?
              <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
                <Typography
                  className={classes.formFields}
                  align="justify"
                  style={{ marginBottom: 0 }}
                >
                  Estado
                </Typography>
                <FormControlLabel
                  control={<IOSSwitch checked={conversation.status} onChange={handleChange} name="status" />}
                  label={conversation.status ? 'Online' : 'Offline'}
                />
              </Box>
              : null}
            <Box display='flex' mb={2}>
              <Button
                variant="contained"
                className={classes.btnSave}
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                {conversation.id ? "Gravar" : "Criar"}
              </Button>
              {conversation.id ?
                <ModalDelete
                  id={conversation.id}
                  name={conversation.name}
                  onDeleteConversation={onDeleteConversation}
                /> : null}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>

  );
}
export default SetupConversation;
