export default function makeInteractionsApi({
    query,
    mutation,
}) {
    const interactionFragment = `
        id
        type
        user { id }
        conversation { id }
        action { id }
    `;

    return {
        getByConversation: id => query(`
            query {
                allInteractions(
                    where: { conversation: { id: "${id}" } },
                ) { ${interactionFragment} }
            }
        `),

        getByConversations: ids => query(`
            query {
                allInteractions(
                    where: { conversation: { id_in: [${ids}] } },
                ) { ${interactionFragment} }
            }
        `),
        
        createPostback: data => mutation(`
            mutation {
                createInteraction(data: {
                    type: postback,
                    conversation: { connect: { id: "${data.conversation}" } },
                    action: { connect: { id: "${data.action}" } },
                    lang: ${data.lang},
                    ref: "${data.ref}"
                }) { id }
            }
        `)
    };
}