import React, { useState, useEffect, useRef } from "react";

const key = 'AIzaSyDZEbHU5o64OJSFUXRI7wOhwbBlS5RoiSg';

let loaded;
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, onChange) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ["establishment", "geocode"],
      componentRestrictions: { country: "pt" },
      fields: ["formatted_address", "geometry", "name", "place_id", "photos"]
    }
  );
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, onChange)
  );
}

async function handlePlaceSelect(updateQuery, onChange) {
  const addressObject = autoComplete.getPlace();
  updateQuery(addressObject.formatted_address);
  onChange(addressObject);
}

const SearchLocationInput = props => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  
  useEffect(() => {
    if(window && window.google){
      handleScriptLoad(setQuery, autoCompleteRef, props.onChange);
    } else{
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef, props.onChange)
      );
    }
    
  }, []);

  useEffect(() => setQuery(props.location), [props.location]);

  return (
    <div className="search-location-input" style={{width: '100%'}}>
      <input
        className={props.classes.input}
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Escreva uma localização"
        value={query}
        style={{width: '100%'}}
      />
    </div>
  );
}

export default SearchLocationInput;