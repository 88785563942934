import React from 'react';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ReactTooltip from "react-tooltip";

import {
  AreaChart, Area, ResponsiveContainer, PieChart, Pie
} from 'recharts';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '154px',
    width: '100%',
    borderRadius: '10px'
  },
  title: {
    color: theme.palette.primary.dark,
  },
  value: {
    color: theme.palette.primary.dark,
  },
}));

const Graph = ({ title, value, tooltip }) => {
  const classes = useStyles();

  const chartData = [
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
    {
      y: Math.floor(Math.random() * 200) + 1,
    },
  ];

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid container
          spacing={2}
        >
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.title} variant="h4">
                {title}
              </Typography>
              <Box ml={1} display="flex" alignItems="center">
                <InfoRoundedIcon data-tip data-for={title} color="primary" style={{ opacity: 0.4, cursor: 'pointer' }} fontSize="small" />
                <ReactTooltip id={title} place="top" effect="solid">
                  {tooltip}
                </ReactTooltip>
              </Box>
            </Box>
            <Typography className={classes.value} variant="h1">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Box style={{ backgroundColor: 'white', width: '105%', minHeight: 62, position: 'relative', left: '-5px' }}>
        <ResponsiveContainer height={62}>
          <AreaChart data={chartData} >
            <defs>
              <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#C663D6" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#C663D6" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <Area type="monotone" dataKey="y" stroke="#1F3996" fill="#1F3996" fill="url(#color)" isAnimationActive={false} animationDuration={0} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  );
};

export default Graph;
