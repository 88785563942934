import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  makeStyles,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';
import SimpleButton from '../../shared/SimpleButton';
import { ServicesContext } from '../../../../../context/services';
import ModalRemove from '../../shared/ModalRemove';
import AddButton from '../../shared/SimpleButton/AddButton';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  text: {
    padding: '18px 18px 10px 18px',
    border: '1px solid #DBDDF1',
    borderRadius: '6px',
    fontWeight: 'normal',
    textAlign: 'justify',
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
    color: theme.palette.primary.dark,
  },
  formInputsButtons: {
    fontSize: '14px',
    width: 282,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  btn: {
    fontSize: 12,
    width: 80,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  box: {
    margin: '5px 0',
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    fontFamily: 'Heebo, Arial',
    textAlign: 'left',
    padding:'14px',
    width: '100%',
    minHeight: 75,
  },
}));

const ModalEditConversationCard = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleSave = () => {
    props.onSave();
    handleClose();
  }
  const handleRemove = () => {
    props.onRemove();
    handleClose();
  }

  useEffect(() => {
    setValues({
      id: props.data ? props.data.id : '',
      text_pt: props.data ? props.data.text_pt : '',
      text_en: props.data ? props.data.text_en : '',
      buttons: props.data && props.data.buttons ? props.data.buttons : [],
      payload: props.data ? props.data.payload : null,
    });
  }, [props.data]);

  const [values, setValues] = useState({
    id: props.data ? props.data.id : '',
    text_pt: props.data ? props.data.text_pt : '',
    text_en: props.data ? props.data.text_en : '',
    buttons: props.data && props.data.buttons ? props.data.buttons : [],
    payload: props.data ? props.data.payload : null,
  });

  const handleChange = e => {
    props.onChange(e);
  };

  const updateObjectInArray = (array, newItem) => {
      return array.map(item => {
          if (item.id !== newItem.id) {
              return item;
          }
          return newItem;
      });
  }

  const onUpdatedButton = button => {
    const newButtons = updateObjectInArray(values.buttons, button);
    setValues({
        ...values,
      ['buttons']: newButtons
    });
    props.onChange({type: 'buttons', data: newButtons });
  };

  const onRemoveButton = id => {
    const newButtons = values.buttons.filter(function(button) { return button.id !== id });
    setValues({
        ...values,
      ['buttons']: newButtons
    });
    props.onChange({type: 'buttons', data: newButtons });
  }

  const addToButtons = button => {
    let newButtons = values.buttons;
    newButtons.push(button);
    setValues({
        ...values,
      ['buttons']: newButtons
    });
  }

  const onAddNewButton = e => {
    var data = { type: 'postback', title_pt: '', title_en: '', url: '' };
    services.buttonsApi.create(data).then(({ createButton }) => {
      // console.log('onAddNewButton', createButton)
      if (createButton) addToButtons(createButton);
    });
  }

  const onDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newButtons = Array.from(values.buttons);
    const [removed] = newButtons.splice(result.source.index, 1);
    newButtons.splice(result.destination.index, 0, removed);
    for (let i = 0; i < newButtons.length; i++) {
        const tab = newButtons[i];
        tab.order = i;
        updateIndex(tab);
    }
    let newValues = values;
    newValues.buttons = newButtons;
    setValues(newValues);
  };

  const updateIndex = (button) => {
      const serviceData = `{ 
          order: ${button.order}
      }`;

      services.buttonsApi.update(button.id, serviceData).then(({ updateButton }) => {
          console.log('updateButton', updateButton)
      });
  }

  const sortByOrder = array => {
      const sorted = array.sort(function(a, b) {
        return parseFloat(a.order) - parseFloat(b.order);
      });
      return sorted;
  }

  const can_delete = props.data && props.data.can_delete === true || props.data.can_delete === null;
  const show_english = props.show_english;

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Icon icon="edit" size={25} color="#4050E1" />
      </IconButton>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
      >
        <DialogTitle id="scroll-dialog-title" style={{textAlign: 'center'}}>
          <Typography
            className={classes.title}
          >
            Editar
          </Typography>
          <Typography
            className={classes.subtitle}
          >
            Pode escolher, no máximo, 3 caminhos de Conversa, que serão o seu Menu de Navegação. Pode editar os nomes pré-sugeridos.
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box className={classes.paper}>
            <Box>
              <Box>
                <Box>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="buttons">
                      {props => (
                          <div 
                              ref={props.innerRef}
                              {...props.droppableProps}
                          >
                          {
                              values.buttons ? sortByOrder(values.buttons).map((button, index) => (
                                  <Draggable
                                      key={button.id}
                                      draggableId={`id-${button.id}`} // must be a string
                                      index={index}
                                      disableInteractiveElementBlocking={true}
                                  >
                                  {props => (
                                      <Box 
                                          ref={props.innerRef}
                                          {...props.draggableProps}
                                          {...props.dragHandleProps}
                                          key={button.id} mb={2}
                                      >
                                          <Typography className={classes.formFields} > Botão {index + 1} </Typography>
                                          <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={show_english} />
                                      </Box>
                                  )}
                                  </Draggable>
                              ))
                              : null
                          }
                          {props.placeholder}
                      </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
                {/* <Box>
                    {
                      values.buttons ? values.buttons.map((button, index) => (
                          <Box key={button.id} mb={2}>
                            <Typography className={classes.formFields} >
                              Botão {index+1}
                            </Typography>
                            <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={props.show_english} />
                          </Box>
                      ))
                      : null
                    }
                </Box> */}
              </Box>
              <AddButton buttonsLength={values.buttons.length} limit={3} onAddNewButton={onAddNewButton} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' alignItems='center' justifyContent={can_delete ? 'space-between' : 'center'} style={{ width: '100%' }}  >
            <Button variant="contained" color="primary" className={classes.btn} onClick={handleSave}>
              Gravar
            </Button>
            { can_delete ? <ModalRemove type="button" onConfirmRemove={handleRemove} /> : null }
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ModalEditConversationCard;