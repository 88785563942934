import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    card: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        flexDirection: 'column',
        height: 420,
        maxWidth: 300,
        margin: 20,      
        overflow: 'hidden',
        textAlign: 'center',
    },
    image: {
        backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '147px',
        width: '100%',
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '34px 34px 0px 34px',
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '23px',
    },
}));


export default function OportunidadesItem ( { image, title, content} ) {
    const classes = useStyles();

  return (
    <Box className={classes.card}>
        <Box className={classes.image} style={{backgroundImage: `url(./../images/various/${image})`}} >
        </Box>
        <Box className={classes.text}>
            <Box mb={2} style={{ width: '100%' }}>
                <Typography variant="h2" style={{ fontWeight: 700 }} >
                    {title }
                </Typography>
            </Box>
            <Box >
                <Typography  className={classes.rich_text}>
                    {content}
                </Typography>
            </Box>
        </Box>
    </Box>
  )
}