import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core'
import { useParams } from "react-router-dom";
import { ServicesContext } from '../context/services';
// import HomeView from './views/HomeView';
import MessagesView from './views/MessagesView';

const useStyles = makeStyles((theme) => ({ 
  '@global': {
    '*': {
     boxSizing: 'border-box',
     margin: 0,
     padding: 0,
    },
    html: {
      overflow: 'hidden',
    },
    body: {
      overflow: 'hidden',
    },
  },
  wrapper: {
    backgroundColor: '#fff',
    margin: '0 auto',
    position: 'relative',
    maxWidth: '420px',
    minHeight: '100vh',
    overflow: 'hidden',
  }
}));

function Chat() {
  const { services } = useContext(ServicesContext);
  const { id } = useParams();
  const classes = useStyles();
  const [lang, setLanguage] = useState(null);
  const [conversation, setConversation] = useState(null);

  useEffect(function () {
    if (id) {
      services.conversationsApi.getById(id).then(({ Conversation }) => {
        if(Conversation && Conversation.id) setConversation(Conversation);
        //if(Conversation && !Conversation.show_english) setLanguage('pt')
      });
    }
  }, []);

  const onLanguageSelected = lang => {
    setLanguage(lang);
  }

  const onReset = () => {
    setLanguage(null)
  }

  return (
    <div className="App" style={{backgroundColor: '#f2f2f2', overflow: 'hidden'}}>
      <div className={classes.wrapper}>
        { 
          conversation && conversation.status === true ? 
            <MessagesView conversation={conversation} onBack={onReset}/>
          : null
        }
      </div>
    </div>
  );
}

export default Chat;