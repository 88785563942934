import React, { useContext, useEffect, useState } from 'react';
import TutorialSidebar from './TutorialSidebar';
import TutorialContent from './TutorialContent';

import { ServicesContext } from '../../../context/services';

import {
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0px',
    }
  },
  sidebar: {
    backgroundColor: '#EBEDF5',
    position: 'fixed',
    height: '220px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'block !important',
      paddingLeft: '15px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxWidth: '400px',
      height: '100vh',
    }, 
  },
  content: {
    background: theme.palette.background.dark,
    padding: '24px 42px',
    position: 'fixed',
    top: '220px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 200px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      top: '0px',
      left: '470px',
      fontWeight: 300,
      display: 'flex !important',
      flexDirection: 'column',
      alignIitems: 'flex-start',
      padding: '24px 42px',
      height: '100vh',
      width: '100%',
    }, 
  },
}));


const TutorialView = () => {
  const classes = useStyles();
  const { services } = useContext(ServicesContext);
  const [tutorials, setTutorials] = useState({});
  const [active, setActive] = useState({});
  
  useEffect(function () {
    services.tutorialsApi.getAll().then(({ allTutorials }) => {
      if(allTutorials.length > 0 ){
        setTutorials(allTutorials);
        setActive(allTutorials[0]);
      }
    });
  }, []);

  const onSelected = selected => {
    setActive(selected);
  };

  return (
    <Box className={classes.root} >
      <Box className={classes.sidebar} >
        <TutorialSidebar page="Tutorial" tutorials={tutorials} onSelected={onSelected}/>
      </Box>
      <Box className={classes.content}>
        <TutorialContent video={active.video_url} title={active.title} />
      </Box>
    </Box> 
    );
  };
  
  export default TutorialView;

  