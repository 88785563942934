import React, { Component } from 'react';
import Layout from '../layouts';

import FaqsView from '../views/FaqsView';

class Faqs extends Component {

  render() {
      return (
        <Layout>
          <FaqsView />
        </Layout>
      );
  }
}

export default Faqs;
