import React, { useContext, useEffect, useState } from 'react';

import ActionView from './ActionView';

import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    paddingBottom: 200
  },
  title: {
    fontSize: '22px',
    fontWeight: '500px',
  },
  subTitle: {
    marginBottom: '24px',
    fontSize: '14px',
    fontWeight: '400px',
  }
}));

const SetupResponses = props => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
        <Typography className={classes.title}>
          Fluxo
        </Typography>
        <Typography className={classes.subTitle} >
          Construa o seu fluxo de Conversa
        </Typography>
      </Grid>
      <ActionView actionId={props.startAction.id} depth={0} show_english={props.show_english} conversation={props.conversation}></ActionView>
    </Grid>
  )
}

export default SetupResponses;
