import React from 'react';


const MenuButton = props => {
  const styles = {
    container: {
      height: '32px',
      width: '32px',
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '4px',
    },
    line: {
      height: '2px',
      width: '20px',
      background: '#fff',
      transition: 'all 0.2s ease',
    },
    lineTop: {
      transform: props.open ? 'rotate(45deg)':'none',
      transformOrigin: 'top left',
      marginBottom: '5px',
    },
    lineMiddle: {
      opacity: props.open ? 0: 1,
      transform: props.open ? 'translateX(-16px)':'none',
    },
    lineBottom: {
      transform: props.open ? 'translateX(-1px) rotate(-45deg)':'none',
      transformOrigin: 'top left',
      marginTop: '5px',
    },       
  }
  return (
      <div style={styles.container}>
        <div style={{...styles.line,...styles.lineTop}}/>
        <div style={{...styles.line,...styles.lineMiddle}}/>
        <div style={{...styles.line,...styles.lineBottom}}/>
      </div>
    )
}
export default MenuButton;