import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import queryString from 'query-string';

import {
  Typography,
  Button,
  Slide,
  Input,
  Box
} from '@material-ui/core';

import { useStyles } from '../style';
import { useNavigation } from 'core/services/navigation';
import { useToasts } from 'react-toast-notifications';

const CHANGE_PASSWORD_MUTATION = gql`
	mutation changePassword($token: String!, $password: String!) {
		changePasswordWithToken(token: $token, password: $password) { id }
	}
`;

const ChangePassword = props => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const navigation = useNavigation();
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({ password: '', confirm: '' });

  useEffect(() => {
    const { key } = queryString.parse(location.search);
    if(key) setToken(key);
  }, []);

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.id]: e.target.value
    });
  };

  const [mutate] = useMutation(CHANGE_PASSWORD_MUTATION);

  const validate = () => {
    if(!user.password) addToast('Por favor insira uma password válida.', { appearance: 'error', autoDismiss: true });
    else if(user.password !== user.confirm) addToast('Passwords não coincidem.', { appearance: 'error', autoDismiss: true });
    else handleOnSubmit();
  }

  const handleOnSubmit = async data => {
    try {
      const password = user.password;
      const { data: { changePasswordWithToken } } = await mutate({ variables: { token, password } });
      if (changePasswordWithToken) navigation.goToDashboard();
      
    } catch (error) {
      console.log(error);
      addToast('Dados inválidos.', { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Slide in={true} timeout={500}>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h1" className="line">Recuperar Password</Typography>
          <Box mt={2}>
            <Typography>Introduza uma nova Password e confirme a sua escolha.</Typography>
          </Box>
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Password"
            type="password"
            id="password"
            name="password"
            value={user.password}
            onChange={handleChange}
            autoComplete='new-password'
          />
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Confirmar Password"
            type="password"
            id="confirm"
            value={user.confirm}
            onChange={handleChange}
            autoComplete='new-confirm'
          />
        </Box>
        <Box>
          <Button variant="outlined" className="landing green" style={{ marginTop: 20 }} onClick={validate}>
            Confirmar
          </Button>
        </Box>
      </Box>
    </Slide>
  );
}

export default ChangePassword;