import React, { useContext, useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';

import ModalButton from './ModalButton'
import { ServicesContext } from '../../../../../context/services';

import {
  Card,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },
  buttonLabel: {
    fontSize: '14px',
    width: '100%',
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    padding: '12px',
    outline: 'none',
    marginRight: '12px',
  },
  text: {
    fontSize: '12px',
  },
}));

const SimpleButton = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  useEffect(() => {
    if(data !== props.data) props.updatedButton(data);
  }, [data]);

  const handlerSave = () => {
    let serviceData = `{ type: ${data.type}, title_pt: "${data.title_pt.replace(/"/g, '\\"')}", title_en: "${data.title_en.replace(/"/g, '\\"')}", url: "${data.url}", url_en: "${data.url_en}", webview: ${data.webview} }`;
    
    if(data.id){
      services.buttonsApi.update(data.id, serviceData).then(({ updateButton }) => {
        // console.log('updateButton', updateButton)
        //setData( {...data, id: updateButton.id, title_pt: updateButton.title_pt, title_en: updateButton.title_en });
        setData( updateButton );
        addToast('Alterações gravadas.', { appearance: 'success', autoDismiss: true });
      });
    }
  }

  const handlerRemove = () => {
    props.onRemoveButton(data.id);
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    });
  };

  const handleToggle = state => {
    let serviceData = `{ disabled: ${state} }`;
    if(data.id){
      services.buttonsApi.update(data.id, serviceData).then(({ updateButton }) => {
        // console.log('updateButton', updateButton)
        //setData( {...data, id: updateButton.id, title_pt: updateButton.title_pt, title_en: updateButton.title_en });
        setData( updateButton );
        addToast(updateButton.disabled ? 'Botão "'+ updateButton.title_pt +'" desactivado.' : 'Botão "'+ updateButton.title_pt +'" activado.', { appearance: updateButton.disabled ? 'error' : 'success', autoDismiss: true });
      });
    }
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' key={data ? data.index : 0 }>
      <Card className={classes.card}>
        <Box className={classes.buttonLabel} >
          <Typography className={classes.text}>
            { data ? data.title_pt : '' }
          </Typography>
        </Box>
      </Card>
      <ModalButton data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} onToggleDisable={handleToggle} open={data && data.title_pt === '' ? true : false} show_english={props.show_english} />
    </Box>
  )
}

export default SimpleButton;

