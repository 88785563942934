import React from 'react';

import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player/youtube';

import {
  makeStyles,
  Box,
  Container,
  CardMedia
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  media: {
    maxWidth: 598,
    maxHeight: 360,
    width: '100% !important',
  },
}));

const TutorialContent = ({ title, video }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Box mb={3}>
        <Typography variant="h3">
        { title }
        </Typography>
      </Box>
      <ReactPlayer 
        url={ video } 
        className={classes.media}
      />
    </Container>
  )
}


export default TutorialContent;

