import React, { useContext, useEffect, useState } from "react";

import { useToasts } from 'react-toast-notifications';

import ModalOption from './ModalOption'
import { ServicesContext } from '../../../../../context/services';

import {
  Card,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 290,
  },
  buttonLabel: {
    fontSize: '14px',
    width: 282,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    padding: '12px',
    outline: 'none',
    marginRight: '12px',
  },
  text: {
    fontSize: '12px',
  },
}));

const SimpleOption = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  useEffect(() => {
    if(data !== props.data) props.updatedButton(data);
  }, [data]);

  const handlerSave = () => {
    let serviceData = `{ title_pt: "${data.title_pt.replace(/"/g, '\\"')}", title_en: "${data.title_en.replace(/"/g, '\\"')}"}`;
    
    if(data.id){
      if(props.type === "button"){
        services.buttonsApi.update(data.id, serviceData).then(({ updateButton }) => {
          // console.log('updateButton', updateButton)
          setData( {...data, id: updateButton.id, title_pt: updateButton.title_pt, title_en: updateButton.title_en });
        });
      }else{
        services.optionsApi.update(data.id, serviceData).then(({ updateOption }) => {
          // console.log('updateOption', updateOption)
          setData( {...data, id: updateOption.id, title_pt: updateOption.title_pt, title_en: updateOption.title_en });
          addToast('Alterações gravadas.', { appearance: 'success', autoDismiss: true });
        });
      }
    }
  }

  const handlerRemove = () => {
    props.onRemoveButton(data.id);
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    });
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center' key={data ? data.index : 0 }>
      <Box mr={1} display="flex">
        <Card className={classes.card}>
          <Box className={classes.buttonLabel} >
            <Typography className={classes.text}>
              { data ? data.title_pt : '' }
            </Typography>
          </Box>
        </Card>
      </Box>
      <ModalOption data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} open={data && data.title_pt === '' ? true : false} show_english={props.show_english}/>
    </Box>
  )
}

export default SimpleOption;

