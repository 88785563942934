import React, { useState, useEffect } from 'react';

import Graph from './Graph';
import Conversations from './Conversations';

import { useAuth } from 'core/services/auth';
import { useNavigation } from 'core/services/navigation';

import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Avatar
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    padding: '50px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '25px 40px',
    },
  },
  title: {
    color: theme.palette.primary.dark,
  },
  name: {
    color: theme.palette.primary.dark,
    fontWeight: 300,
  },
  avatar: {
    cursor: 'pointer',
    width: 36,
    height: 36,
    marginRight: '10px'
  },
  graphic: {
    width: '100%',
    maxWidth: '25%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  }
}));

const DashboardView = () => {
  const classes = useStyles();
  const [stats, setStats] = useState([
    { title: 'Inicios de Conversa', value: '--', tooltip: 'Número total de conversas iniciadas através da leitura de QR Code ou link directo.' },
    { title: 'Utilizadores', value: '--', tooltip: 'Total de utilizadores de Messenger únicos que acederam ás tuas conversas.' },
    { title: 'Interações', value: '--', tooltip: 'Total de vezes que os utilizadores interagiram com botões (e.g. Quem és tu?) ou opções (e.g Sim / Não).' },
    { title: 'Links Externos', value: '--', tooltip: 'Total de vezes que os utilizadores abriram links externos em botões das tuas conversas.'  }
  ]);
  const { user } = useAuth();
  const navigation = useNavigation();

  const onStats = (stats) => {
    setStats([
      { title: 'Inicios de Conversa', value: stats.start || '--', tooltip: 'Número total de conversas iniciadas através da leitura de QR Code ou link directo.'  },
      { title: 'Utilizadores', value: stats.users || '--', tooltip: 'Total de utilizadores de Messenger únicos que acederam ás tuas conversas.'  },
      { title: 'Interações', value: stats.postback || '--', tooltip: 'Total de vezes que os utilizadores interagiram com botões (e.g. Quem és tu?) ou opções (e.g Sim / Não).'  },
      { title: 'Links Externos', value: stats.link || '--' , tooltip: 'Total de vezes que os utilizadores abriram links externos em botões das tuas conversas.' }
    ]);
  };

  useEffect(() => {
    if (user && user.expired) {
      navigation.openTrial();
    }
  }, [user]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography className={classes.title} gutterBottom variant="h2">
          Dashboard
        </Typography>
        <Box alignItems="center">
          <Link to="/backoffice/settings" style={{ textDecoration: 'none' }}>
            <Box display="flex" alignItems="center">
              <Avatar className={classes.avatar} alt={user ? user.name : ''} />
              <Typography className={classes.name} gutterBottom variant="h4">
                {user ? user.name : ''}
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {
          stats && stats.length > 0 ? stats.map((graph, index) => (
            <Grid key={'stats-' + index} item className={classes.graphic} >
              <Graph title={graph.title} value={graph.value} tooltip={graph.tooltip}/>
            </Grid>
          )) : null
        }
        <Grid item xs={12}>
          <Conversations user={user} onStats={onStats} />
        </Grid>
      </Grid>
    </Container>

  );
};

export default DashboardView;
