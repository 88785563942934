import React from 'react';
import PropTypes from 'prop-types';
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import Landing from './landing';
import Dashboard from './backoffice/pages/Dashboard'
import Create from './backoffice/pages/Create';
import Edit from './backoffice/pages/Edit';
import Tutorial from './backoffice/pages/Tutorial';
import Faqs from './backoffice/pages/Faqs';
import Settings from './backoffice/pages/Settings';
import Wool from './landing/pages/Wool';
import Chat from './chat';

import Modal from './modal';

import { useAuth } from 'core/services/auth';

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated, user } = useAuth();
	return (
		<Route
			{...rest}
			render={({ location }) => (
				isAuthenticated
					?
						<Component user={user} />
					:
					(
						<Redirect
							to={{
								pathname: "/",
								search: `?id=signin&from=${location.pathname}`,
							}}
						/>
					)
			)}
		/>
	);
};

PrivateRoute.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

PrivateRoute.defaultProps = {
	children: null,
};

const Router = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Landing} />
				<Route exact path="/caso-wool" component={Wool} />
				<PrivateRoute exact path="/backoffice" component={Dashboard} />
				<PrivateRoute exact path="/backoffice/create" component={Create} />
          		<PrivateRoute exact path="/backoffice/edit/:id" component={Edit} />
				<PrivateRoute exact path="/backoffice/tutorial" component={Tutorial} />
				<PrivateRoute exact path="/backoffice/faqs" component={Faqs} />
				<PrivateRoute exact path="/backoffice/settings" component={Settings} />
				<Route exact path="/faqs" component={Faqs} />
          		<Route exact path="/chat/:id" component={Chat} />
				{/* <Route exact path="/backoffice/stats" component={Stats} /> */}
			</Switch>
			<Route path="/" component={Modal} />
		</BrowserRouter>
	);
}

export default Router;