export default function makeLeadsApi({
    query,
    mutation,
}) {
    const leadFragment = `
        id
    `;

    return {
        create: data => mutation(`
            mutation {
                createLead(data: {
                    name: "${data.name}",
                    email: "${data.email}",
                    phone: "${data.phone}"
                }) { ${leadFragment} }
            }
        `)
    };
}