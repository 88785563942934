import React from 'react';

import { useAuth } from 'core/services/auth';
import { useNavigation } from 'core/services/navigation';

import Icon from "./../../backoffice/components/Icon";

import { Box, Button, Container, Link, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  hero: {
    alignItems: 'flex-start',
    backgroundImage: 'url(./../images/various/headerBackground.svg)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    marginBottom: '215px',
    minHeight: 756,
    padding: ' 40px 0px',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./../images/various/headerBackground_mobile.jpg)',
      paddingBottom: '200px',
      marginBottom: '0',
    },
  },
  header: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      '& > img': {
        width: '79px',
      },
    },
  },
  nav: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  nav_itens: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 16,
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: 920,
    position: 'relative',
    top: '-100px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      display: 'block',
      textAlign: 'center'
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '50px',
    lineHeight: '50px',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      marginBottom: 28,
    },
  },
  healine: {
    left: 250,
    position: 'absolute',
    top: 287,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: 60,
      left: 0,
      position: 'relative',
      top: 0,
      marginTop: 54,
    },
  },
  info: {
    alignItems: 'flex-start',
    color: theme.palette.secondary.electric,
    display: 'inline-flex',
    flexDirection: 'column',
    margin: ' 0px 40px 40px 40px',
    maxWidth: '530px',
    overflow: 'hidden',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      margin: '10px 15px 0px 15px',
      textAlign: 'center',
    },
  },
  rich_text: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '29px',
  },
  phone_back: {
    backgroundImage: 'url(./../images/various/phoneBack.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    left: '876px',
    minHeight: '626px',
    padding: ' 10px 0px',
    position: 'absolute',
    top: '177px',
    width: '314px',
    [theme.breakpoints.down('md')]: {
      left: 0,
      margin: '0 auto',
      position: 'relative',
      top: 0,
    },
  },
  phone: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  phone_video: {
    left: '891px',
    height: '620px',
    padding: ' 10px 0px',
    position: 'absolute',
    top: '180px',
    width: '284px',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      margin: '0 auto',
      position: 'relative',
      top: '-610px',
    },
  },
  play: {
    left: '33%',
    position: 'absolute',
    top: '45%',
  },
  btn_phone: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  credits: {
    color: theme.palette.common.white,
    position: 'absolute',
    transform: 'rotate(90deg) translateX(50%)',
    top: '120px',
    right: '-155px',
    fontSize: 9,
    fontStyle: 'italic',
    [theme.breakpoints.down('md')]: {
      right: '-149px',
    },
  },
}));

export default function Header() {
  const { isAuthenticated } = useAuth();
  const navigation = useNavigation();
  const classes = useStyles();

  const onLogin = () => navigation.openLogin();
  const onBackoffice = () => navigation.goToDashboard();

  return (
    <header >
      <Box className={classes.hero} >
        <Container maxWidth="lg" style={{ position: 'relative' }} >
          <Box className={classes.header}>
            <Box flexGrow={1} flexBasis={0}>
              <Box className={classes.logo} >
                <a href="/">
                  <img src="./../images/logoComplex.svg" alt="Talk2Me" />
                </a>
              </Box>
            </Box>
            <Box className={classes.nav}>
              <Box mx={3}>
                <Link href="#what" >
                  <Typography className={classes.nav_itens}>O que é o Talk2me</Typography>
                </Link>
              </Box>
              <Box mx={3}>
                {/*                 <Link href="#business" >
                  <Typography className={classes.nav_itens}>Como funciona</Typography>
                </Link> */}
                <Link href="#opportunities" >
                  <Typography className={classes.nav_itens}>Oportunidades</Typography>
                </Link>
              </Box>
               <Box mx={3}>
                <Link href="#pricing" >
                  <Typography className={classes.nav_itens}>Planos Subscrição</Typography>
                </Link>
              </Box>
              <Box mx={3}>
                <Link href="#talk" >
                  <Typography className={classes.nav_itens}>Fale connosco</Typography>
                </Link>
              </Box>
              <Box mx={3}>
                <Link href="https://talk2me-stage.herokuapp.com/faqs" >
                  <Typography className={classes.nav_itens}>FAQs</Typography>
                </Link>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} flexBasis={0} justifyContent="flex-end">
              <Box display="flex">
                {!isAuthenticated ?
                  <Button variant="outlined" className="landingFull green" onClick={onLogin}>
                    Login
                  </Button>
                  :
                  <Button variant="outlined" className="landingFull green" onClick={onBackoffice}>
                    Entrar
                  </Button>
                }
              </Box>
            </Box>
          </Box>
          <Box className={classes.healine} >
            <Box className={classes.info}>
              <Box>
                <Typography className={classes.title}>
                  Aumente o valor da sua experiência turística!
                        </Typography>
              </Box>
              <Box mb={4}>
                <Typography className={classes.rich_text}>
                Crie conversas, conteúdos interactivos e transforme visitantes em embaixadores, através de um chatbot.
                </Typography>
              </Box>
              <Box mb={3}>
                <Button variant="outlined" className="landing green" href="#talk"  >
                  Fale connosco
                        </Button>
              </Box>
              {/*                     <Box className={classes.btn_phone} mb={3}>
                        <Button variant="outlined" className="landing white" href="/"  >
                            Ver exemplo
                        </Button>
                    </Box>  */}
            </Box>
          </Box>
          <Box className={classes.phone}>
            <Box className={classes.phone_back}></Box>
            <Box className={classes.phone_video}>
              <Box style={{width: '100%', borderRadius: 30, overflow: 'hidden', height: '100%' }}>
                <video width="100%" height="auto" id="something" allow='autoPlay' autoPlay="autoPlay" muted="muted" loop="loop" playsInline="playsInline">
                  <source src="./../images/various/talk2me.mp4" type="video/mp4"/>                                    
                </video>
              </Box>
              {/*                 <Box className={classes.play}>
                    <Icon icon="play-btn-home" size={101} color="#fff" />
                </Box> */}
            </Box>
          </Box>
        </Container>
        <Box className={classes.credits} >
          Imagens cedidas por WOOL | Covilhã Arte Urbana (autores: Bosoletti e Alberto Montes)
        </Box>
      </Box>
    </header>
  )
}