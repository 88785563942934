import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import SetupConversation from './SetupConversation';
import SetupResponses from './SetupResponses';

import { ServicesContext } from '../../../context/services';
import { useAuth } from 'core/services/auth';
import { useNavigation } from '../../../core/services/navigation';

import { useParams } from "react-router-dom";

import {
  makeStyles,
  Box,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EBEDF5',
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0px',
    }
  },
  tabs: {
    display: 'flex',
    width: '100%',
    '& > *': {
      padding: '20px',
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: 500,
      textAlign: 'center',
      width: '50%',
      '&.active': {
        backgroundColor: 'rgba(31, 57, 150, 0.1)',
        color: '#1F3996',
      }
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  sidebar: {
    backgroundColor: '#EBEDF5',
    [theme.breakpoints.up('sm')]: {
      display: 'block !important',
      paddingLeft: '15px',
      position: 'fixed',
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxWidth: '400px',
      height: '100vh',
      width: '100%',
    },
  },
  content: {
    background: theme.palette.background.dark,
    padding: '24px 42px',
    [theme.breakpoints.up('sm')]: {
      left: '470px',
      fontWeight: 300,
      display: 'flex !important',
      flexDirection: 'column',
      alignIitems: 'flex-start',
      padding: '24px 42px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'fixed',
      width: '100%',
      height: '100vh',
    },
  },
}));

const CreateView = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const classes = useStyles();
  const navigation = useNavigation();
  const { addToast } = useToasts();
  const { services } = useContext(ServicesContext);
  const [location, setLocation] = useState(null);
  const [conversation, setConversation] = useState({
    id: '',
    name: '',
    qrcode: '',
    place_address: '',
    show_english: true,
    status: false,
    white_label: false,
    link: '',
    tag: ''
  });


  useEffect(function () {
    if (id) {
      services.conversationsApi.getById(id).then(({ Conversation }) => {
        if (Conversation.id) setConversation({ ...Conversation, id: id });
      });
    }

  }, []);

  const parsedLocation = () => {
    return `place_name: "${location.place_name}",
      place_address: "${location.place_address}",
      place_image: "${location.place_image}",
      place_lat: ${location.place_lat},
      place_lng: ${location.place_lng}`;
  }

  const parsedData = create => {
    return `{ 
      name: "${conversation.name}",
      white_label: ${conversation.white_label},
      ${conversation.theme_color ? `theme_color: "${conversation.theme_color}",` : ''}
      show_english: ${conversation.show_english},
      status: ${conversation.status},
      tag: "${conversation.tag}",
      ${location ? parsedLocation(location) : ''} 
      ${create ? `user: { connect: { id: "${user.id}" } },` : ''} 
      ${conversation.file ? ', image: $image' : ''}
      ${conversation.logo && !conversation.logo.publicUrl ? ', logo: $logo' : ''}
    }`;
  }

  const createAction = () => {
    if (conversation.name === "") {
      addToast('Nome é um campo obrigatório.', { appearance: 'error', autoDismiss: true });
      return;
    }

    const data = parsedData(true);

    const onSuccess = ({ createConversation }) => {
      if (createConversation.id) {
        navigation.goTo('/backoffice/edit/' + createConversation.id);
      } else console.log('ERROR on createConversation');
    }


    if (conversation.file && !conversation.logo) {
      services.conversationsApi.createAndUpload(data, conversation.file).then(onSuccess);
    } else if (!conversation.file && conversation.logo && !conversation.logo.publicUrl) {
      services.conversationsApi.createAndUploadLogo(data, conversation.logo).then(onSuccess);
    } else if (conversation.file && conversation.logo && !conversation.logo.publicUrl) {
      services.conversationsApi.createAndUploadFileLogo(data, conversation.file, conversation.logo).then(onSuccess);
    } else {
      services.conversationsApi.create(data).then(onSuccess);
    }
  }

  const updateConversation = () => {
    const data = parsedData();

    const onSuccess = ({ updateConversation }) => {
      if (updateConversation.id) {
        setConversation({ ...updateConversation, file: null });
        addToast('Alterações gravadas com sucesso.', { appearance: 'success', autoDismiss: true });
      } else addToast('Ocorreu um erro inesperado.', { appearance: 'error', autoDismiss: true });
    }

    if (conversation.file && !conversation.logo || conversation.file && conversation.logo && conversation.logo.publicUrl) {
      console.log('updateAndUpload')
      services.conversationsApi.updateAndUpload(conversation.id, data, conversation.file).then(onSuccess);
    } else if (!conversation.file && conversation.logo && !conversation.logo.publicUrl) {
      console.log('updateAndUploadLogo')
      services.conversationsApi.updateAndUploadLogo(conversation.id, data, conversation.logo).then(onSuccess);
    } else if (conversation.file && conversation.logo && !conversation.logo.publicUrl) {
      console.log('updateAndUploadFileLogo')
      services.conversationsApi.updateAndUploadFileLogo(conversation.id, data, conversation.file, conversation.logo).then(onSuccess);
    } else {
      console.log('update')
      services.conversationsApi.update(conversation.id, data).then(onSuccess);
    }
  }

  const onChange = e => {
    if (e.target && e.target.name === 'name' || e.target && e.target.name === 'theme_color') setConversation({ ...conversation, [e.target.name]: e.target.value }); //textfields
    else if (e.target && e.target.checked !== undefined && e.target && e.target.name === 'status') setConversation({ ...conversation, [e.target.name]: e.target.checked }); //checkboxes
    else if (e.name === "tag") setConversation({ ...conversation, [e.name]: e.values });
    else if (e.name === "location") setLocation(e.value);
    else if (e.target && e.target.name === "show_english" || e.target && e.target.name === "white_label") setConversation({ ...conversation, [e.target.name]: e.target.checked });
    else setConversation({ ...conversation, [e.name]: e.value });
  };

  const onSaveDetails = () => {
    if (conversation.id) updateConversation();
    else createAction();
  }

  const onDelete = id => {
    if (id === conversation.id) {
      services.conversationsApi.delete(id).then(({ deleteConversation }) => {
        if (deleteConversation.id) navigation.goTo('/backoffice/');
      });
    }
  }

  const [tab, setTab] = useState(1);

  const onTabSelected = id => {
    setTab(id);
  }

  return (
    <Box className={classes.root}>
      {conversation.id && conversation.start ?
        <Box className={classes.tabs}>
          <Box onClick={() => onTabSelected(1)} className={tab === 1 ? 'active' : ''}>Editar</Box>
          <Box onClick={() => onTabSelected(2)} className={tab === 2 ? 'active' : ''}>Fluxo</Box>
        </Box>
        : null}
      <Box className={classes.sidebar} style={{ display: tab === 1 ? 'block' : 'none' }}>
        <SetupConversation
          title={conversation.id ? "Editar conversa" : "Criar conversa"}
          conversation={conversation}
          onChange={onChange}
          onSubmit={onSaveDetails}
          onDelete={onDelete}
        />
      </Box>
      {conversation.id && conversation.start ?
        <Box className={classes.content} style={{ display: tab === 2 ? 'block' : 'none' }}>
          <SetupResponses conversation={conversation} startAction={conversation.start} show_english={conversation.show_english} />
        </Box>
        : null}
    </Box>
  );
};

export default CreateView;