import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  questions: {
    color: '#000',
    fontWeight: 500,
    fontSize: '16px',
    margin:'0px 42px auto 42px',
  },
}));

const FaqsQuestion = ({ question }) => {
  const classes = useStyles();

  return (
    <ListItem button disableGutters>
        <ListItemText disableTypography className={classes.questions} primary={ question } />
    </ListItem>
          
  );
}

export default FaqsQuestion;
