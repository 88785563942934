import React, { Component } from 'react';
import Layout from '../layouts';
import DashboardView from '../views/DashboardView';


const Dashboard = props => {
  return (
    <Layout>
      <DashboardView />
    </Layout>
  );
}
export default Dashboard;
