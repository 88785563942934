import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import {
  makeStyles,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';
import SimpleOption from '../../shared/SimpleOption';
import AddOption from '../../shared/SimpleOption/AddOption';
import { ServicesContext } from '../../../../../context/services';
import ModalRemove from '../../shared/ModalRemove';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  text: {
    padding: '18px 18px 10px 18px',
    border: '1px solid #DBDDF1',
    borderRadius: '6px',
    fontWeight: 'normal',
    textAlign: 'justify',
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
    color: theme.palette.primary.dark,
  },
  formInputsButtons: {
    fontSize: '14px',
    width: 282,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  btn: {
    fontSize: 12,
    width: 80,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  box: {
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    fontFamily: 'Heebo, Arial',
    marginTop: 10,
    textAlign: 'left',
    padding:'10px 12px',
    width: '100%',
    minHeight: 60,
  },
}));

const ModalEditQuestionOptions = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const { services } = useContext(ServicesContext);
  const { addToast } = useToasts();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
      if(!values.text_pt) addToast('Texto em português em falta', { appearance: 'error', autoDismiss: true });
      else if(props.show_english && !values.text_en) addToast('Texto em inglês em falta', { appearance: 'error', autoDismiss: true });
      else handleSave();
  }

  const handleSave = () => {
    props.onSave();
    handleClose();
  }
  const handleRemove = () => {
    props.onRemove();
    handleClose();
  }

  useEffect(() => {
    setValues({
      id: props.data ? props.data.id : '',
      text_pt: props.data ? props.data.text_pt : '',
      text_en: props.data ? props.data.text_en : '',
      options: props.data ? props.data.options : [],
      payload: props.data ? props.data.payload : null,
    });
  }, [props.data]);

  const [values, setValues] = useState({
    id: props.data ? props.data.id : '',
    text_pt: props.data ? props.data.text_pt : '',
    text_en: props.data ? props.data.text_en : '',
    options: props.data ? props.data.options : [],
    payload: props.data ? props.data.payload : null,
  });

  const handleChange = e => {
    props.onChange(e);
    //needed to fix OSX special characters
    setValues({
      ...values,
      [e.target.id]: e.target.value
    });
  };

  const updateObjectInArray = (array, newItem) => {
      return array.map(item => {
          if (item.id !== newItem.id) {
              return item;
          }
          return newItem;
      });
  }

  const onUpdatedOption = option => {
    const newOptions = updateObjectInArray(values.options, option);
    setValues({
        ...values,
      ['options']: newOptions
    });
    props.onChange({type: 'options', data: newOptions });
  };

  const onRemoveButton = id => {
    const newOptions = values.options.filter(function(option) { return option.id !== id });
    setValues({
        ...values,
      ['options']: newOptions
    });
    props.onChange({type: 'options', data: newOptions });
  }

  const addToOptions = option => {
    let newOptions = values.options;
    newOptions.push(option);
    setValues({
        ...values,
      ['options']: newOptions
    });
  }

  const onAddNewOption = e => {
    var data = { title_pt: '', title_en: '' };
    services.optionsApi.create(data).then(({ createOption }) => {
      // console.log('onAddNewOption', createOption)
      if (createOption) addToOptions(createOption);
    });
  }

  const can_delete = props.data && props.data.can_delete === true || props.data.can_delete === null;

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Icon icon="edit" size={25} color="#4050E1" />
      </IconButton>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
      >
        <DialogTitle id="scroll-dialog-title" style={{textAlign: 'center'}}>
          <Typography
            className={classes.title}
          >
            {props.data && props.data.id ?  'Editar Pergunta' : 'Criar Pergunta'}
          </Typography>
          <Typography
            className={classes.subtitle}
          >
            Escreva uma Pergunta e não se esqueça de gravar. Depois, para escolher as respostas a essa pergunta, clique em “Adicionar Opções de Resposta”.​
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box className={classes.paper}>
            <Box>
              <Box>
              <form>
                  <textarea
                      className={classes.box}
                      type="text"
                      id="text_pt"
                      value={values.text_pt}
                      onChange={handleChange}
                      placeholder="Pergunta (em português)"
                      maxLength="280"
                  />
                  <textarea
                      className={classes.box}
                      type="text"
                      id="text_en"
                      value={values.text_en}
                      onChange={handleChange}
                      placeholder="Question (em inglês)"
                      maxLength="280"
                      style={{display: props.show_english ? 'inline-block' : 'none' }}
                  />
              </form>
              </Box>
              <Box mt={2}>
                <Box>
                  {
                    values.options ? values.options.map((option, index) => (
                        <Box key={option.id} mb={2}>
                          <Typography className={classes.formFields} >
                            Opção {index+1}
                          </Typography>
                          <SimpleOption data={option} updatedButton={onUpdatedOption} onRemoveButton={onRemoveButton} show_english={props.show_english} />
                        </Box>
                    ))
                    : null
                  }
                </Box>
              </Box>
              <AddOption optionsLength={values.options.length} limit={13} onAdd={onAddNewOption} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' alignItems='center' justifyContent={can_delete ? 'space-between' : 'center'} style={{ width: '100%' }}  >
            <Button variant="contained" color="primary" className={classes.btn} onClick={validate}>
              Gravar
            </Button>
            { can_delete ? <ModalRemove type="button" onConfirmRemove={handleRemove} /> : null }
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ModalEditQuestionOptions;