import React from 'react';

import {
  makeStyles,
  Modal,
  Backdrop,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';;

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 384,
      height: 303,
      background:'#FFFFFF',
      borderRadius: '6px',
      padding: 32,
      outline: 'none',
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
    },
    subtitle: {
      fontWeight: 400,
      marginTop: 5,
    },
    box: {
      marginTop: 35,
      width: 320,
      height: 67,
      background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 47.92%, #F3F3F3 100%)',
      border: '1px solid #DBDDF1',
      boxSizing: 'border-box',
      borderRadius: '6px',
    },
    item: {
      fontWeight: 400,
      textAlign: 'left',
      padding:'23px 14px 23px 14px',
    },
    btnDelet: {
    width: '160px',
    height:'34px',
    borderRadius: '6px',
    marginTop:'16px',
    marginLeft:'6px',
    textTransform: 'none',
    },
}));

const ModalDelete = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
      <Box>
        <Button variant="outlined" className={classes.btnDelet} color="primary" type="reset" onClick={handleOpen}>
            Eliminar
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{timeout: 500,}}
        >
          <Slide in={open} timeout={500} >
            <Box className={classes.paper}>
              <Typography
              className={classes.title}
              variant="h2"
              >
                Eliminar conversa
              </Typography>
              <Typography
              className={classes.subtitle}
              variant="h4"
              >
                Tem a certeza que pretende eliminar a seguinte conversa?
              </Typography>
              <Box className={classes.box}>
                <Typography
                className={classes.item}
                variant="h4"
                >
                  {props.name}
                </Typography>
              </Box>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={() => {props.onDeleteConversation(props.id)}} 
                style={{borderRadius: '32px', textTransform: 'none', marginTop: 32 }}
              >
                Eliminar
              </Button>
            </Box>
          </Slide>
        </Modal>
      </Box>
    );
}

export default ModalDelete;