export default function makeFaqsApi({
    query,
    mutation,
}) {
    const faqFragment = `
        id
        category{
            id
            title
            order
        }
        title,
        text
    `;

    return {
        getAll: () => query(`
            query {
                allFaqs { ${faqFragment} }
            }
        `),
    };
}