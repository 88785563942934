import { Box, Button, CardMedia, makeStyles, Typography, 
    FormControlLabel,
    Switch, IOS, 
    withStyles, } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServicesContext } from '../../../../../context/services';
import { useToasts } from 'react-toast-notifications';
// import Icon from '../../../../components/Icon';
import ModalRemove from '../ModalRemove';
// import SimpleButton from '../SimpleButton';
// import AddButton from '../SimpleButton/AddButton';



const useStyles = makeStyles(theme => ({
    formFields: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '6px',
        color: theme.palette.primary.dark,
    },
    input: {
        width: '100%',
        background: '#fff',
        border: '1px solid #DBDDF1',
        boxSizing: 'border-box',
        borderRadius: '6px',
        fontWeight: 400,
        fontFamily: 'Arial',
        textAlign: 'left',
        padding: '10px 12px',
    },
    btn: {
        display: 'inline-block',
        fontSize: 12,
        height: 34,
        borderRadius: '34px',
        textTransform: 'none',
        marginRight: 8,
        padding: '8px 16px',
    },
    formUpload: {
        display: 'none',
    },
    btnUpload: {
        width: '97px',
        height: '34px',
        borderRadius: '34px',
        marginTop: '16px',
        textTransform: 'none',
    },
}));


const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      color: theme.palette.common.white,
    },
    track: {
      borderRadius: 26 / 2,
      border: '1px solid #EBEDF5',
      backgroundColor: '#FFFFFF',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const ImageTab = props => {
    const classes = useStyles();
    const [element, setElement] = useState(props.data);
    const { services } = useContext(ServicesContext);
    const { addToast } = useToasts();
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [show_detail, setShowDetail] = useState(false);

    useEffect(() => {
        setElement(props.data);
        if (props.data.image && props.data.image.publicUrl) setImage(props.data.image.publicUrl);
    }, [props.data]);

    useEffect(() => {
        if(props.saveAll) validate();
    }, [props.saveAll]);

    useEffect(() => {
        props.onUpdatedElement(element)
        //if(element.subtitle_pt || element.subtitle_en || element.detail_pt || element.detail_en) setShowDetail(true);
        if(element.label_pt || element.label_en || element.detail_pt || element.detail_en) setShowDetail(true);
    }, [element]);

    const handleChange = e => {
        setElement({
            ...element,
            [e.target.id]: e.target.value
        });
    };

    // const parseButtons = buttons => {
    //     let arr = '';
    //     for (var i = 0; i < buttons.length; i++) {
    //         arr += `{ id: "${buttons[i].id}" }`;
    //     }
    //     return arr;
    // }

    // const validateButtons = () => {
    //     let isValid = false;
    //     for (let i = 0; i < element.buttons.length; i++) {
    //         const button = element.buttons[i];
    //         if(!button.disabled) isValid = true;
    //     }
    //     return isValid;
    // }

    const validate = () => {
        props.onUpdatedElement('validate');
        
        if (!element.title_pt) addToast('Título em português em falta', { appearance: 'error', autoDismiss: true });
        else if(props.show_english && !element.title_en) addToast('Título em inglês em falta', { appearance: 'error', autoDismiss: true });
        //else if(element.subtitle_pt === '') addToast('Subtítulo em português detalhe em falta', { appearance: 'error', autoDismiss: true });
        //else if(props.show_english && element.subtitle_en === '') addToast('Subtítulo em inglês detalhe em falta', { appearance: 'error', autoDismiss: true });
        else if(show_detail && element.label_pt === '') addToast('Label do botão em português detalhe em falta', { appearance: 'error', autoDismiss: true });
        else if(show_detail && props.show_english && element.label_en === '') addToast('Label do botão em inglês detalhe em falta', { appearance: 'error', autoDismiss: true });
        else if(show_detail && element.detail_pt === '') addToast('Detalhe em português em falta', { appearance: 'error', autoDismiss: true });
        else if(show_detail && props.show_english && element.detail_en === '') addToast('Detalhe em inglês em falta', { appearance: 'error', autoDismiss: true });
        //else if(element.buttons.length <= 0) addToast('Adicione pelo menos um botão', { appearance: 'error', autoDismiss: true });
        //else if(!validateButtons()) addToast('Tem de ter pelo menos um botão activo', { appearance: 'error', autoDismiss: true });
        else handleSave();
    }

    const handleSave = () => {
        //const buttonsIDs = parseButtons(element.buttons);
        const serviceData = `{ 
            title_pt: "${element.title_pt.replace(/"/g, '\\"')}", 
            title_en: "${element.title_en ? element.title_en.replace(/"/g, '\\"') : ''}", 
            subtitle_pt: "${element.subtitle_pt ? element.subtitle_pt.replace(/"/g, '\\"') : ''}", 
            subtitle_en: "${element.subtitle_en ? element.subtitle_en.replace(/"/g, '\\"') : ''}", 
            label_pt: "${element.label_pt ? element.label_pt.replace(/"/g, '\\"') : ''}", 
            label_en: "${element.label_en ? element.label_en.replace(/"/g, '\\"') : ''}", 
            detail_pt: "${element.detail_pt ? element.detail_pt.replace(/"/g, '\\"').replaceAll("\n", '<br/>') : ''}", 
            detail_en: "${element.detail_en ? element.detail_en.replace(/"/g, '\\"').replaceAll("\n", '\\n') : ''}", 
            ${file ? ', image: $image' : ''} 
        }`;
        //buttons: { disconnectAll: true, connect: [ ${buttonsIDs} ] } 
        if (element.id && !file) {
            services.elementsApi.update(element.id, serviceData).then(({ updateElement }) => {
                setElement(updateElement);
                props.onSaved(updateElement);
            });
        } else {
            services.elementsApi.updateAndUpload(element.id, serviceData, file.value).then(({ updateElement }) => {
                setElement(updateElement);
                props.onSaved(updateElement);
            });
        }
    }

    const handleRemove = () => {
        props.onRemoveTab(element.id);
    }

    // const addToElement = button => {
    //     let newButtons = element.buttons ? element.buttons : [];
    //     newButtons.push(button);
    //     setElement({
    //         ...element,
    //         ['buttons']: newButtons
    //     });
    // }

    // const onAddNewButton = e => {
    //     var data = { type: 'web_url', title_pt: '', title_en: '' };
    //     services.buttonsApi.create(data).then(({ createButton }) => {
    //         // console.log('onAddNewButton', createButton)
    //         if (createButton) addToElement(createButton);
    //     });
    // }

    // const updateButtonInArray = (buttons, newButton) => {
    //     return buttons.map(button => {
    //         if (button.id !== newButton.id) {
    //             return button;
    //         }
    //         return newButton;
    //     });
    // }

    // const onUpdatedButton = button => {
    //     const newButtons = updateButtonInArray(element.buttons, button);
    //     setElement({
    //         ...element,
    //         ['buttons']: newButtons
    //     });

    //     //props.onUpdatedElement(element);
    //     //props.onChange({type: 'options', data: newOptions });
    // };

    // const onRemoveButton = id => {
    //     const newButtons = element.buttons.filter(function (button) { return button.id !== id });
    //     setElement({
    //         ...element,
    //         ['buttons']: newButtons
    //     });
    //     //props.onChange({type: 'options', data: newOptions });
    // }

    const onFileChanged = (event) => {
        const { files } = event.target;
        const file = files[0];

        //onChange({ name: 'file', value: file });
        setFile({ name: 'file', value: file });

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            setImage(event.target.result);
        });
        reader.readAsDataURL(file);
    }

    const hiddenFileInput = useRef(null);

    const onFileUpload = event => {
        hiddenFileInput.current.click();
    }

    const onShowDetail = e => {
        setShowDetail(e.target.checked);
        if(e.target.checked === false){
            setElement({
                ...element,
                label_pt: '',
                label_en: '',
                detail_pt: '',
                detail_en: '',
            });
        }
    }

    return (
        <Box>
            <Box>
                <Box mt={0}>
                    <Typography className={classes.formFields} align="justify"> Título em português </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_pt"
                        value={element.title_pt}
                        onChange={handleChange}
                        placeholder="Label (em português)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                    <Typography className={classes.formFields} align="justify"> Título em inglês </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_en"
                        value={element.title_en}
                        onChange={handleChange}
                        placeholder="Label (em inglês)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2}>
                    <Typography className={classes.formFields} align="justify"> Subtítulo em português </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="subtitle_pt"
                        value={element.subtitle_pt}
                        onChange={handleChange}
                        placeholder="Subtítulo (em português)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                    <Typography className={classes.formFields} align="justify"> Subtítulo em inglês </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="subtitle_en"
                        value={element.subtitle_en}
                        onChange={handleChange}
                        placeholder="Subítulo (em inglês)"
                        maxLength="80"
                    />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2} mb={3}>
                <Typography className={classes.formFields} align="justify"> Foto </Typography>
                <input
                    accept="image/x-png,image/gif,image/jpeg"
                    className={classes.formUpload}
                    name="contained-button-file"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onFileChanged}
                />
                <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                    {/* <CardMedia
                        alt="image"
                        component="img"
                        image={image ? image : '/images/templates/frame.svg'}
                    /> */}
                    <CardMedia
                        style={{ paddingTop: '56.25%', borderRadius: '6px' }}
                        image={image ? image : '/images/templates/frame.svg'}
                        title="image"
                    />
                    <Button
                        variant="contained"
                        className={classes.btnUpload}
                        color="primary"
                        type="submit"
                        onClick={onFileUpload}
                    >
                        Upload Foto
                </Button>
                </label>
            </Box>
            <Box>
                <Box display='flex' flexDirection='column' alignItems='flex-start' mb={1}>
                <Typography
                    className={classes.formFields}
                    align="justify"
                    style={{ marginBottom: 0 }}
                >
                    Mostrar popup
                </Typography>
                <FormControlLabel
                    control={<IOSSwitch checked={show_detail} onChange={onShowDetail} name="show_detail" />}
                    label={show_detail ? 'Sim' : 'Não'}
                />
                </Box>
            </Box>
            {
                show_detail ?
                <Box>
                    <Box>
                        <Typography className={classes.formFields} align="justify">Titulo do popup (português)</Typography>
                        <input
                            className={classes.input}
                            type="text"
                            id="label_pt"
                            value={element.label_pt}
                            onChange={handleChange}
                            placeholder="Label do botão (em português)"
                            maxLength="80"
                        />
                    </Box>
                    <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                        <Typography className={classes.formFields} align="justify">Titulo do popup (inglês)</Typography>
                        <input
                            className={classes.input}
                            type="text"
                            id="label_en"
                            value={element.label_en}
                            onChange={handleChange}
                            placeholder="Label do botão (em inglês)"
                            maxLength="80"
                        />
                    </Box>
                    <Box mt={2}> 
                        <Typography className={classes.formFields} align="justify">Texto do popup (português)</Typography>
                        <textarea
                            rows={3}
                            className={classes.input}
                            type="text"
                            id="detail_pt"
                            value={element.detail_pt}
                            onChange={handleChange}
                            placeholder="Texto do popup (português)"
                        />
                    </Box>
                    <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                        <Typography className={classes.formFields} align="justify">Texto do popup (inglês)</Typography>
                        <textarea
                            rows={3}
                            className={classes.input}
                            type="text"
                            id="detail_en"
                            value={element.detail_en}
                            onChange={handleChange}
                            placeholder="Texto do popup (inglês)"
                        />
                    </Box>
                </Box>
                : null 
            }
            {/* <Box>
                {
                    element.buttons ? element.buttons.map((button, index) => (
                        <Box key={button.id} mb={2}>
                            <Typography className={classes.formFields} > Botão {index + 1} </Typography>
                            <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={props.show_english} />
                        </Box>
                    ))
                        : null
                }
            </Box>
            <AddButton buttonsLength={element.buttons.length} limit={3} onAddNewButton={onAddNewButton} /> */}
            <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }} mt={2} >
                <Button variant="contained" color="primary" className={classes.btn} onClick={props.onSave}>
                    Gravar
                </Button>
                <ModalRemove type="button" onConfirmRemove={handleRemove} label="Remover tab" />
            </Box>
        </Box>
    );
};

export default ImageTab;
