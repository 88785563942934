export default function makeElementsApi({
    query,
    mutation,
}) {
    const elementFragment = `
        id 
        type
        title_pt
        title_en
        subtitle_pt
        subtitle_en
        label_pt
        label_en
        detail_pt
        detail_en
        place_address
        place_lng
        place_lat
        place_image
        order
        conversation {
            id
            name
            image {
                publicUrl
            }
            place_address
            place_lng
            place_lat
            place_image
            qrcode
            url
            status
            tag
            user {
                id
            }
            start {
                id
            }
            createdAt
        }
        image {
            publicUrl
        }
        buttons { 
            id
            type
            title_pt
            title_en
            payload { id }
            url
            url_en
            webview
            can_delete
            can_edit
            disabled
            order
        }
    `;

    return {
        getAll: () => query(`
            query {
                allElements { ${elementFragment} }
            }
        `),

        getById: id => query(`
            query{
                Element(where: {id: "${id}"})  { ${elementFragment} }
            }
        `),

        get: ids => query(`
            query {
                allElements(where: { id_in: ${ids} }) { ${elementFragment} }
            }
        `),

        create: data => mutation(`
            mutation {
                createElement(data: {
                    type: ${data.type},
                    title_pt: "${data.title_pt}",
                    title_en: "${data.title_en}"
                }) { ${elementFragment} }
            }
        `),

        update: (id, data) => mutation(`
            mutation {
                updateElement(id: "${id}", data: ${data}) { ${elementFragment} }
            }
        `),

        updateAndUpload: (id, data, image) => mutation(`
            mutation updateQuery ($image: Upload) {
                updateElement(id: "${id}", data: ${data}) { ${elementFragment} }
            }
            `, { image }
        ),

        delete: id => mutation(`
            mutation {
                deleteElement(id: "${id}") { id }
            }
        `),
    };
}