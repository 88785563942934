import React from 'react';

import { Box,Container, makeStyles, Typography, } from '@material-ui/core'
import Icon from "./../../backoffice/components/Icon";

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        display: 'flex',
        minHeight: 656,
        paddingTop: 20,
        marginBottom: 180,
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 100,
        },
    },
    background: {
        backgroundImage: 'url(./../images/various/howBackground.svg)',
        backgroundPosition: 'center 35px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'auto',
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
            background: '#27D9D5',
            bottom: '6px',
            content: '""',
            height: 13,
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1
        }
    },
    card: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,        
        border: '2px solid #4050E1',
        borderRadius: '20px 20px 20px 0px',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        flexDirection: 'column',
        minHeight: 800,
        margin: 20,
        maxWidth: 390,
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            margin: 10,
        },
    },
    header: {
        backgroundColor: 'rgba(64,80,224,0.1)',
        color: '#1F3996',
        padding: '32px',
        textAlign: 'center',
        width: '100%'
      },
    image: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginTop: 46,
        minHeight: '156px',
        width: '166px',
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '24px 34px',
        textAlign: 'left',
    },
    bold: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 700,
        fontSize: '16px',
    },
    included: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 5,
    },
    not_included: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        opacity: 0.5,
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 5,
    },
}));


export default function Princing() {
    const classes = useStyles();

  return (
    <Box id='pricing' className={classes.root}>
        <Box className={classes.background}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box mb={14}>
                    <Typography className={classes.title} >
                        Planos <span className={classes.line_text}>Subscrição</span>
                    </Typography>
                </Box> 
                <Box display='inline-flex' alignItems='center' flexWrap='wrap' justifyContent='center' >
                    <Box className={classes.card}>
                        <Box className={classes.header}>
                            <Typography variant="h1" style={{ fontWeight: 700}}>BASIC</Typography>
                            <Box mt={2}>
                                <Typography variant="h2" color='secondary' style={{ fontWeight: 700}}>40 EUROS + IVA /MÊS</Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography style={{ fontWeight: 700}}>
                                    6 Meses período mínimo 
                                    <br/>
                                    15 dias experimentação grátis
                                    <br/>
                                    Máximo 20 QR CODES.
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.text}>
                            <Box className={classes.bold} mb={1}>
                                Este plano inclui:
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Geração de fluxos de conversa para um máximo de 20 objetos  
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Fluxo de conversa limitado a textos de introdução e a 3 grandes áreas de conteúdo. Textos e botões customizáveis. 
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Acesso adicional a Carrossel para interligar as várias conversas criadas e/ou Carrossel de fotografias com sugestões de locais georeferenciados perto da sua operação turística, com direções ligadas ao Google Maps   
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Acesso a quadro de estatísticas com as principais métricas das suas conversas
                                </Box>
                            </Box>
                            <Box className={classes.not_included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="close-homepage" size={15} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Opções adicionais de geração de conteúdo diferenciado em qualquer momento do fluxo  incluindo:
                                    <br/>- Cassosséis de imagens  e/ou videos,  
                                    <br/>- Perguntas simples com resposta binária 
                                    <br/>- Botões parametrizáveis (texto e links externos para websites, reservas, compra de bilhetes, etc)
                                </Box>
                            </Box>
                            {/* <Box className={classes.not_included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="close-homepage" size={15} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Incorporação de Vídeo
                                </Box>
                            </Box>
                            <Box className={classes.not_included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="close-homepage" size={15} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Carrossel de fotografias e/ou vídeo extra, sem georreferenciação
                                </Box>
                            </Box>
                            <Box className={classes.not_included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="close-homepage" size={15} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Links externos para websites, reservas, compra de bilhetes, entre outro
                                </Box>
                            </Box> */}
                        </Box>
                    </Box>
                    <Box className={classes.card}>
                            <Box className={classes.header}>
                                <Typography variant="h1" style={{ fontWeight: 700}}>ADVANCED</Typography>
                                <Box mt={2}>
                                    <Typography variant="h2" color='secondary' style={{ fontWeight: 700}}>80 EUROS + IVA /MÊS</Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography style={{ fontWeight: 700}}>
                                        6 Meses período mínimo 
                                        <br/>
                                        15 dias experimentação grátis
                                        <br/>
                                        Máximo 50 QR CODES.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.text}>
                                <Box className={classes.bold} mb={1}>
                                    Este plano inclui:
                                </Box>
                                <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Geração de fluxos de conversa para um máximo de 50 objetos  
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Fluxo de conversa com 3 grandes áreas de conteúdo. Textos e botões customizáveis 
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Acesso adicional a Carrossel para interligar as várias conversas criadas e/ou Carrossel de fotografias com sugestões de locais georeferenciados perto da sua operação turística, com direções ligadas ao Google Maps 
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Acesso a quadro de estatísticas com as principais métricas das suas conversas
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Opções adicionais de geração de conteúdo diferenciado em qualquer momento do fluxo  incluindo:
                                    <br/>- Cassosséis de imagens  e/ou videos,  
                                    <br/>- Perguntas simples com resposta binária 
                                    <br/>- Botões parametrizáveis (texto e links externos para websites, reservas, compra de bilhetes, etc)
                                </Box>
                            </Box>   
                            {/* <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Incorporação de Vídeo
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Carrossel de fotografias e/ou vídeo extra, sem georreferenciação
                                </Box>
                            </Box>
                            <Box className={classes.included}>
                                <Box style={{ flexShrink: 0}}>
                                    <Icon icon="okay" size={20} color="#4050E1" />
                                </Box>
                                <Box ml={1}>
                                    Links externos para websites, reservas, compra de bilhetes, entre outro
                                </Box>
                            </Box> */}
                            </Box>
                        </Box>
                </Box> 
            </Box>
        </Container>
        </Box>
    </Box>
  )
}