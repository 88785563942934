import React, { useContext, Fragment, useState, useEffect } from "react";
import { useToasts } from 'react-toast-notifications';
import {
  Card,
  CardMedia,
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';

import ActionView from '../../ActionView';
import { ServicesContext } from '../../../../../context/services';
import ModalEditConversationCard from "./ModalEditConversationCard";
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 380,
    padding: '18px'
  },
  media: {
    // height: 193,
    borderRadius: 4,
    paddingTop: '56.25%',
    margin: '0 0px 18px 0px',
  },
  btn: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    borderColor: 'transparent',
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    textTransform: 'none',
    height: '34px',
    width: '100%'
  },
  btnActive: {
    borderRadius: '6px',
    background: theme.palette.grey.light,
    color: theme.palette.primary.dark,
    marginBottom: '10px',
    textTransform: 'none',
    height: '34px',
    width: '100%'
  },
  actionBtn: {
    backgroundColor: '#F3F7FF',
    border: '1px solid #4050E1',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '18px',
    margin: '15px 0',
    padding: '8px 12px',
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      top: '-10px',
      height: '5px',
    },
    '&::after': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      bottom: '-5px',
      height: '5px',
    },
  }
}));

const ConversationCard = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const [action, setAction] = useState(null);
  const { services } = useContext(ServicesContext);

  useEffect(() => {
    if (action && data.buttons.length) {
      const updateAction = data.buttons.find(button => button.id === action.id);
      setAction(updateAction);
    }
  }, [data]);

  const onOptionSelect = button => {
    setAction(button);
  }

  const parseButtons = buttons => {
    let arr = '';
    for (var i = 0; i < buttons.length; i++) {
      arr += `{ id: "${buttons[i].id}" }`;
    }
    return arr;
  }

  const handlerSave = () => {
    const buttonsIDs = parseButtons(data.buttons);
    let serviceData = `{ buttons: { disconnectAll: true, connect: [ ${buttonsIDs} ] }}`;
    if (!data.id) {
      services.responsesApi.create(serviceData).then(({ createResponse }) => {
        setData(createResponse);
      });
    } else {
      services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
        setData(updateResponse);
      });
    }
  }

  const handlerRemove = () => {
    props.onRemoveResponse(data.id);
  }

  const handleChange = e => {
    if (e.type === 'buttons') {
      setData({
        ...data,
        [e.type]: e.data
      });
    } else {
      setData({
        ...data,
        [e.target.id]: e.target.value
      });
    }
  }

  const sortByOrder = array => {
    const sorted = array.sort(function(a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box mr={1}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image={props.conversation.image ? props.conversation.image.publicUrl : '/images/templates/frameBig.svg'}
              title="image"
            />
            <Box>
              <Typography variant='inherit' style={{ fontWeight: 700 }} >
                {props.conversation ? props.conversation.name : ''}
              </Typography>
            </Box>
            <Box mt={2}>
              {
                data && data.buttons ? sortByOrder(data.buttons).map((button) => (
                  <>
                    {
                      !button.disabled ?
                        <Box key={button.id}>
                          {
                            button.type === "payload" && button.payload && button.payload.id === action ?
                              <Button variant="outlined" color="primary" className={classes.btnActive} onClick={() => onOptionSelect(button)}>{button.title_pt}</Button>
                              :
                              <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onOptionSelect(button)}>{button.title_pt}</Button>
                          }
                        </Box>
                        : null
                    }
                  </>
                )) : null
              }
            </Box>
          </Card>
        </Box>
        <ModalEditConversationCard data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} open={data && data.text_pt === '' ? true : false} show_english={props.show_english} />
      </Box>
      {action ?
        <Fragment>
          <Box textAlign="center" style={{ maxWidth: '380px' }}>
            {
              action.type === "web_url" ?
                <a href={action.url} target="_blank" style={{ textDecoration: 'none' }}>
                  <Box className={classes.actionBtn}>
                    <div style={{maxWdith: '70%', width: '100%'}}>{action.url}</div>
                    <LinkIcon fontSize="small" style={{marginLeft: '4px'}}/>
                  </Box>
                </a>
                :
                <Fragment>
                  <Box className={classes.actionBtn}>
                    {action.title_pt}
                  </Box>
                  <Box>
                    <ActionView actionId={action.payload.id} depth={props.depth + 1} card={data} show_english={props.show_english} />
                  </Box>
                </Fragment>
            }
          </Box>
        </Fragment>
        : null}
    </>
  )
}

export default ConversationCard;

