

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useNavigation } from 'core/services/navigation';
import { useAuth } from 'core/services/auth';

import { Box, Modal } from '@material-ui/core';

import Signin from './Signin/';
import SignUp from './Signup';

import Recover from './Recover';
import ChangePassword from './ChangePassword';

import TrialOver from './TrialOver';
import Video from './Video';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            outline: 'none'
        }
    }
}));

const ModalView = () => {
    const classes = useStyles();
    const location = useLocation();
    const navigation = useNavigation();
    const { user } = useAuth();

    const [modal, setModal] = useState(null);

    const handleClose = () => {
        navigation.closeModal();
    };

    // Effects
    useEffect(() => {
        if (location.search) {
            // We do have a query string so open modal accordingly
            const { id } = queryString.parse(location.search);
            setModal(id);
        } else {
            // No search is present in the location
            if (modal) {
                // If a modal is open close it
                setModal(null);
            }
        }
    }, [location]);

    const renderModal = () => {
        switch (modal) {
            case 'signin':
                return <Signin />
            case 'signup':
                return <SignUp />
            case 'recover-password':
                return <Recover />
            case 'change-password':
                return <ChangePassword />;
            case 'trial':
                return <TrialOver />;
            case 'video':
                return <Video />
            default:
                return <Signin />
        }
    }

    return (
        <Modal className={classes.modal} open={!!modal} onClose={handleClose}>
            <Box>{renderModal()}</Box>
        </Modal>
    );
}

export default ModalView;