import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  root: props => ({
    background: '#fff',
    borderRadius: '20px',
    //color: '#4050E1',
    color: props.theme_color ? props.theme_color : '#4050E1',
    cursor: 'pointer',
    display: 'block',
    fontWeight: 500,
    margin: '5px 0',
    padding: '10px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all 150ms',
    '&:hover': {
      background: props.theme_color ? props.theme_color : '#4050E1',
      color: '#fff'
    }
  })
}));

function Button(props) {
  const classes = useStyles(props);

  const checkUrl = url =>{
    if(!/(http(s?)):\/\//i.test(url)) return 'http://'+url;
    else return url;
  }

  const parseHref = url => {
    
    if (url && validator.isEmail(url)) return 'mailto:' + url;
    else if (url && validator.isMobilePhone(url)) return 'tel:' + url;
    else return `/t?url=${checkUrl(url)}&cid=${props.conversation}`;
  }

  return (
    props.button && props.button.type === "web_url" ?
      (props.lang === "en" && props.button.url_en && props.button.url_en !== "null" ?
        <a className={classes.root} {...props} href={parseHref(props.button.url_en)} target="_blank">
          {props.children}
        </a>
        : (props.lang === "pt" && props.button.url && props.button.url !== "null" ?
          <a className={classes.root} {...props} href={parseHref(props.button.url)} target="_blank">
            {props.children}
          </a>
          : null))
      :
      <div className={classes.root} {...props}>
        {props.children}
      </div>
  )
}

export default Button;