import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';

import {
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
  },
  item: {
    fontSize:'18px',
    fontWeight: 500,
    margin: '0 auto 24px auto',
    maxWidth: 340,
    '& span, & svg': {
      display:'block',
      marginTop:'24px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0'
    }
  },
}));

const FaqsContent = ({ question, answer }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemText
          disableTypography
          className={classes.item}
          variant="h1"
          primary={ question }
          secondary={
            <Box id={ question }>
              <Typography
                component="span"
                variant="body2"
                color="textPrimary"
                align="justify"
              >
                <div
                dangerouslySetInnerHTML={{
                  __html: answer
                }}></div>

              </Typography>
            </Box>  
          }
        />
      </ListItem>
    </List>
 
  )
}


export default FaqsContent;
