export default {
  fontFamily: [
    'Heebo',
    'Arial',
  ].join(','),
  h1: {
    fontWeight: 500,
    fontSize: 38,
    lineHeight: '48px',
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '28px',
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '14px',
    letterSpacing: '-0.05px'
  },
  p: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.06px'
  },
  overline: {
    fontWeight: 'normal'
  }
};
