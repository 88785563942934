import React from 'react';

import {
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';

import { useAuth } from 'core/services/auth';

const useStyles = makeStyles(theme => ({
    text: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom:'6px',
      color: theme.palette.primary.dark,
    },
}));

const AddButton = props => {
    const classes = useStyles();
    const { user } = useAuth();

    return (
        user.plan.links && props.buttonsLength < props.limit ?
            <Box mt={2} >
                <Box display='flex' alignItems='center' style={{ width: '100%', cursor: 'pointer' }} onClick={props.onAddNewButton} >
                    <Icon icon="add" size={25} color="#4050E1" />
                    <Typography
                        className={classes.text}
                        align="justify"
                        style={{ marginLeft: '10px', marginBottom: '0px' }}
                    >
                        Adicionar botão{props.buttonsLength === 0 ? '*' : ''}
                    </Typography>
                </Box>
                {props.buttonsLength === 0 ? <Box mt={1}><Typography className={classes.text}>*Obrigatório adicionar pelo menos um botão.</Typography></Box> : null}
            </Box>
        : <Box><Typography className={classes.text}>Não é permitido adicionar mais botões.</Typography></Box>
    );
}

export default AddButton;