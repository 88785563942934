import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  Box,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    fontWeight: 500,
    fontSize: '18px',
    marginTop:'0px'
  },
  btn: {
    width:'100px',
    height:'34px',
    borderRadius: '34px',
    marginTop:'8px'
  },
}));


const TutorialItem = ({ data, onSelected }) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters >
      <ListItemText disableTypography className={classes.content} primary={ data.title } secondary={
          <Box display="flex" flexDirection='column' alignItems="flex-start" style={{ marginTop:'8px' }}>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              align="justify"
            >
              { data.description }
            </Typography>
            <Button className={classes.btn} variant="outlined" color="primary" onClick={()=> onSelected(data)}>
              Ver video
            </Button>
          </Box>
        } />
    </ListItem>
          
  );
}

export default TutorialItem;
