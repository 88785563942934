import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Icon from "./../../components/Icon";
import { useAuth } from 'core/services/auth';
import { useToasts } from 'react-toast-notifications';

import {
  Link,
  makeStyles,
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';


function ListItemLink(props) {
  const { icon, primary, to } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} >
        {icon ? <ListItemIcon className={classes.back}>{icon}</ListItemIcon> : null}
        <ListItemText disableTypography className={classes.item} primary={primary} />
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '22px',
  },
  content: {
    marginTop:'5px',
  },
  back: {
    fontWeight: 500,
    fontSize: '22px',
    '& > svg': {
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft:'26px',
    }
  },
  item: {
    fontSize: '22px',
    marginLeft: '-16px',
    '& > svg': {
      color: theme.palette.primary.dark,
    }
  },
  btnInvite: {
    height:'34px',
    width: '140px',
    borderRadius: '34px',
    marginTop:'16px',
  },
  btn: {
    height:'34px',
    width: '160px',
    borderRadius: '6px',
    marginTop:'16px',
    marginLeft: '6px',
    textTransform: 'none'
  },
  avatar: {
    width:'108px',
    height:'108px',
    marginBottom: '32px',
  },
  fields: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  inputs: {
    width:'320px',
    height:'42px', 
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    marginTop: theme.spacing(1),
    padding:'12px', 
    fontFamily: 'Heebo',
    outline: 'none',
    '&:disabled':{
      opacity: 0.6
    }
  },
  inputsGuests: {
    width:'320px',
    height:'42px', 
    background: 'none',
    borderRadius: '6px;',
    border: '1px solid #3A3A3A',
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    padding:'12px',
    fontFamily: 'Heebo',
    outline: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const SettingsSidebar = ({ page }) => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const { user, signout, update } = useAuth();
  
  const [currentUser, setUser] = useState({
    name: user ? user.name : null,
    email:  user ? user.email : null,
    plan:  user && user.plan ? user.plan : '',
  });

  const handleChange = e => {
    setUser({
      ...currentUser,
      [e.target.id]: e.target.value
    });
  };

  const handleSignOut = async e => {
		e.preventDefault();
		await signout();
  };


  const handleSave = async e => {
		e.preventDefault();
		await update({id: user.id, data: { name : currentUser.name } });
    addToast('Alterações gravadas.', { appearance: 'success', autoDismiss: true });
  };


  return (
    <Grid container
      spacing={2}
      className={classes.root}
      >
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} className={classes.content}>
          <List>
            <ListItemLink disableTypography to="/backoffice/" primary={ page } icon={<Icon icon="back" size={25} color="#4050E1" />} />
          </List>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
        <Box display='flex' flexDirection='column' alignItems='center' >
          <Box>
            <Avatar to="/" alt={currentUser.name} src="/images/avatar/1.jpg" className={classes.avatar} />
          </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
            <label htmlFor="name" className={classes.fields}>Nome</label>
            <input
              className={classes.inputs}
              type="text"
              id="name"
              value={currentUser.name}
              onChange={handleChange}
            />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3}>
              <label htmlFor="email" className={classes.fields}>Email</label>
              <input
                className={classes.inputs}
                type="email"
                id="email"
                value={currentUser.email}
                onChange={handleChange}
                disabled
              />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={3} >
              <label htmlFor="type" className={classes.fields}>Plano</label>
              <input
                className={classes.inputs}
                type="text"
                id="type"
                value={currentUser.plan.name}
                onChange={handleChange}
                disabled
              />
            </Box>
          <Box display='flex' alignContent='space-evenly' p={2}>
            <Box>
                <Button 
                  className={classes.btn} 
                  variant="contained"
                  color="primary" 
                  type="submit" 
                  onClick={handleSave}>
                  Guardar Alterações
                </Button>
            </Box>
            <Box >
                <Button 
                    className={classes.btn} 
                    variant="outlined"  
                    color="primary"  
                    type="reset" 
                    onClick={handleSignOut}>
                    Terminar Sessão
                </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SettingsSidebar;
