import React from 'react';

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    frame: {
        backgroundColor: '#000',
        width: 640,
        height: 360,
        [theme.breakpoints.down('sm')]: {
            width: 320,
            height: 'auto'
        }
    }
}));

const Video = props => {
    const classes = useStyles();

    return (
        <iframe className={classes.frame} src="https://player.vimeo.com/video/534560055?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Fachadas da Covilh&amp;atilde; exibem obras de arte."></iframe>
    );
}

export default Video;