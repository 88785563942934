import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import {
  makeStyles,
  IconButton,
  Modal,
  Backdrop,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';
import Icon from '../../../../components/Icon';
import ModalRemove from '../../shared/ModalRemove';

import { Picker } from 'emoji-mart';
import TextareaEmoji from '../../../../components/TextareaEmoji';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 384,
    background: '#FFFFFF',
    borderRadius: '6px',
    padding: 32,
    outline: 'none',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  input: {
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    fontFamily: 'Heebo, Arial',
    marginTop: 10,
    textAlign: 'left',
    padding: '10px 12px',
    minWidth: '320px',
    minHeight: 60,
    width: '100%'
  },
}));

const ModalEditMessage = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const { addToast } = useToasts();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onSave();
    handleClose();
  }

  useEffect(() => {
    setValues({
      id: props.data ? props.data.id : '',
      text_pt: props.data ? props.data.text_pt : '',
      text_en: props.data ? props.data.text_en : '',
    });
  }, [props.data]);

  const [values, setValues] = useState({
    id: props.data ? props.data.id : '',
    text_pt: props.data ? props.data.text_pt : '',
    text_en: props.data ? props.data.text_en : '',
  });

  const handleChange = e => {
    props.onChange(e);
    //needed to fix OSX special characters
    setValues({
      ...values,
      [e.target.id]: e.target.value
    });

    //needed to fix OSX special characters
    setValues({
      ...values,
      [e.target.id]: e.target.value
    });
  };

  const onConfirmRemove = () => {
    props.onRemove();
    //handleClose();
  }

  const validate = () => {
    if (!values.text_pt) addToast('Texto em português em falta', { appearance: 'error', autoDismiss: true });
    else if (props.show_english && !values.text_en) addToast('Texto em inglês em falta', { appearance: 'error', autoDismiss: true });
    else handleSave();
  }

  const onBackdropClick = e => {
    if (values.text_pt && values.text_en) handleClose();
    else validate()
  }

  const can_delete = props.data && props.data.can_delete === true || props.data.can_delete === null;



  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Icon icon="edit" size={25} color="#4050E1" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        onBackdropClick={onBackdropClick}
        disableBackdropClick={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={open} timeout={500} >
          <Box className={classes.paper}>
            <Typography
              className={classes.title}
              variant="h2"
            >
              {props.data && props.data.id === null ? 'Criar Texto Simples' : 'Editar Texto Simples'}
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="h4"
            >
              Escreva um texto informativo ou descritivo, para começar a Conversa. Não se esqueça de Gravar, no final.
            </Typography>
            <Box >
              <form>
                <TextareaEmoji
                  className={classes.input}
                  type="text"
                  id="text_pt"
                  value={values.text_pt}
                  onChange={handleChange}
                  placeholder="Texto em português"
                  maxLength="280"
                />
                {
                  props.show_english ?
                    <TextareaEmoji
                      className={classes.input}
                      type="text"
                      id="text_en"
                      value={values.text_en}
                      onChange={handleChange}
                      placeholder="Texto em inglês"
                      maxLength="280"
                    />
                    : null
                }
                {/* <textarea
                      className={classes.input}
                      type="text"
                      id="text_pt"
                      value={values.text_pt}
                      onChange={handleChange}
                      placeholder="Texto em português"
                      maxLength="280"
                  />
                  <textarea
                      className={classes.input}
                      type="text"
                      id="text_en"
                      value={values.text_en}
                      onChange={handleChange}
                      placeholder="Texto em inglês"
                      maxLength="280"
                      style={{display: props.show_english ? 'inline-block' : 'none' }}
                  /> */}
              </form>
            </Box>
            <Box mt={1}>
              <Typography variant="p" style={{ fontSize: '12px' }}>Limite máximo de 280 carateres.</Typography>
            </Box>
            <Box display='flex' justifyContent={can_delete ? 'space-between' : 'center'} style={{ width: '100%' }} mt={2}>
              <Button variant="contained" color="primary" style={{ borderRadius: '34px', textTransform: 'none', marginRight: 8, minHeight: '28px' }} onClick={validate}>
                {props.data && props.data.id !== null ? 'Gravar' : 'Criar'}
              </Button>
              {can_delete ? <ModalRemove type="button" onConfirmRemove={onConfirmRemove} /> : null}
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
}

export default ModalEditMessage;