import { Box, Button, CardMedia, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServicesContext } from '../../../../../context/services';
import { useToasts } from 'react-toast-notifications';
import Icon from '../../../../components/Icon';
import ModalRemove from '../ModalRemove';
import SimpleButton from '../SimpleButton';
import AddButton from '../SimpleButton/AddButton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
    formFields: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '6px',
        color: theme.palette.primary.dark,
    },
    input: {
        width: '100%',
        background: '#fff',
        border: '1px solid #DBDDF1',
        boxSizing: 'border-box',
        borderRadius: '6px',
        fontWeight: 400,
        textAlign: 'left',
        padding: '10px 12px',
    },
    btn: {
        display: 'inline-block',
        fontSize: 12,
        height: 34,
        borderRadius: '34px',
        textTransform: 'none',
        marginRight: 8,
        padding: '8px 16px',
    },
    formUpload: {
        display: 'none',
    },
    btnUpload: {
        width: '97px',
        height: '34px',
        borderRadius: '34px',
        marginTop: '16px',
        textTransform: 'none',
    },
}));

const ElementTab = props => {
    const classes = useStyles();
    const [element, setElement] = useState(props.data);
    const { services } = useContext(ServicesContext);
    const { addToast } = useToasts();
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        setElement(props.data);
        if (props.data.image && props.data.image.publicUrl) setImage(props.data.image.publicUrl);
    }, [props.data]);

    useEffect(() => {
        if(props.saveAll) validate();
    }, [props.saveAll]);

    useEffect(() => {
        props.onUpdatedElement(element)
    }, [element]);

    const handleChange = e => {
        setElement({
            ...element,
            [e.target.id]: e.target.value
        });
    };

    const parseButtons = buttons => {
        let arr = '';
        for (var i = 0; i < buttons.length; i++) {
            arr += `{ id: "${buttons[i].id}" }`;
        }
        return arr;
    }

    const validateButtons = () => {
        let isValid = false;
        for (let i = 0; i < element.buttons.length; i++) {
            const button = element.buttons[i];
            if(!button.disabled) isValid = true;
        }
        return isValid;
    }

    const validate = () => {
        props.onUpdatedElement('validate');
        
        if (!element.title_pt) addToast('Título em português em falta', { appearance: 'error', autoDismiss: true });
        else if (props.show_english && !element.title_en) addToast('Título em inglês em falta', { appearance: 'error', autoDismiss: true });
        else if(element.buttons.length <= 0) addToast('Adicione pelo menos um botão', { appearance: 'error', autoDismiss: true });
        else if(!validateButtons()) addToast('Tem de ter pelo menos um botão activo', { appearance: 'error', autoDismiss: true });
        else handleSave();
    }

    const handleSave = () => {
        const buttonsIDs = parseButtons(element.buttons);
        const serviceData = `{ 
            title_pt: "${element.title_pt.replace(/"/g, '\\"')}", 
            title_en: "${element.title_en ? element.title_en.replace(/"/g, '\\"') : ''}", 
            subtitle_pt: "${element.subtitle_pt ? element.subtitle_pt.replace(/"/g, '\\"') : ''}", 
            subtitle_en: "${element.subtitle_en ? element.subtitle_en.replace(/"/g, '\\"') : ''}", 
            buttons: { disconnectAll: true, connect: [ ${buttonsIDs} ] } 
            ${file ? ', image: $image' : ''} 
        }`;
        if (element.id && !file) {
            services.elementsApi.update(element.id, serviceData).then(({ updateElement }) => {
                setElement(updateElement);
                props.onSaved(updateElement);
            });
        } else {
            services.elementsApi.updateAndUpload(element.id, serviceData, file.value).then(({ updateElement }) => {
                setElement(updateElement);
                props.onSaved(updateElement);
            });
        }
    }

    const handleRemove = () => {
        props.onRemoveTab(element.id);
    }

    const addToElement = button => {
        let newButtons = element.buttons ? element.buttons : [];
        newButtons.push(button);
        setElement({
            ...element,
            ['buttons']: newButtons
        });
    }

    const onAddNewButton = e => {
        var data = { type: 'web_url', title_pt: '', title_en: '' };
        services.buttonsApi.create(data).then(({ createButton }) => {
            // console.log('onAddNewButton', createButton)
            if (createButton) addToElement(createButton);
        });
    }

    const updateButtonInArray = (buttons, newButton) => {
        return buttons.map(button => {
            if (button.id !== newButton.id) {
                return button;
            }
            return newButton;
        });
    }

    const onUpdatedButton = button => {
        const newButtons = updateButtonInArray(element.buttons, button);
        setElement({
            ...element,
            ['buttons']: newButtons
        });

        //props.onUpdatedElement(element);
        //props.onChange({type: 'options', data: newOptions });
    };

    const onRemoveButton = id => {
        const newButtons = element.buttons.filter(function (button) { return button.id !== id });
        setElement({
            ...element,
            ['buttons']: newButtons
        });
        //props.onChange({type: 'options', data: newOptions });
    }

    const onFileChanged = (event) => {
        const { files } = event.target;
        const file = files[0];

        //onChange({ name: 'file', value: file });
        setFile({ name: 'file', value: file });

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            setImage(event.target.result);
        });
        reader.readAsDataURL(file);
    }

    const hiddenFileInput = useRef(null);

    const onFileUpload = event => {
        hiddenFileInput.current.click();
    }

    
    const onDragEnd = result => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const newButtons = Array.from(element.buttons);
        const [removed] = newButtons.splice(result.source.index, 1);
        newButtons.splice(result.destination.index, 0, removed);
        for (let i = 0; i < newButtons.length; i++) {
            const tab = newButtons[i];
            tab.order = i;
            updateIndex(tab);
        }
        let newElement = element;
        newElement.buttons = newButtons;
        setElement(newElement);
    };

    const updateIndex = (button) => {
        const serviceData = `{ 
            order: ${button.order}
        }`;

        services.buttonsApi.update(button.id, serviceData).then(({ updateButton }) => {
            console.log('updateButton', updateButton)
        });
    }

    const sortByOrder = array => {
        const sorted = array.sort(function(a, b) {
          return parseFloat(a.order) - parseFloat(b.order);
        });
        return sorted;
    }

    const show_english = props.show_english;

    return (
        <Box>
            <Box>
                <Box mt={0}>
                    <Typography className={classes.formFields} align="justify"> Título em português </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_pt"
                        value={element.title_pt}
                        onChange={handleChange}
                        placeholder="Título (em português)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                    <Typography className={classes.formFields} align="justify"> Título em inglês </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="title_en"
                        value={element.title_en}
                        onChange={handleChange}
                        placeholder="Título (em inglês)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2}>
                    <Typography className={classes.formFields} align="justify">Subtítulo em português </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="subtitle_pt"
                        value={element.subtitle_pt}
                        onChange={handleChange}
                        placeholder="Subítulo (em português)"
                        maxLength="80"
                    />
                </Box>
                <Box mt={2} style={{ display: props.show_english ? 'block' : 'none' }}>
                    <Typography className={classes.formFields} align="justify">Subítulo em inglês </Typography>
                    <input
                        className={classes.input}
                        type="text"
                        id="subtitle_en"
                        value={element.subtitle_en}
                        onChange={handleChange}
                        placeholder="Subítulo (em inglês)"
                        maxLength="80"
                    />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2} mb={3}>
                <Typography className={classes.formFields} align="justify"> Foto </Typography>
                <input
                    accept="image/x-png,image/gif,image/jpeg"
                    className={classes.formUpload}
                    name="contained-button-file"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onFileChanged}
                />
                <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                    {/* <CardMedia
                        alt="image"
                        component="img"
                        image={image ? image : '/images/templates/frame.svg'}
                    /> */}
                    <CardMedia
                        style={{ paddingTop: '56.25%', borderRadius: '6px' }}
                        image={image ? image : '/images/templates/frame.svg'}
                        title="image"
                    />
                    <Button
                        variant="contained"
                        className={classes.btnUpload}
                        color="primary"
                        type="submit"
                        onClick={onFileUpload}
                    >
                        Upload Foto
                </Button>
                </label>
            </Box>
            <Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="buttons">
                {props => (
                    <div 
                        ref={props.innerRef}
                        {...props.droppableProps}
                    >
                    {
                        element.buttons ? sortByOrder(element.buttons).map((button, index) => (
                            <Draggable
                                key={button.id}
                                draggableId={`id-${button.id}`} // must be a string
                                index={index}
                                disableInteractiveElementBlocking={true}
                            >
                            {props => (
                                <Box 
                                    ref={props.innerRef}
                                    {...props.draggableProps}
                                    {...props.dragHandleProps}
                                    key={button.id} mb={2}
                                >
                                    <Typography className={classes.formFields} > Botão {index + 1} </Typography>
                                    <SimpleButton data={button} updatedButton={onUpdatedButton} onRemoveButton={onRemoveButton} show_english={show_english} />
                                </Box>
                            )}
                            </Draggable>
                        ))
                        : null
                    }
                    {props.placeholder}
                </div>
                )}
              </Droppable>
            </DragDropContext>
            </Box>
            <AddButton buttonsLength={element.buttons.length} limit={3} onAddNewButton={onAddNewButton} />
            <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }} mt={2} >
                <Button variant="contained" color="primary" className={classes.btn} onClick={props.onSave}>
                    Gravar
                </Button>
                <ModalRemove type="button" onConfirmRemove={handleRemove} label="Remover tab" />
            </Box>
        </Box>
    );
};

export default ElementTab;
