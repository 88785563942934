import React, { useState } from "react";
import { Picker } from 'emoji-mart';

const TextareaEmoji = ({ ...props }) => {
    const [emojis, showEmojis] = useState(false);

    const addEmoji = e => {
        props.onChange({target: { id: props.id, value: props.value + e.native }})
        showEmojis(false);
    };
    
    return (
        <div style={{position: 'relative'}}>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <textarea {...props} />
                <div onClick={() => showEmojis(!emojis)} style={{marginLeft: 5, opacity: 0.3, display: 'flex'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path><path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path></svg>
                </div>
            </div>
            { emojis ? 
            <div style={{position: "absolute", top: '-50%', left: '101%'}}>
                <Picker
                    onSelect={addEmoji}
                    title={false}
                />
            </div> : null }
        </div>
    );
};

export default TextareaEmoji;