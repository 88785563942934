import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import Sidebar from './sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    marginTop: '55px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0px',
      marginLeft: '70px',
    }, 
  },
  contentContainer: {
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'initial'
  }
}));

const MainLayout = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Sidebar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
