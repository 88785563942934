import React from 'react';

import {
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';

const useStyles = makeStyles(theme => ({
    text: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom:'6px',
      color: theme.palette.primary.dark,
    },
}));

const AddOption = props => {
    const classes = useStyles();

    return (
        props.optionsLength < props.limit ?
            <Box mt={2} display='flex' alignItems='center' style={{ width: '100%', cursor: 'pointer' }} onClick={props.onAdd} >
                <Icon icon="add" size={25} color="#4050E1" />
                <Typography
                    className={classes.text}
                    align="justify"
                    style={{ marginLeft: '10px', marginBottom: '0px' }}
                >
                    Adicionar Opções de Resposta (limite de 13 opções)
                </Typography>
            </Box>
        : <Box><Typography className={classes.text}>Não é permitido adicionar mais opções.</Typography></Box>
    );
}

export default AddOption;