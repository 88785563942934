import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, } from '@material-ui/core'
import Option from './ui/Option';
import Slider from './ui/Slider';

const useStyles = makeStyles((theme) => ({

}));

function MessageAreaLoop(props) {
  const classes = useStyles();
  const [message, setMessage] = useState(props.message);

  console.log('aqui')

  return (
    <li className={message.user ? 'user' : 'bot'} style={{ marginBottom: props.showOptions ? '50px' : '2px' }}>
      {
        props.lang === 'en' ? (message.text_en && !message.title_en ? message.text_en : message.title_en)
          : (message.text_pt && !message.title_pt ? message.text_pt : message.title_pt)
      }
      {
        props.showOptions ?
          <div style={{ position: 'fixed', bottom: 5, left: '50%', transform: 'translateX(-50%)', maxWidth: '420px', width: '100%' }}>
            <Slider active={message.options && message.options.length !== 1} theme_color={props.theme_color}>
              {
                message.response.options ? message.response.options.map((option, i) => {
                  return <div key={i} style={{ marginLeft: 5 }}>
                    <Option onClick={() => props.onResponse(option)} theme_color={props.theme_color}>
                      {props.lang === 'en' && option.title_en ? option.title_en : option.title_pt}
                    </Option>
                  </div>
                })
                  : null
              }
            </Slider>
          </div> : null
      }
    </li>
  );
}

export default MessageAreaLoop;