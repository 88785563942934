import React from 'react';

import Icon from "./../../backoffice/components/Icon";

import Carousel, { consts } from 'react-elastic-carousel';
import { Box, Container, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 704,
        marginBottom: 100,
        [theme.breakpoints.down('sm')]: {
            '& > div': {
				padding: 0,
			},
        },
    },
    content: {
        alignItems: 'flex-start',
        display: 'flex',          
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', 
            alignItems: 'center',
        }, 
    },
    title: {
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
    line_text: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        '&::before': {
            background: '#27D9D5',
            bottom: '6px',
            content: '""',
            height: 13,
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1
        }
    },
    info: {
        alignItems: 'flex-start',
        color: theme.palette.primary.dark,
        display: 'inline-flex',
        flexDirection: 'column',
        margin: ' 0px 40px 40px 40px',
        maxWidth: '280px',
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center', 
            margin: ' 40px 40px 0px 40px',
            textAlign: 'center',
            },      
    },
    rich_text: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
    },
    card: {
        alignItems: 'center',
        backgroundColor: '#00E7E4',
        borderRadius: '20px 20px 20px 0px',
        color: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'column',
        height: 360,
        margin: '20px 10px',
        overflow: 'hidden',
        textAlign: 'left',
        width: 300,
    },
    text: {
        alignItems: 'flex-start', 
        display: 'flex' ,
        flexDirection: 'column',
        padding: '40px 20px 0px 20px',
    },
    title_card: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '22px',
        lineHeight: '26px',
        width:'65%'
    },
    rich_text_card: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '23px',
        width:'246px'
    },
    mobile: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
		}
    },
    feed: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '100%',
			'& .rec-slider-container': {
				margin: 0,
			},
            '& .rec-carousel ': {
                '& button': {
                    display: 'none',
                },
			},
        },
        [theme.breakpoints.down('xs')]: {
			'& .rec-carousel-item-prev': {
				position: 'relative',
				right: '-15vw'
			},
			'& .rec-carousel-item-next': {
				position: 'relative',
				left: '-15vw'
			},
		}
    },
}));


export default function Business() {
    const classes = useStyles();

  return (
    <Box id='business' className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box className={classes.info}>                  
                    <Box mb={4}>
                        <Typography className={classes.title}>
                        Vantagens para o meu <span className={classes.line_text}>negócio</span>
                        </Typography>
                    </Box> 
                    <Box mb={4}>
                        <Typography  className={classes.rich_text}>
                        Quanto mais surpreendente for a experiência com os “objectos” do seu negócio, maior é a probabilidade de 
                        ser passada a amigos, ou partilhada nas redes sociais. 
                        <br/> <br/> 
                        Uma boa experiência aproxima, envolve e valoriza a oferta.​
                        Abre pontes para mais notoriedade, mais visitas e mais receitas.
                        <br/> <br/> 
                        Esta plataforma responde às "Trends" mais actuais...
                        </Typography>
                    </Box>

                </Box>
                <Box mb={4} mt={4} className={classes.mobile} >
                    <Box className={classes.card} >
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Icon icon="ballon" size={58} color="#4050E1" />
                                </Box>
                                <Box >
                                    <Typography className={classes.title_card} >
                                        Trend #Controlo
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                    As pessoas querem controlar o seu tempo e a qualidade da interacção. Em programas turísticos e culturais, preferem conteúdos curtos e interessantes, por si controlados, e repudiam densos lençóis de texto.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.card}>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Icon icon="ballon" size={58} color="#4050E1" />
                                </Box>
                                <Box >
                                    <Typography className={classes.title_card} >
                                        Trend #Phygital
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                Assiste-se a uma convergência entre os mundos físico e digital. “Objectos” turísticos interactivos, permitem o acesso a uma maior riqueza de conteúdos e já são considerados standard, em muitas cidades e destinos mundiais.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box mb={4} className={classes.mobile} >
                    <Box className={classes.card}>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Icon icon="ballon" size={58} color="#4050E1" />
                                </Box>
                                <Box >
                                    <Typography className={classes.title_card} >
                                        Trend #Storytelling
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                Uma história bem contada tem um enorme impacto na experiência global. Actua ao nível emocional e da memorização e potencia o word-of-mouth.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.card}>
                        <Box className={classes.text}>
                            <Box display='flex' mb={2}>
                                <Box pr={1}>
                                    <Icon icon="ballon" size={58} color="#4050E1" />
                                </Box>
                                <Box >
                                    <Typography className={classes.title_card} >
                                    Trend #QR Code
                                    </Typography>
                                </Box>
                            </Box>
                            <Box >
                                <Typography  className={classes.rich_text_card}>
                                O QR Code é das tecnologias mais fáceis de usar (leitor integrado nas câmaras dos telemóveis) e está a ser massificado desde o início da pandemia (+47% aumento em UK e US, Set’20).
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box> 
            
                <Box className={classes.feed} mb={4}>
                    <Carousel 
                        showArrows={false}
                        enableMouseSwipe={false}
                        pagination={false}
                        itemsToShow={3} 
                        breakPoints={ [{ width: 1, itemsToShow: 1 },
                        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
                        { width: 767, itemsToShow: 2, itemsToScroll: 2 },
                        { width: 800, itemsToShow: 3, itemsToScroll: 3 }, 
                        { width: 1440, itemsToShow: 3, itemsToScroll: 3 },]}
                    >
                        <Box className={classes.card} >
                            <Box className={classes.text}>
                                <Box display='flex' mb={2}>
                                    <Box pr={1}>
                                        <Icon icon="ballon" size={58} color="#4050E1" />
                                    </Box>
                                    <Box >
                                        <Typography className={classes.title_card} >
                                            Trend #Controlo
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography  className={classes.rich_text_card}>
                                        As pessoas querem controlar o seu tempo e a qualidade da interacção. Em programas turísticos e culturais, preferem conteúdos curtos e interessantes, por si controlados, e repudiam densos lençóis de texto.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.card}>
                            <Box className={classes.text}>
                                <Box display='flex' mb={2}>
                                    <Box pr={1}>
                                        <Icon icon="ballon" size={58} color="#4050E1" />
                                    </Box>
                                    <Box >
                                        <Typography className={classes.title_card} >
                                            Trend #Phygital
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography  className={classes.rich_text_card}>
                                    Assiste-se a uma convergência entre os mundos físico e digital. “Objectos” turísticos interactivos, permitem o acesso a uma maior riqueza de conteúdos e já são considerados standard, em muitas cidades e destinos mundiais.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.card}>
                            <Box className={classes.text}>
                                <Box display='flex' mb={2}>
                                    <Box pr={1}>
                                        <Icon icon="ballon" size={58} color="#4050E1" />
                                    </Box>
                                    <Box >
                                        <Typography className={classes.title_card} >
                                            Trend #Storytelling
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography  className={classes.rich_text_card}>
                                    Uma história bem contada tem um enorme impacto na experiência global. Actua ao nível emocional e da memorização e potencia o word-of-mouth.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.card}>
                            <Box className={classes.text}>
                                <Box display='flex' mb={2}>
                                    <Box pr={1}>
                                        <Icon icon="ballon" size={58} color="#4050E1" />
                                    </Box>
                                    <Box >
                                        <Typography className={classes.title_card} >
                                        Trend #QR Code
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography  className={classes.rich_text_card}>
                                    O QR Code é das tecnologias mais fáceis de usar (leitor integrado nas câmaras dos telemóveis) e está a ser massificado desde o início da pandemia (+47% aumento em UK e US, Set’20).
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Carousel>
                </Box> 
            </Box>
        </Container>
    </Box>
  )
}