import React from 'react';

import Footer from './sections/Footer'
import Header from './sections/Header'
import What from './sections/What'
import Business from './sections/Business'
import How from './sections/How'
import Opportunities from './sections/Opportunities'
import Stats from './sections/Stats'
import Projects from './sections/Projects'
import About from './sections/About'
import TalkToUs from './sections/TalkToUs'
import Pricing from './sections/Pricing'

import { Box, makeStyles,  } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  floating_green: {
      backgroundImage: 'url(./../images/various/floatingShapeGreen.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      height: 1282,
      minWidth: 711,
      left: 0,
      top: 606,
      zIndex:'1',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
  },
  floating_blue: {
    backgroundImage: 'url(./../images/various/floatingShapeBlue.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    height: 1460,
    minWidth: 442,
    right: 0,
/*     top: 2600, */
    top: 1936,
    zIndex:'-1',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
  },
},
}));

function Index() {
  const classes = useStyles();

  return (
    <div className="App">
      <Header />  
      <main>
          <Box > 
            <Box className={classes.floating_green}></Box>
            <Box className={classes.floating_blue}></Box>
          </Box>
          <What />
          <Business />
          <How />
          <Opportunities />
          <Stats /> 
          <Projects /> 
          <Pricing /> 
          <About /> 
          <TalkToUs />
      </main>
      <Footer /> 
    </div>
  );
}

export default Index;