import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import {
  makeStyles,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';
import ModalRemoveOption from './ModalRemoveOption';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  text: {
    padding: '18px 18px 10px 18px',
    border: '1px solid #DBDDF1',
    borderRadius: '6px',
    fontWeight: 'normal',
    textAlign: 'justify',
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
    color: theme.palette.primary.dark,
  },
  formInputsButtons: {
    fontSize: '14px',
    width: 282,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  btn: {
    fontSize: 12,
    width: 80,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  box: {
    margin: '5px 0',
    width: '100%',
    height: 67,
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    textAlign: 'left',
    padding:'23px 14px 23px 14px',
  },
}));

const ModalButton = props => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [open, setOpen] = useState(props.open);
  const [modalRemove, setModalRemove] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onSave();
    handleClose();
  }
  const handleRemove = () => {
    setModalRemove(true);
  }

  useEffect(() => {
    setValues({
      id: props.data ? props.data.id : '',
      title_pt: props.data ? props.data.title_pt : '',
      title_en: props.data ? props.data.title_en : '',
    });
  }, [props.data]);

  const [values, setValues] = useState({
    id: props.data ? props.data.id : '',
    title_pt: props.data ? props.data.title_pt : '',
    title_en: props.data ? props.data.title_en : '',
  });

  const handleChange = e => {
    props.onChange(e);
    //needed to fix OSX special characters
    setValues({
      ...values,
      [e.target.id]: e.target.value
    });
  };

  const onConfirmRemoveButton = () => {
    props.onRemove();
    handleClose();
  }

  const validate = () => {
    if(!values.title_pt) addToast('Texto em português em falta', { appearance: 'error', autoDismiss: true });
    else if(props.show_english && !values.title_en) addToast('Texto em inglês em falta', { appearance: 'error', autoDismiss: true });
    else handleSave();
  }

  return (
    <Box>
      <Box>
        <IconButton onClick={handleOpen}>
          <Icon icon="edit" size={25} color="#4050E1" />
        </IconButton>
        <ModalRemoveOption onConfirmRemoveButton={onConfirmRemoveButton} open={modalRemove}/>
      </Box>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        onBackdropClick={validate}
        disableBackdropClick={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="scroll-dialog-title" style={{textAlign: 'center'}}>
          <Typography
            className={classes.title}
          >
            Adicionar Opções de Resposta
          </Typography>
          <Typography
            className={classes.subtitle}
          >
            Escreva o nome das Opções de Resposta, uma a uma.​
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box className={classes.paper}>
            <form>
                <input
                    className={classes.box}
                    type="text"
                    id="title_pt"
                    value={values.title_pt}
                    onChange={handleChange}
                    placeholder="Label (em português)"
                    maxLength="20"
                />
                <input
                    className={classes.box}
                    type="text"
                    id="title_en"
                    value={values.title_en}
                    onChange={handleChange}
                    placeholder="Label (em inglês)"
                    maxLength="20"
                    style={{display: props.show_english ? 'inline-block' : 'none' }}
                />
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }}  >
            <Button variant="contained" color="primary" className={classes.btn} onClick={validate}>
              Gravar
            </Button>
            <Button variant="outlined" color="primary" className={classes.btn} onClick={handleRemove}>
              Remover
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ModalButton;