import React, { useContext, useEffect, useState } from 'react';

import { ServicesContext } from '../../../context/services';

import ConversationCard from './responses/ConversationCard';
import TemplateGeneric from './responses/TemplateGeneric';
import QuestionOptions from './responses/QuestionOptions';
import QuestionButtons from './responses/QuestionButtons';
import LoopbackOptions from './responses/LoopbackOptions';
import TemplateImages from './responses/TemplateImages';
import AreaOptions from './responses/AreaOptions';
import SimpleText from './responses/SimpleText';

import ModalAdd from './ModalAdd';

import {
  Grid,
  Box,
} from '@material-ui/core';


const ActionView = props => {
  const [action, setAction] = useState(null);
  const { services } = useContext(ServicesContext);

  // on mount
  useEffect(() => {
    if (props.actionId) {
      services.actionsApi.getById(props.actionId).then(({ Action }) => {
        if (Action.id) setAction({ ...Action });
      });
    }
  }, [props.actionId, props.rand]);

  const onUpdateResponse = response => {
    // console.log('onUpdateResponse', response)
  }

  const onRemoveResponse = id => {
    if (id) {
      services.responsesApi.delete(id).then(({ deleteResponse }) => {
        if (deleteResponse.id === id) {
          action.responses = action.responses.filter(function (res) { return res.id !== deleteResponse.id });
          services.actionsApi.updateResponses(action).then(({ updateAction }) => {
            if (updateAction) setAction({ ...updateAction });
          });
        }
      });
    }
  }

  const addResponse = (type, index) => {
    switch (type) {
      case 'text':
        var data = { type: 'text', text_pt: '', text_en: '', order: index };
        services.responsesApi.createText(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'options':
        var data = { type: 'options', text_pt: '', text_en: '', order: index };
        services.responsesApi.createText(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'buttons':
        var data = { type: 'buttons', text_pt: '', text_en: '', order: index };
        services.responsesApi.createText(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'template_generic':
        var data = `{ type: template, order: ${index} }`;
        services.responsesApi.create(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'template_places':
        var data = `{ type: template_places, order: ${index} }`;
        services.responsesApi.create(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'template_images':
        var data = `{ type: template_images, order: ${index} }`;
        services.responsesApi.create(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'loopback':
        var data = `{ 
          type: loopback,
          text_pt: "O que queres saber mais?",
          text_en: "What else would you like to know?",
          response: { connect: { id: "${props.card.id}" } }, 
          order: ${index} 
        }`;

        services.responsesApi.create(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      case 'closearea':
        var data = `{ 
          type: closearea,
          text_pt: "${props.area.text_pt}",
          text_en: "${props.area.text_en}",
          response: { connect: { id: "${props.area.id}" } },
          order: ${index}
        }`;

        services.responsesApi.create(data).then(({ createResponse }) => {
          if (createResponse) addToAction(createResponse, index);
        });
        break;
      default:
        break;
    }
  }

  const addToAction = (response, index) => {
    action.responses.splice(index, 0, response); //add response to correct position
    let responses = ''; //update response order positions
    for (let i = 0; i < action.responses.length; i++) {
      if (responses.length) responses += ',';
      responses += `{ id: "${action.responses[i].id}", data: { order: ${i} } }`;
    }
    //save new response order positions
    services.responsesApi.updateMany(responses).then(({ updateResponses }) => {
      if (updateResponses) {
        //save new response to action
        services.actionsApi.updateResponses(action).then(({ updateAction }) => {
          if (updateAction) setAction({ ...updateAction });
        });
      } else console.log("Error updating the Item");
    }).catch(error => console.log(error));
  }

  const renderResponse = response => {
    switch (response.type) {
      case 'text':
        return <SimpleText data={response} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'options':
        return <QuestionOptions data={response} depth={props.depth} card={props.card} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'template':
        return <TemplateGeneric data={response} depth={props.depth} card={props.card} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'buttons':
        return <QuestionButtons data={response} depth={props.depth} card={props.card} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'card':
        return <ConversationCard data={response} depth={props.depth} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} conversation={props.conversation} />
      case 'loopback':
        return <LoopbackOptions data={response} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'closearea':
        return <AreaOptions data={response} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'template_places':
        return <TemplateGeneric data={response} depth={props.depth} card={props.card} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      case 'template_images':
        return <TemplateImages data={response} depth={props.depth} card={props.card} onUpdateResponse={onUpdateResponse} onRemoveResponse={onRemoveResponse} show_english={props.show_english} />
      default:
        break
    }
  }

  const checkLastResponse = responses => {
    if (!responses.length) return true;
    const type = responses[responses.length - 1].type;
    return type != 'options' && type != 'card' && type != 'loopback' && type != 'closearea';
  };

  const addBtn = action && checkLastResponse(action.responses);

  return (
    <>
      {
        action ? action.responses.map((response, index) => (
          <Grid key={response.id} item lg={12} md={12} sm={12} xl={12} xs={12} key={response.id} style={{ padding: '0' }}>
            {renderResponse(response)}
            {index < action.responses.length - 1 ?
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                <Box textAlign="center" style={{ maxWidth: '380px' }} >
                  <ModalAdd onAddResponse={addResponse} card={props.card ? true : false} area={props.area ? true : false} index={index + 1} depth={props.depth} constrain={true} />
                </Box>
              </Grid>
              : null
            }
          </Grid>
        )) : null
      }
      {
        addBtn ?
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
            <Box textAlign="center" style={{ maxWidth: '380px' }} >
              <ModalAdd onAddResponse={addResponse} card={props.card ? true : false} area={props.area ? true : false} index={action.responses.length} depth={props.depth} constrain={false} />
            </Box>
          </Grid> : null
      }
    </>
  )
}

export default ActionView;
