import React, { createContext, useContext, useState } from 'react';
import { gql, useQuery, useMutation, useApolloClient, useLazyQuery } from '@apollo/client';

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const userFragment = `
	id
	name,
	email,
	expired,
	isAdmin,
	plan {
		name
		depth
		links
		unlocked
		components
	}
`;

const USER_QUERY = gql`
	query {
		authenticatedUser {
			${userFragment}
		}
	}
`;

const LOGIN_MUTATION = gql`
	mutation signin($email: String, $password: String) {
		authenticateUserWithPassword(email: $email, password: $password) {
			item {
				${userFragment}
			}
		}
	}
`;

const LOGOUT_MUTATION = gql`
	mutation {
		unauthenticateUser {
			success
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: UserUpdateInput) {
		updateUser(id: $id, data: $data) {
			${userFragment}
		}
	}
`;

export const AuthProvider = ({ children, initialUserValue }) => {
	const [user, setUser] = useState(initialUserValue);
	const [userLoading, setUserLoading] = useState(true);
	const client = useApolloClient();

	// This is just used initially. To get the user in a latter
	// moment in time please use getUser bellow.
	const { refetch } = useQuery(USER_QUERY, {
		fetchPolicy: 'no-cache',
		onCompleted: ({ authenticatedUser, error }) => {
			if (error) {
				throw error;
			}

			setUser(authenticatedUser);
			setUserLoading(false);
		},
		onError: console.error,
	});

	// We use useLayQuery here because something was causing
	// unexpected results when calling useQuery(USER_QUERY) with refetch
	const [getUser] = useLazyQuery(USER_QUERY, {
		onCompleted: ({ authenticatedUser }) => setUser(authenticatedUser)
	});

	const [signinMutation, { loading: authLoading }] = useMutation(LOGIN_MUTATION);

	const signin = props => (signinMutation({ variables: props }).then(async ({ data: { authenticateUserWithPassword: { item } = {}, errors } }) => {
		if (errors) {
			throw errors;
		}

		// Ensure there's no old unauthenticated data hanging around
		await client.resetStore();

		if (item) {
			setUser(item);
		}
	}).catch(error => { throw error }));

	const [signoutMutation, { loading: unauthLoading }] = useMutation(LOGOUT_MUTATION);

	const signout = props => (signoutMutation(props).then(async ({ data: { unauthenticateUser: { success } = {}, errors } }) => {
		if (errors) {
			throw errors;
		}

		// Ensure there's no old authenticated data hanging around
		await client.resetStore();

		if (success) {
			setUser(null);
		}
	}).catch(error => { throw error }));

	const [updateMutation, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);

	const update = props => (updateMutation({ variables: props }).then(async ({ data: { updateUser , errors } }) => {
		if (errors) {
			throw errors;
		}

		// Ensure there's no old unauthenticated data hanging around
		await client.resetStore();


		if (updateUser) {
			setUser(updateUser);
		}
	}).catch(error => { throw error }));

	return (
		<AuthContext.Provider
			value={{
				userLoading,
				isAuthenticated: !!user,
				isLoading: authLoading || unauthLoading,
				signin,
				signout,
				getUser,
				update,
				user,
			}}
		>
			{!userLoading && children}
		</AuthContext.Provider>
	);
};