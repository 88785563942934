import React, { useEffect, useState, useContext } from 'react';

import {
  makeStyles,
  IconButton,
  Box,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import Icon from '../../../../components/Icon';
import ElementTab from '../../shared/ElementTab';
import PlaceTab from '../../shared/PlaceTab';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ServicesContext } from '../../../../../context/services';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 382,
    height: 693,
    background: '#FFFFFF',
    borderRadius: '6px',
    padding: 32,
    outline: 'none',
    textAlign: 'center',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
    color: theme.palette.primary.dark,
  },
  formInputs: {
    fontSize: '14px',
    width: 320,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  formInputsButtons: {
    fontSize: '14px',
    width: 282,
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  btn: {
    fontSize: 12,
    width: 80,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  btnUpload: {
    fontSize: 12,
    width: 99,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  searchItem: {
    display: 'flex',
    width: '320px',
    height: '42px',
    borderRadius: '6px',
    border: '1px solid #DBDDF1',
    backgroundColor: fade('#FFFFFF', 1),
    '&:hover': {
      backgroundColor: fade('#FFFFFF', 0.75),
    },
    marginLeft: 0,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
  },
  inputInput: {
    width: '250px',
    backgroundColor: 'transparent',
    padding: '12px',
    fontSize: '14px',
  },
  media: {
    width: 320,
    height: 177,
    borderRadius: 6,
  },
  box: {
    margin: '5px 0',
    width: '100%',
    height: 67,
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    textAlign: 'left',
    padding: '23px 14px 23px 14px',
  },
}));


const ModalEditTemplateGeneric = props => {
  const classes = useStyles();
  const { services } = useContext(ServicesContext);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [saveAll, setSaveAll] = useState(false);


  const [data, setData] = useState({
    elements: props.data ? props.data.elements : [],
  });

  const [tabs, setTabs] = useState(props.data.elements);

  useEffect(() => {
    // setData({
    //   elements: props.data ? props.data.elements : [],
    // });

    setTabs(props.data.elements);

    //check if new tab is empty
    if (tabs.length) {
      for (let i = 0; i < tabs.length; i++) {
        let element = tabs[i];
        if (!element.title_pt || element.title_pt.toString().toLowerCase() === "nova tab") {
          setOpen(true);
          setValue(i);
        } else {
          if (tabs.length !== props.data.elements.length) setValue(0);
        }
      }
    }
  }, [props.data.elements]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onUpdatedTab = tab => {
    if (tab !== "validate") props.onChange(tab);
    else setSaveAll(false);
  }

  const onSavedTab = tab => {
    // console.log('onSavedTab', tab)
    props.onChange(tab);
    setOpen(false);
    setSaveAll(false);
  }

  const addTab = () => {
    props.onAddTab();

    //todo
    setTimeout(() => {
      setValue(tabs.length - 1);
    }, 750);
  }

  const onRemoveTab = id => {
    props.onRemoveTab(id);
    //setValue(0);
  }

  const onSave = () => {
    setSaveAll(true);
  }

  const onDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newTabs = Array.from(tabs);
    const [removed] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, removed);
    for (let i = 0; i < newTabs.length; i++) {
      const tab = newTabs[i];
      tab.order = i;
      updateIndex(tab);
    }
    setTabs(newTabs);
    //props.onChangeSort(newTabs);
    setValue(result.destination.index);
  };

  const updateIndex = (element) => {
    const serviceData = `{ 
        order: ${element.order}
    }`;

    services.elementsApi.update(element.id, serviceData).then(({ updateElement }) => {
        //setElement(updateElement);
        //props.onSaved(updateElement);
    });
  }

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <Icon icon="edit" size={25} color="#4050E1" />
      </IconButton>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
      >
        <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
          <Typography className={classes.title}>
            {props.data.type === "template_places" ? "Criar Carrossel com imagens e geolocalização" : "Criar Carrossel de Imagens com links​"}
          </Typography>
          <Typography className={classes.subtitle}>
            {props.data.type === "template_places" ? "Para cada tab escolha uma conversa ou crie uma nova localização." : "Faça upload de uma imagem em cada tab. Dê-lhe um nome (título) e adicione umm botão com link​.​"}
          </Typography>
          <Box mt={3} display="flex" justifyContent="flex-start">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="tabs" direction="horizontal">
                {props => (
                  <Tabs
                    ref={props.innerRef}
                    {...props.droppableProps}
                    value={value}
                    indicator="true"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    //onChange={handleTabChange} Not used
                  >
                    {tabs.map((tab, index) => (
                      <Draggable
                        key={tab.id}
                        draggableId={`id-${tab.id}`} // must be a string
                        index={index}
                        disableInteractiveElementBlocking={true}
                      >
                        {props => (
                          <Tab
                            ref={props.innerRef}
                            {...props.draggableProps}
                            {...props.dragHandleProps}
                            label={tab.title_pt}
                            onClick={() => setValue(index)} // Set active tab like this
                          />
                        )}
                      </Draggable>
                    ))}
                    {props.placeholder}
                  </Tabs>
                )}
              </Droppable>
            </DragDropContext>
            {tabs.length < 10 ?
              <IconButton onClick={addTab}>
                <Icon icon="add" size={25} color="#4050E1" />
              </IconButton>
              : null}
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          {
            tabs.map((element, index) => (
              <TabPanel key={element.id} value={value} index={index}>
                {
                  props.data.type === "template_places" ?
                    <PlaceTab key={element.id} data={element} index={element.order} onUpdatedPlace={onUpdatedTab} onRemoveTab={onRemoveTab} onSaved={onSavedTab} show_english={props.show_english} onSave={onSave} saveAll={saveAll} />
                    :
                    <ElementTab key={element.id} data={element} index={element.order} onUpdatedElement={onUpdatedTab} onRemoveTab={onRemoveTab} onSaved={onSavedTab} show_english={props.show_english} onSave={onSave} saveAll={saveAll} />
                }
              </TabPanel>
            ))
          }
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ModalEditTemplateGeneric;