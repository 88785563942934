import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core'
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgb(0 0 0 / 45%)',
    display: 'block',
    overflow: 'hidden',
    padding: '10px',
    position: 'fixed',
    top: 0,
    height: '100%',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '420px',
    width: '100%',
    zIndex: 10,
  },
  container: props => ({ 
    background: props.theme_color ? props.theme_color : '#6673E7',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    position: 'relative',
    width: '100%',
    height: '100%',
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    padding: '10px 15px',
    textTransform: 'uppercase',
  },
  close_btn: {
    cursor: 'pointer'
  }
}));

const GoogleMaps = (props) => {
  const classes = useStyles(props);
  const [embed, setEmbed] = useState(null);
  const key = 'AIzaSyBwdDFVSPA6tq03tZhwNb9XsrtYQtX7cxo';
  const destination = props.place_lat + ',' + props.place_lng;
  useEffect(() => {
    if (!embed) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position);
          const origin = position.coords.latitude + ',' + position.coords.longitude;
          const url = `https://www.google.com/maps/embed/v1/directions?key=${key}&origin=${origin}&destination=${destination}`;
          console.log(url);
          setEmbed(url);
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
          const url = `https://www.google.com/maps/embed/v1/search?key=${key}&q=${destination}`;
          setEmbed(url);
        }
      );
    }
  }, [origin]);

  return (
    <div id='map' className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span>{props.place_name}</span>
          <div className={classes.close_btn} onClick={() => props.onClose()}>
            <Icon icon="close-small" size="35" />
          </div>
        </div>
        {embed ?
          <div
            style={{ borderRadius: '20px', flex: 1, overflow: 'hidden' }}
            dangerouslySetInnerHTML={{
              __html: '<iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src=' + embed + '></iframe>'
            }} /> : null}
      </div>
    </div>
  )
}

export default GoogleMaps;