import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, } from '@material-ui/core';
import Button from './Button';
import CardTitleImage from './CardTitleImage';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  buttons: props => ({
    filter: props.theme_color ? 'drop-shadow(0px 0px 10px rgba(138, 138, 138, 0.25))' : 'drop-shadow(0px 0px 10px rgba(102, 115, 231, 0.25))',
    listStyle: 'none',
    margin: '0 auto',
    padding: 0,
    flex: 1,
    width: '100%'
  }),
  zoomImage: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: '56.25%',
    width: '100%',
    top: '-35px',
    right: '10px',
    zIndex: 2,
  }
}));

function TemplateItem(props) {
  const classes = useStyles(props);

  const getTitle = element => {
    let title = element && element.title_pt ? element.title_pt : '';
    if (props.lang === 'en' && element && element.title_en) title = element.title_en;
    return title;
  }

  const getSubTitle = element => {
    let subtitle = element && element.subtitle_pt ? element.subtitle_pt : '';
    if (props.lang === 'en' && element && element.subtitle_en) subtitle = element.subtitle_en;
    return subtitle;
  }

  const sortByOrder = array => {
    const sorted = array.sort(function(a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  return (
    <div style={{ width: props.slider ? '85%' : '100%', margin: props.slider ? '10px' : '0', display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative' }}>
        {props.element.image ?
          <div className={classes.zoomImage} onClick={() => props.onOpenZoom(props.element)}>
            <Icon icon="search" color="white" size={30} />
          </div>
          :
          null
        }
        {
          props.element.detail_pt ?
            <>
              <CardTitleImage
                image_url={props.element.image && props.element.image.publicUrl ? props.element.image.publicUrl : null}
                title={getTitle(props.element)}
                subtitle={getSubTitle(props.element)}
              />
              {props.element.label_pt && props.lang === 'pt' ? <div className={classes.buttons}><Button onClick={() => props.onOpenDetail(props.element)}>{props.element.label_pt}</Button></div> : null}
              {props.element.label_en && props.lang === 'en' ? <div className={classes.buttons}><Button onClick={() => props.onOpenDetail(props.element)}>{props.element.label_en}</Button></div> : null}
            </>
            :
            <CardTitleImage
              image_url={props.element.image && props.element.image.publicUrl ? props.element.image.publicUrl : null}
              title={getTitle(props.element)}
              subtitle={getSubTitle(props.element)}
            />
        }
      </div>
      <div className={classes.buttons}>
        {
          sortByOrder(props.element.buttons).map((button, i) => {
            if (!button.disabled) {
              return <div key={i}>
                {
                  button.type === "web_url" ?
                    <Button lang={props.lang} button={button} conversation={props.conversation} theme_color={props.theme_color}>
                      {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                    </Button>
                    :
                    (
                      button.type === "maps" && props.element.type === "location" ?
                        <Button lang={props.lang} button={button} onClick={() => props.onResponse(props.element)} theme_color={props.theme_color}>
                          {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                        </Button>
                        :
                        <Button lang={props.lang} button={button} onClick={() => props.onResponse(button)} theme_color={props.theme_color}>
                          {props.lang === 'en' && button.title_en ? button.title_en : button.title_pt}
                        </Button>
                    )

                }
              </div>
            }
          })
        }
      </div>
    </div>
  );
}

export default TemplateItem;