import React from 'react';
import SettingsSidebar from './SettingsSidebar';

import {
  makeStyles,
  Box,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: '0px',
    }
  },
  sidebar: {
    backgroundColor: '#EBEDF5',
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 404px',
    }
  },
  content: {
    flexGrow: 1,
    fontWeight: 300,
    background: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    alignIitems: 'flex-start',
    padding: '24px 42px',
  },
}));


const SettingsView = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} >
      <Box className={classes.sidebar} >
        <SettingsSidebar page="Definições"/>
      </Box>
    </Box> 
  );
};

export default SettingsView;
  