import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: 'rgba(64,80,224,0.1)',
        color: '#1F3996',
        padding: '32px',
        width: '100%'
      },
      paper: {
        alignItems: 'center',
        background:'#FFFFFF',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        outline: 'none',
        paddingBottom: '32px',
        textAlign: 'center',
        width: '100%',
        maxWidth: 384,
      },
      title: {
        color: theme.palette.primary.dark,
      },
      subtitle: {
        fontWeight: 400,
        marginTop: 5,
      },
      wrapper: {
        padding: '0 20px',
        margin: '0 auto',
        width: '280px',
      },
      input: {
        borderBottom: '1px solid #1F3996',
        color: '#1F3996',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        margin: '15px 0',
        width: '100%',
        '& > input:-webkit-autofill': {
          'transition': 'background-color 5000s ease-in-out 0s',
          '-webkit-text-fill-color': 'inherit !important',
        },
        '& > ::placeholder': {
          opacity: 1,
        },
      },
      lostPasswordBtn: {
        color: '#00E7E4',
        cursor: 'pointer',
        textDecoration: 'underline'
      },
      success: {
        padding: '32px 32px 0 32px'
      }
}));