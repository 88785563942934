
import React, { useContext, useState } from "react";

import {
  Card,
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ModalEditLoopbackOptions from "./ModalEditLoopbackOptions";
import { ServicesContext } from '../../../../../context/services';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 380
  },
  text: {
    padding: '18px 18px 10px 18px',
  },
  btnYes: {
    borderRadius: '34px',
    background: theme.palette.grey.light,
    color: theme.palette.primary.dark,
    marginRight: '5px',
    marginBottom: '10px',
    textTransform: 'none',
    height: '34px',
  },
  btnNo: {
    borderRadius: '34px',
    background: theme.palette.grey.light,
    color: theme.palette.primary.dark,
    borderColor: 'transparent',
    marginRight: '5px',
    marginBottom: '10px',
    textTransform: 'none',
    height: '34px',
  },
  actionBtn: {
    backgroundColor: '#F3F7FF',
    border: '1px solid #4050E1',
    borderRadius: '34px',
    color: theme.palette.primary.main,
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '18px',
    margin: '15px 0',
    padding: '8px 12px',
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      top: '-10px',
      height: '5px',
    },
    '&::after': {
      content: '""',
      backgroundColor: '#4050E1',
      position: 'absolute',
      width: '1px',
      left: '50%',
      bottom: '-5px',
      height: '5px',
    },
  }
}));

const LoopbackOptions = props => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const { services } = useContext(ServicesContext);

  const handlerSave = () => {
    let serviceData = `{ text_pt: "${data.text_pt.replace(/\n/, "\\n")}", text_en: "${data.text_en.replace(/\n/, "\\n")}"}`;
    if(!data.id){
      services.responsesApi.create(serviceData).then(({ createResponse }) => {
        // console.log('createResponse', createResponse)
        setData( {...data, id: createResponse.id, text_pt: createResponse.text_pt, text_en: createResponse.text_en });
      });
    }else{
      services.responsesApi.update(data.id, serviceData).then(({ updateResponse }) => {
        // console.log('updateResponse', updateResponse)
        setData( {...data, id: updateResponse.id, text_pt: updateResponse.text_pt, text_en: updateResponse.text_en });
      });
    }
  }

  const handlerRemove = () => {
    props.onRemoveResponse(data.id);
  }

  const handleChange = e => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    });
  };

  return (
    <>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box mr={1}>
          <Card className={classes.card}>
            <Box className={classes.text} >
              <Typography style={{ whiteSpace: 'pre-line' }} variant='inherit'>
                {data ? data.text_pt : ''}
              </Typography>
            </Box>
            <Box display='flex' flexWrap='wrap' style={{ padding: '0px 16px 10px 16px' }} >
              {
                data && data.response && data.response.buttons ? data.response.buttons.map((option) => (
                  option.type == 'postback' ?
                  <Box key={option.id}>
                    <Button variant="outlined" color="primary" className={classes.btnYes}>{option.title_pt}</Button>
                  </Box> : null
                )) : null
              }
            </Box>
          </Card>
        </Box>
        <ModalEditLoopbackOptions data={data} onSave={handlerSave} onRemove={handlerRemove} onChange={handleChange} open={data && data.text_pt === '' ? true : false} show_english={props.show_english} />
      </Box>
    </>
  )
}

export default LoopbackOptions;