import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import TutorialItem from './TutorialItem';
import Icon from "./../../components/Icon";

import {
  Grid,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  Hidden,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '22px',
  },
  content: {
    marginTop:'5px',
  },
  list: {
    margin:'0px 42px auto 42px',
  },
  back: {
    fontWeight: 500,
    fontSize: '22px',
    marginLeft:'26px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
  item: {
    fontSize: '22px',
    marginLeft: '-16px',
    'svg': {
      color: theme.palette.primary.dark,
    }
  },
}));

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} >
        {icon ? <ListItemIcon className={classes.back}>{icon}</ListItemIcon> : null}
        <ListItemText disableTypography className={classes.item} primary={primary} />
      </ListItem>
    </li>
  );
}

const TutorialSidebar = props => {
  const classes = useStyles();

  const onSelect = e => {
    props.onSelected(props.tutorials[e.target.value]);
  }

  return (
    <Grid container
      spacing={2}
      className={classes.root}
      >
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} className={classes.content}>
        <Box>
          <List>
            <ListItemLink disableTypography to="/backoffice/" primary={ props.page } icon={<Icon icon="back" size={25} color="#4050E1" />} />
          </List>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
        <Box display='flex' flexDirection='column' alignItems='center' className={classes.list} >
          <Hidden xsDown>
          <List style={{paddingTop: 0}} >
            {props.tutorials.length > 0 ? props.tutorials.map((tutorial) => (
              <TutorialItem key={tutorial.id} data={tutorial} title={tutorial.title} info={tutorial.description} onSelected={props.onSelected}/>)) : null 
            }
            </List>
            </Hidden>
            <Hidden smUp>
              <Select
                native
                value={props.index}
                onChange={onSelect}
                style={{width: '100%', background: 'transparent'}}
              >
                {props.tutorials.length > 0 ? props.tutorials.map((tutorial, index) => (
                  <option value={index} key={index}>{tutorial.title} </option>
                  )) : null 
                }
              </Select>
            </Hidden>
        </Box>
      </Grid>
    </Grid>  
  );
}

export default TutorialSidebar;
