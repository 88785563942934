import React, { useState } from 'react';
import { makeStyles, } from '@material-ui/core';
import Slider from './ui/Slider';
import TemplatePlace from './ui/TemplatePlace';
import TemplateItem from './ui/TemplateItem';
import ModalDetail from './ui/ModalDetail';
import ModalZoomImage from './ui/ModalZoomImage';


const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    // position: 'relative',
  },
  image: {
    backgroundColor: 'rgb(242, 242, 242)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: 170,
  },
  buttons: props => ({
    filter: props.theme_color ? 'drop-shadow(0px 0px 10px rgba(138, 138, 138, 0.25))' : 'drop-shadow(0px 0px 10px rgba(102, 115, 231, 0.25))',
    listStyle: 'none',
    margin: '0 auto',
    padding: 0,
  }),
  button: props => ({
    background: '#F3F7FF',
    borderRadius: '20px',
    //color: '#4050E1',
    color: props.theme_color ? props.theme_color : '#4050E1',
    cursor: 'pointer',
    margin: '5px 0',
    padding: '5px 15px',
    textAlign: 'center',
    width: '100%',
  })
}));

function MessageTemplate(props) {
  const classes = useStyles(props);
  const message = props.message;
  const [openModalDetail, setModalDetail] = useState(null);
  const [openModalZoom, setModalZoom] = useState(null);

  const onResponse = button => {
    props.onResponse(button)
  }

  const onOpenZoom = element => {
    setModalZoom(element)
  }

  const onOpenDetail = element => {
    setModalDetail(element)
  }

  const sortElements = elements => {
    const sorted = elements.sort(function (a, b) {
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return sorted;
  }

  return (
    <>
      <li
        className={message.id ? 'bot' : 'user'}
        style={{ width: '100%', padding: 0, background: 'transparent' }}
      >
        <div className={classes.sliderWrapper}>
          <Slider active={message.elements && message.elements.length !== 1} theme_color={props.theme_color}>
            {message.elements ?
              sortElements(message.elements).map((element, i) => {
                switch (element.type) {
                  case 'places':
                    return <TemplatePlace
                      key={i}
                      element={element}
                      conversation={props.conversation}
                      slider={message.elements.length !== 1}
                      lang={props.lang}
                      onResponse={onResponse}
                      theme_color={props.theme_color}
                    />
                  default:
                    return <TemplateItem
                      key={i}
                      element={element}
                      conversation={props.conversation}
                      slider={message.elements.length !== 1}
                      lang={props.lang}
                      onResponse={onResponse}
                      onOpenZoom={onOpenZoom}
                      onOpenDetail={onOpenDetail}
                      theme_color={props.theme_color}
                    />
                }
              })
              : null}
          </Slider>
        </div>
      </li>
      {
        openModalZoom ?
          <ModalZoomImage
            lang={props.lang}
            element={openModalZoom}
            onClose={() => setModalZoom(null)}
          />
          : null
      }
      {
        openModalDetail ?
          <ModalDetail
            lang={props.lang}
            element={openModalDetail}
            onClose={() => setModalDetail(null)}
          />
          : null
      }
    </>
  );
}

export default MessageTemplate;