import React from 'react';

const Logo = (props) => {
  return (
    <a href="/">
      <img
        alt="Logo"
        src="/images/logo.svg"
        width="100%"
        {...props}
      />
    </a>

  );
};

export default Logo;
