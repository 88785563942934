export default function makeFaqsApi({
    query,
    mutation,
}) {
    const tutorialFragment = `
        id
        title,
        description,
        video_url
    `;

    return {
        getAll: () => query(`
            query {
                allTutorials { ${tutorialFragment} }
            }
        `),
    };
}