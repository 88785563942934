export default function makeResponsesApi({
    query,
    mutation,
}) {
    const responseFragment = `
        id
        type
        text_pt
        text_en
        can_delete
        order
        options {
            id,
            title_pt
            title_en
            payload { id }
        }
        buttons { 
            id
            type
            title_pt
            title_en
            payload { id }
            url
            url_en
            webview
            can_delete
            can_edit
            disabled
            order
         }
         elements { 
            id 
            type
            title_pt
            title_en
            subtitle_pt
            subtitle_en
            label_pt
            label_en
            detail_pt
            detail_en
            place_address
            place_lng
            place_lat
            place_image
            order
            conversation {
                id
                name
                image {
                    publicUrl
                }
                place_address
                place_image
                qrcode
                url
                status
                tag
                user {
                    id
                }
                start {
                    id
                }
                createdAt
            }
            image {
                publicUrl
            }
            buttons { 
                id
                type
                title_pt
                title_en
                payload { id }
                url
                webview
                can_delete
                can_edit
                disabled
                order
            }
        }
        conversation { 
            id
            name
            image { 
                publicUrl 
            }
        }
    `;

    return {
        getAll: () => query(`
            query {
                allResponses { ${responseFragment} }
            }
        `),

        getById: (id) => query(`
            query{
                Response(where: {id: "${id}"})  { ${responseFragment} }
            }
        `),

        get: ids => query(`
            query {
                allResponses(where: { id_in: ${ids} }) { ${responseFragment} }
            }
        `),

        createText: data => mutation(`
            mutation {
                createResponse(data: {
                    type: ${data.type},
                    text_pt: "${data.text_pt}",
                    text_en: "${data.text_en}",
                    order: ${data.order}
                }) { id }
            }
        `),

        create: data => mutation(`
            mutation {
                createResponse(data: ${data}) { id }
            }
        `),

        update: (id, data) => mutation(`
            mutation {
                updateResponse(id: "${id}", data: ${data}) { ${responseFragment} }
            }
        `),

        updateMany: items => mutation(`
            mutation {
                updateResponses(data: [${items}] ) { id }
            }
        `),

        delete: id => mutation(`
            mutation {
                deleteResponse(id: "${id}") { id }
            }
        `),
    };
}