import React from 'react';

import Icon from "./../../backoffice/components/Icon";
import OpportunitiesItem from './OportunidadesItem'

import Carousel, { consts } from 'react-elastic-carousel';
import { Box, Button, Container, makeStyles, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 530,      
        paddingTop: 20, 
        marginBottom: 200,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 60,
            '& > div': {
				padding: 0,
			},
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',    
        textAlign: 'center',
    },
    title: {
        color: theme.palette.primary.dark,
        padding: '0px 250px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 15px', 
            },
    },
    feed: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
			'& .rec-slider-container': {
				margin: 0,
			},
            '& .rec-carousel ': {
                '& button': {
                    display: 'none',
                },
			},
        },
        [theme.breakpoints.down('xs')]: {
			'& .rec-carousel-item-prev': {
				position: 'relative',
				right: '-16vw'
			},
			'& .rec-carousel-item-next': {
				position: 'relative',
				left: '-16vw'
			},
		}
    },
}));

export default function Opportunities() {
    const classes = useStyles();

  return (
    <Box id='opportunities' className={classes.root}>
        <Container maxWidth="lg">
            <Box className={classes.content}>
                <Box className={classes.title} mb={4}>
                    <Typography variant="h1" className="line" style={{ fontWeight: 700 }}>
                        Oportunidades
                    </Typography>
                </Box> 
                <Box className={classes.feed} mb={4}>
                    <Carousel 
                        renderArrow={({ type, onClick, isEdge }) => {
                            const pointer = type === consts.PREV ? <Icon icon="arrow-left-home" size={40} color="#4050E1" /> : <Icon icon="arrow-right-home-" size={27} color="#4050E1" />
                            return (
                              <Button onClick={onClick} disabled={isEdge}>{pointer}</Button>
                            )
                        }}
                        enableMouseSwipe={false}
                        pagination={false}
                        itemsToShow={3} 
                        breakPoints={ [{ width: 1, itemsToShow: 1 },
                        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
                        { width: 767, itemsToShow: 2, itemsToScroll: 2 },
                        { width: 800, itemsToShow: 3, itemsToScroll: 3 }, 
                        { width: 1440, itemsToShow: 3, itemsToScroll: 3 },]}
                    >
                        <OpportunitiesItem 
                            image='video_01.jpg'
                            title='Itinerários'
                            content='Ideal para contar histórias ao longo de um itinerário, ligado a Google Maps, e listar e o que fazer nas redondezas.'
                        />
                        <OpportunitiesItem 
                            image='video_03.jpg'
                            title='Visitas Turísticas'
                            content='Se tem um espaço que merece ser visitado - jardim, fábrica, recinto recreativo, parque temático… - use o Talk2me para criar uma experiência ainda mais interessante.'
                        />
                         <OpportunitiesItem 
                            image='video_04.jpg'
                            title='Artes e Ofícios'
                            content='Adequado para espaços onde pretenda criar uma maior relação com as obras ou o património exposto: galerias, museus, hotel histórico, oficina de ofícios…'
                        />
                        <OpportunitiesItem 
                            image='video_02.jpg'
                            title='Produtos Regionais'
                            content='Imagine imprimir um QRCode no rótulo de um produto e, com isso, possibilitar uma conversa, com o próprio produto, sobre o processo, ou a história da sua criação. '
                        />
                    </Carousel>
                </Box> 
            </Box>
        </Container>
    </Box>
  )
}