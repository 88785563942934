import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Base64 } from 'js-base64';

import {
  Typography,
  Button,
  Slide,
  Input,
  Box,
  FormControlLabel,
  Checkbox,
  Link
} from '@material-ui/core';

import { useStyles } from '../style';
import { useAuth } from 'core/services/auth';
import { useNavigation } from 'core/services/navigation';
import { useToasts } from 'react-toast-notifications';

const SignupView = props => {
  const classes = useStyles();
  const { update, signin } = useAuth();
  const { addToast } = useToasts();
  const navigation = useNavigation();
  const [user, setUser] = useState({ email: '', name: '', password: '', confirm: '', terms: false });

  useEffect(() => {
    const { uh } = queryString.parse(location.search);
    if (uh) {
      const data = JSON.parse(Base64.decode(uh));
      if (data) setUser(data);
    }
  }, []);

  const handleChange = e => {
    if(e.target.id === "terms") setUser({ ...user, [e.target.id]: e.target.checked  });
    else setUser({ ...user, [e.target.id]: e.target.value  });
  };

  const validate = () => {
    const regPassword = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

    if(user.name.length < 3) addToast('Por favor valide o nome do utilizador.', { appearance: 'error', autoDismiss: true });
    else if(!regPassword.test(user.password)) addToast('Por favor insira uma password válida. Mínimo de 8 caracteres, pelo menos uma letra e um número.', { appearance: 'error', autoDismiss: true });
    else if(user.password !== user.confirm) addToast('Passwords não coincidem.', { appearance: 'error', autoDismiss: true });
    else if(!user.terms) addToast('É necessário aceitar os Termos de Uso.', { appearance: 'error', autoDismiss: true });
    else handleOnSubmit();
  }

  const handleOnSubmit = async data => {
    try {
      await update({ id: user.id, data: { password: user.password, terms: user.terms } });
      await signin({ email: user.email, password: user.password });
      navigation.goToDashboard();
    } catch (error) {
      console.log(error);
      addToast('Ocorre um erro. Por favor tente novamente mais tarde.', { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Slide in={true} timeout={500}>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h1" className="line">Completar Registo</Typography>
          <Box mt={2}>
            <Typography>Para completar o seu registo escolha uma password e aceite as condições.</Typography>
          </Box>
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Nome do utilizador"
            type="name"
            id="name"
            value={user.name}
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Email do utilizador"
            type="email"
            id="email"
            value={user.email}
            onChange={handleChange}
            disabled
          />
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Password"
            type="password"
            id="password"
            value={user.password}
            autoComplete="off"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.wrapper}>
          <Input
            className={classes.input}
            placeholder="Confirmar Password"
            type="password"
            id="confirm"
            value={user.confirm}
            autoComplete="off"
            onChange={handleChange}
          />
        </Box>
        <Box mt={1}>
          <FormControlLabel
            style={{maxWidth: 250,  textAlign: 'left', pointerEvents: "none"}}
            control={
              <Checkbox
                checked={user.terms}
                onChange={handleChange}
                name="terms"
                id="terms"
                color="primary"
                style={{ pointerEvents: "auto" }}
              />
            }
            label={
              <Typography style={{fontSize: '12px', zIndex: 3, position: 'relative', pointerEvents: "auto"}}>
                 <span>Aceito os </span>
                 <Link href={'/pdf/termos_condicoes.pdf'} target="_blank" style={{color :'#1F3996'}}>termos de uso</Link>
                 <span> e </span>
                 <Link href={'/pdf/politica_privacidade.pdf'} target="_blank" style={{color: '#1F3996'}}>política de privacidade</Link>
              </Typography>
            }
          />
        </Box>
        <Box mt={2}>
          <Button variant="outlined" className="landing green" onClick={validate}>
            Confirmar
          </Button>
        </Box>
      </Box>
    </Slide>
  );
}

export default SignupView;