export default function makeActionsApi({
    query,
    mutation,
}) {
    const actionFragment = `
        id
        type
        responses(orderBy: "order_ASC") {
            id
            type
            text_pt
            text_en
            can_delete
            order
            options {
                id
                title_pt
                title_en
                payload { id }
            }
            buttons { 
                id
                type
                title_pt
                title_en
                payload { id }
                url
                url_en
                webview
                can_delete
                can_edit
                disabled
                order
            }
            elements { 
                id 
                type
                title_pt
                title_en
                subtitle_pt
                subtitle_en
                label_pt
                label_en
                detail_pt
                detail_en
                place_address
                place_lng
                place_lat
                place_image
                order
                conversation {
                    id
                    name
                    image {
                        publicUrl
                    }
                    place_address
                    place_lng
                    place_lat
                    place_image
                    qrcode
                    url
                    status
                    tag
                    user {
                        id
                    }
                    start {
                        id
                    }
                    createdAt
                }
                image {
                    publicUrl
                }
                buttons { 
                    id
                    type
                    title_pt
                    title_en
                    payload { id }
                    url_en
                    url
                    webview
                    can_delete
                    can_edit
                    disabled
                    order
                }
            }
            conversation { 
                id
                name
                image { 
                    publicUrl 
                }
            }
            response { 
                buttons { 
                    id
                    type
                    title_pt
                    title_en
                    payload { id }
                    can_delete
                    can_edit
                    disabled
                    order
                }
                options {
                    id
                    title_pt
                    title_en
                    payload { id }
                }
            }
        }
    `;

    const parseResponses = responses => {
        let arr = '';
        for (var i = 0; i < responses.length; i++) {
            arr += `{ id: "${responses[i].id}" }`;
        }
        return arr;
    }

    return {
        getAll: () => query(`
            query {
                allActions { ${actionFragment} }
            }
        `),

        getById: (id) => query(`
            query{
                Action(where: {id: "${id}"})  { ${actionFragment} }
            }
        `),

        create: data => mutation(`
            mutation {
                createAction(data: {}) { ${actionFragment} }
            }
        `),

        update: (id, data) => mutation(`
            mutation {
                updateAction(
                    id: "${id}", 
                    data: { responses: { disconnectAll: true, connect: [ ${data} ] } }
                ) { ${actionFragment} }
            }
        `),

        updateResponses: (action) => mutation(`
            mutation {
                updateAction(id: "${action.id}", data: {
                    responses: { disconnectAll: true, connect: [${parseResponses(action.responses)}] }
                }) { ${actionFragment} }
            }
        `),

        delete: (id) => mutation(`
            mutation {
                deleteAction(id: "${id}") { id }
            }
        `),
    };
}