import React, { Component } from 'react';
import Layout from '../layouts';

import CreateView from '../views/CreateView';

class Create extends Component {
  render() {
      return (
        <Layout>
          <CreateView />
        </Layout>
      );
  }
}

export default Create;
