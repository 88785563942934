import React from 'react';

import Icon from "./../../components/Icon";

import {
  makeStyles,
  IconButton,
  Modal,
  Backdrop,
  Box,
  Typography,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';;

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 384,
      height: 392,
      background:'#FFFFFF',
      borderRadius: '6px',
      padding: 32,
      outline: 'none',
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
    },
    subtitle: {
      fontWeight: 400,
      marginTop: 5,
    },
}));

function ModalView() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
      <Box>
        <IconButton onClick={handleOpen}>
          <Icon icon="eye" size={25} color="#4050E1" />
        </IconButton>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          }}
        >
          <Slide in={open} timeout={500} >
            <Box className={classes.paper}>
              <Typography
              className={classes.title}
              variant="h2"
              >
                Modal View
              </Typography>
              <Typography
              className={classes.subtitle}
              variant="h4"
              >
               Pending...
              </Typography>
            </Box>
          </Slide>
        </Modal>
      </Box>
    );
}

export default ModalView;