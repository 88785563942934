import React, { Fragment, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {
  makeStyles,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Icon from '../../../../components/Icon';
import ModalRemoveButton from './ModalRemoveButton';
import { useAuth } from '../../../../../core/services/auth';
import { ToggleOn, ToggleOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 5,
  },
  text: {
    padding: '18px 18px 10px 18px',
    border: '1px solid #DBDDF1',
    borderRadius: '6px',
    fontWeight: 'normal',
    textAlign: 'justify',
  },
  formFields: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '6px',
    color: theme.palette.primary.dark,
  },
  formInputsButtons: {
    fontSize: '14px',
    width: '100%',
    height: 42,
    background: '#FFFFFF',
    borderRadius: '6px;',
    borderWidth: 0,
    fontFamily: 'Heebo',
    outline: 'none',
    marginRight: '12px',
  },
  btn: {
    fontSize: 12,
    width: 80,
    height: 34,
    borderRadius: '34px',
    textTransform: 'none',
    marginRight: 8,
    padding: '8px 16px',
  },
  box: {
    margin: '5px 0',
    width: '100%',
    minWidth: '320px',
    height: 67,
    background: '#fff',
    border: '1px solid #DBDDF1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontWeight: 400,
    textAlign: 'left',
    padding: '23px 14px 23px 14px',
  },
}));

const ModalButton = props => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const { user } = useAuth();
  const [open, setOpen] = useState(props.open);
  const [modalRemove, setModalRemove] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onSave();
    handleClose();
  }
  const handleRemove = () => {
    setModalRemove(true);
  }

  useEffect(() => {
    setValues({
      id: props.data ? props.data.id : '',
      type: props.data ? props.data.type : '',
      title_pt: props.data ? props.data.title_pt : '',
      title_en: props.data ? props.data.title_en : '',
      url: props.data && props.data.url ? props.data.url : '',
      url_en: props.data && props.data.url_en ? props.data.url_en : '',
      webview: props.data && props.data.webview ? props.data.webview : '',
      can_delete: props.data ? props.data.can_delete : true,
      can_edit: props.data ? props.data.can_edit : true,
      disabled: props.data ? props.data.disabled : false,
    });
  }, [props.data]);

  const [values, setValues] = useState({
    id: props.data ? props.data.id : '',
    type: props.data ? props.data.type : 'web_url',
    title_pt: props.data ? props.data.title_pt : '',
    title_en: props.data ? props.data.title_en : '',
    url: props.data && props.data.url ? props.data.url : '',
    webview: props.data && props.data.webview ? props.data.webview : 'full',
    can_delete: props.data && props.data.can_delete ? props.data.can_delete : true,
    can_edit: props.data && props.data.can_edit ? props.data.can_edit : true,
    disabled: props.data && props.data.disabled ? props.data.disabled : false,
  });

  const validate = () => {
    if (!values.title_pt) addToast('Texto em português em falta', { appearance: 'error', autoDismiss: true });
    else if (props.show_english && !values.title_en) addToast('Texto em inglês em falta', { appearance: 'error', autoDismiss: true });
    else if (values.type === "web_url" && values.url.length <= 3) addToast('URL em falta', { appearance: 'error', autoDismiss: true });
    else if (props.show_english && !values.type === "web_url" && values.url_en.length <= 3) addToast('URL (EN) em falta', { appearance: 'error', autoDismiss: true });
    else handleSave();
  }

  const handleChange = e => {
    props.onChange(e);
    //needed to fix OSX special characters
    setValues({
      ...values,
      [e.target.id]: e.target.value
    });
  };

  const onConfirmRemoveButton = () => {
    props.onRemove();
    handleClose();
  }

  const onDisable = state => {
    {/*setValues({
      ...values,
      disabled: state
    });*/}
    props.onToggleDisable(state);
  }

  console.log('props.show_english', props.show_english);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <ToggleButton
          value="check"
          id="disabled"
          onChange={() => { onDisable(!values.disabled) }}
          style={{ border: 'none', borderRadius: '50%' }}
        >
          {values.disabled ? <ToggleOff htmlColor="#F16E6E" /> : <ToggleOn htmlColor="#94E66D" />}
        </ToggleButton>
        <IconButton onClick={handleOpen} disabled={!values.can_edit} style={{ opacity: values.can_edit ? 1 : 0.5 }}>
          <Icon icon="edit" size={25} color="#4050E1" />
        </IconButton>
        <ModalRemoveButton onConfirmRemoveButton={onConfirmRemoveButton} open={modalRemove} can_delete={values.can_delete} />
        <div style={{cursor: 'grab', display: 'flex'}}>
          <Icon icon="drag" size={22} color="#4050E1" />
        </div>
      </Box>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        onBackdropClick={validate}
        disabledBackdropClick={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
          <Typography
            className={classes.title}
          >
            Adicionar Botão
          </Typography>
          <Typography
            className={classes.subtitle}
          >
            Preencha por favor os campos pedidos
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box className={classes.paper}>
            <form>
              {/* {user.plan.links ?
                <select
                  name="type"
                  id="type"
                  className={classes.box}
                  value={values.type}
                  onChange={handleChange}>
                  <option value="postback">Fluxo</option>
                  <option value="web_url">Link externo (URL)</option>
                </select>
                : null
              } */}
              <input
                className={classes.box}
                type="text"
                id="title_pt"
                value={values.title_pt}
                onChange={handleChange}
                placeholder="Legenda​"
                maxLength="30"
              />
              <input
                className={classes.box}
                type="text"
                id="title_en"
                value={values.title_en}
                onChange={handleChange}
                placeholder="Legenda (EN)"
                maxLength="30"
                style={{ display: props.show_english ? 'inline-block' : 'none' }}
              />
              {values.type === "web_url" ?
                <Fragment>
                  <input
                    className={classes.box}
                    type="text"
                    id="url"
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    placeholder="URL, número de telefone ou email"
                  />
                  <input
                    className={classes.box}
                    type="text"
                    id="url_en"
                    name="url_en"
                    value={values.url_en}
                    onChange={handleChange}
                    placeholder="URL, número de telefone ou email (EN)"
                    style={{ display: props.show_english ? 'inline-block' : 'none' }}
                  />
                  {/* <select 
                      name="webview" 
                      id="webview" 
                      className={classes.box}
                      value={values.webview}
                      onChange={handleChange}
                    >
                      <option value="compact">Compact</option>
                      <option value="full">Full</option>
                      <option value="tall">Tall</option>
                    </select> */}
                </Fragment>
                : null}
            </form>
          </Box>
          <Box mt={1}>
            <Typography variant="p" style={{ fontSize: '12px' }}>Limite máximo de 30 carateres.</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' alignItems='center' justifyContent='space-between' style={{ width: '100%' }}  >
            <Button variant="contained" color="primary" className={classes.btn} onClick={validate}>
              Gravar
            </Button>
            {values.can_delete ?
              <Button variant="outlined" color="primary" className={classes.btn} onClick={handleRemove}>
                Remover
              </Button> : null
            }
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ModalButton;